import React, { useState } from "react";
import { Eye, Images } from "../../../assets";
import AppDrawer from "../../common/AppDrawer";
import Chat from "./chat";
import { validateEmail } from "../../../helper/validation";
import {
  CircularProgress,
  Snackbar,
  SnackbarContent,
  Tooltip,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { addDoc, collection } from "firebase/firestore";
import { firestoreDb } from "../../../libraries/firebase";

const Hero = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const earlyAccesHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isEmailValid = validateEmail(email, setError);
    if (!isEmailValid) {
      setLoading(false);
      return;
    }
    setError("");
    const docRef = collection(firestoreDb, "EarlyAccessEmails");

    try {
      await addDoc(docRef, {
        email,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    } finally {
      setLoading(false);
      setEmail("");
      setEmailSent(true);
    }
  };

  return (
    <div className=" text-center relative flex flex-col items-center pt-[10%] lg:pt-0 lg:justify-center w-full lg:w-1/2 h-[100svh] lg:h-screen">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={emailSent}
        onClose={() => setEmailSent(false)}
        autoHideDuration={2000}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#43a047",
            color: "#ffffff",
            fontWeight: "600",
          }}
          message="Thank you for joining our Early Access list!"
        />
      </Snackbar>
      <p className="font-helvetica font-bold text-[22px] lg:text-2xl mb-10">
        UnBoard
      </p>
      <h1 className="font-bold font-grotesque text-[52px] leading-[52px] sm:text-[64px] sm:leading-[64px] xl:text-[84px] xl:leading-[76px]">
        Work with AI,
        <br /> collaboratively.
      </h1>
      <p className="font-helvetica text-lg lg:text-2xl mt-6 lg:mt-[28px] lg:leading-[28.8px]">
        All the AI(s) you need for power work,
        <br />
        with your team, in one subscription.
      </p>
      <form
        onSubmit={earlyAccesHandler}
        className="mt-[40%] lg:mt-[152px] w-[308px] flex flex-col gap-3 relative"
      >
        <img
          src={Images.LandingPageHeart}
          className="w-[263px] lg:w-[244px] h-[319px] lg:h-[296px] object-contain -translate-x-1/2 absolute left-1/2 -bottom-[56px]"
          alt="background"
        />
        <input
          className="relative backdrop-blur-md placeholder:text-[#686868] bg-white/10 font-helvetica py-[11px] pl-6 rounded-full border border-[#E3E3E3] w-[308px]"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email"
          type="email"
        />
        {error && (
          <Tooltip title={error} arrow placement="top">
            <div className="!text-red z-[5] absolute right-4 top-2.5">
              <Info className="animate-ping absolute top-1" fontSize="small" />
              <Info fontSize="small" />
            </div>
          </Tooltip>
        )}
        <button
          className="relative bg-[#3C60F0] w-[308px] text-white py-[11px] rounded-full font-bold disabled:opacity-95"
          onClick={earlyAccesHandler}
          disabled={loading}
          type="submit"
        >
          {loading ? (
            <CircularProgress size={16} className="!text-white" />
          ) : (
            "Join waitlist"
          )}
        </button>
      </form>
      <button
        onClick={() => setOpenDrawer(true)}
        className="lg:hidden relative mt-[56px] flex items-center justify-center gap-3 font-bold text-[#3C60F0]"
      >
        <Eye className="w-[21.5px] h-[14px]" />
        <p>Try Live Demo</p>
      </button>
      <AppDrawer
        appDrawerProps={{
          open: openDrawer,
          anchor: "right",
          closeModal: () => setOpenDrawer(false),
          drawerWidth: "100%",
        }}
        style={{
          height: "100svh",
          overflow: "auto",
          backgroundColor: "#e3e3e3",
        }}
        type={"swipeable"}
      >
        <Chat />
      </AppDrawer>
    </div>
  );
};

export default Hero;
