import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { MedText, RegText, SemiBoldText } from "../../../../styles";
import { styles } from "./styles";
import {
  ArrowDownSvg,
  ArrowLeftSvg,
  ContactUsSvg,
  LeaveSvg,
  MenuSvg,
  ProfileSvg,
  SearchSvg,
  CloseSvg,
  InviteSvg,
  SettingSvg,
  LeftArrowSvg,
  Icons,
} from "../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  setInitialLoader,
  toggleLeftMenu,
  toggleNotification,
  toggleRightMenu,
} from "../../../../redux/appSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../../../themes";
import AppDrawer from "../../AppDrawer";
import ProjectCard from "../../ProjectCard";
import CommonModal from "../../CommonModal";
import CustomBtn from "../../CustomBtn";
import Notification from "../../Notification";
import SubMenu from "../../SubMenu";
import InviteCollaborators from "../../InviteCollaborators";
import { signOutFromApp } from "../../../../services/auth";
import {
  findWorkspaces,
  clearUserData,
  setSelectedTeam,
} from "../../../../redux/userSlice";
import { indexedDBLocalPersistence } from "firebase/auth";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  createPortalLink,
  doc,
  firestoreDb,
  leaveTeam,
  onSnapshot,
} from "../../../../libraries/firebase";
import {
  checkStringLength,
  getUserRole,
  trackEvent,
} from "../../../../utils/functions";
import { capitalize } from "../../../../helper/formatting";
import { fetchCurrentTeamSubscription } from "../../../../services/payment";
import PlanBox from "../../PlanBox";

const LogoutModal = ({ openLogoutModal, setOpenLogoutModal }) => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await signOutFromApp();
    dispatch(clearUserData());
    localStorage.clear();
    setOpenLogoutModal(false);
    dispatch(setInitialLoader(true));
  };

  return (
    <CommonModal
      open={openLogoutModal}
      setOpen={setOpenLogoutModal}
      heading="Logout"
      subHeading={"Are you sure, you want to log out?"}
    >
      <Stack gap="24px">
        <CustomBtn onClick={handleLogout}>Logout</CustomBtn>
        <CustomBtn type="secondary" onClick={() => setOpenLogoutModal(false)}>
          Cancel
        </CustomBtn>
      </Stack>
    </CommonModal>
  );
};

const InviteModal = ({
  openInviteModal,
  setInviteModal,
  emails,
  setEmails,
}) => {
  return (
    <CommonModal
      open={openInviteModal}
      setOpen={setInviteModal}
      heading="Invite Collaborators"
    >
      <InviteCollaborators
        emails={emails}
        setEmails={setEmails}
        setOpenInviteModal={setInviteModal}
        openInviteModal={openInviteModal}
      />
    </CommonModal>
  );
};

const MobileHomeNavbar = ({ openLeftSideBar, screen }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={styles.navbar}
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center">
        <IconButton onClick={openLeftSideBar}>
          <MenuSvg height={12} width={18} />
        </IconButton>
        <SemiBoldText variant="body1" sx={styles.text}>
          {screen}
        </SemiBoldText>
      </Stack>
      <IconButton>
        <ContactUsSvg />
      </IconButton>
    </Stack>
  );
};

const handleContactUs = () => {
  window.location.href = "https://www.ellenox.com/contact";
};

const DesktopHomeNavbar = () => {
  return (
    <div className="hidden bg-white md:flex items-center justify-between py-[13px] px-3">
      <Stack component={Button} sx={styles.button} onClick={handleContactUs}>
        <MedText sx={styles.medText} variant="body2">
          Contact us
        </MedText>
      </Stack>
    </div>
  );
};

const MobileProfileNavbar = ({ openLeftSideBar, screen }) => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  return (
    <div className="sticky top-0 block md:hidden custom-shadow">
      <LogoutModal
        openLogoutModal={openLogoutModal}
        setOpenLogoutModal={setOpenLogoutModal}
      />
      <Stack
        direction="row"
        alignItems="center"
        sx={styles.navbar}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center">
          <IconButton onClick={openLeftSideBar}>
            <MenuSvg height={12} width={18} />
          </IconButton>
          <SemiBoldText variant="body1" sx={styles.text}>
            {screen}
          </SemiBoldText>
        </Stack>
        <IconButton onClick={() => setOpenLogoutModal(true)}>
          <Box
            component="img"
            src={Icons.logout}
            alt="logout"
            height="18px"
            width="18px"
          />
        </IconButton>
      </Stack>
    </div>
  );
};

const ProfileDesktopNavbar = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  return (
    <div className="sticky top-0 bg-white z-20 shadow-sm custom-shadow">
      <LogoutModal
        openLogoutModal={openLogoutModal}
        setOpenLogoutModal={setOpenLogoutModal}
      />
      <div className="hidden md:flex  items-center justify-between py-[13px] px-3">
        <SemiBoldText sx={styles.semiBoldText} variant="body1">
          Profile
        </SemiBoldText>
        <Stack
          component={Button}
          sx={styles.button}
          onClick={() => setOpenLogoutModal(true)}
        >
          <MedText sx={styles.medText} variant="body2">
            Logout
          </MedText>
        </Stack>
      </div>
    </div>
  );
};

const MobileSettingNavbar = () => {
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const navigate = useNavigate();
  const { selectedTeam, teams } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedTeam());
  }, [navigate, teams, dispatch]);

  return (
    <>
      <InviteModal
        openInviteModal={openInviteModal}
        setInviteModal={setOpenInviteModal}
      />
      <Stack
        direction="row"
        alignItems="center"
        sx={styles.navbar}
        justifyContent="flex-end"
        width="100%"
        className="custom-shadow"
      >
        <IconButton onClick={() => navigate(`/teams/${selectedTeam?.teamId}`)}>
          <CloseSvg stroke={Colors.black} />
        </IconButton>
      </Stack>
    </>
  );
};

const SettingNavbar = ({ emails, setEmails, onClick, selectedTeam }) => {
  return (
    <>
      <Stack
        sx={styles.root}
        onClick={onClick}
        className="cursor-pointer custom-shadow"
      >
        <div className="flex py-[11px] flex items-center gap-2 mb-[10px]">
          <LeftArrowSvg />
          <SemiBoldText sx={styles.semiBoldText} variant="body1">
            {selectedTeam?.teamName}
          </SemiBoldText>
        </div>
      </Stack>
    </>
  );
};

const MobileUpgradeNavbar = () => {
  const navigate = useNavigate();
  const { selectedTeam, teams } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedTeam());
  }, [navigate, dispatch, teams]);

  const handleClick = () => {
    navigate(`/teams/${selectedTeam.teamId}/members`);
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={styles.navbar}
        width="100%"
      >
        <IconButton onClick={handleClick}>
          <ArrowLeftSvg fill="black" />
        </IconButton>
      </Stack>
    </>
  );
};

const MobileShowMemberNavbar = () => {
  return (
    <>
      <Stack direction="row" sx={styles.navbar} width="100%">
        <IconButton>
          <CloseSvg stroke={Colors.black} />
        </IconButton>
      </Stack>
    </>
  );
};

const TopNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [snackBarColor, setSnackBarColor] = useState("ok");
  const { userSearch } = useSelector((state) => state.test);
  const [daysRemaining, setDaysRemaining] = useState("");
  const { selectedTeam, selectedProjectId, workSpaceSearchResults, teams } =
    useSelector((state) => state.user);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [isActivePlan, setIsActivePlan] = useState(null);
  const [tempLoader, setTempLoader] = useState(false);

  const role = getUserRole(user?.uid, selectedTeam);
  const isAdmin = role === "admin";

  const open = Boolean(anchorEl);
  const handleOpenSubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (index) => {
    setAnchorEl(null);
    if (index === 1) {
      setOpenModal(true);
    }
  };

  const handleLeave = async () => {
    setLoading(true);

    const response = await leaveTeam({ role, teamId: selectedTeam?.teamId });
    if (
      response?.data?.status === "failed" &&
      response?.data?.error === "only one admin in team"
    ) {
      setLeaveModal(true);
    } else if (response?.data?.status === "success") {
      trackEvent("Workspace - Dropdown - Leave");
      navigate("/");
    }
    handleClose();
    setOpenModal(false);
    setLoading(false);
  };

  const menuItems = [
    {
      icon: <LeaveSvg />,
      text: "Leave Team",
      color: Colors.errorColor,
      onClick: () => {
        if (role === "admin" && selectedTeam.admin.length === 1) {
          setLeaveModal(true);
          handleClose();
          return;
        }
        setOpenModal(true);
      },
    },
  ];

  if (isAdmin) {
    menuItems.unshift({
      icon: <SettingSvg />,
      text: "Team Settings",
      onClick: () => {
        trackEvent("Workspace - Dropdown - Settings");
        navigate(`/teams/${selectedTeam.teamId}/settings`);
        handleClose();
      },
    });
  }

  const goBack = () => {
    navigate(`/teams/${selectedTeam?.teamId}/`);
  };
  useEffect(() => {
    dispatch(findWorkspaces(searchInput.trim()));
  }, [selectedTeam, selectedProjectId, searchInput]);

  useEffect(() => {
    dispatch(setSelectedTeam());
  }, [navigate, dispatch, teams]);

  const openLeftSideBar = () => {
    dispatch(toggleLeftMenu(true));
  };
  const openRightSideBar = () => {
    dispatch(toggleRightMenu(true));
  };

  const openWorkspaceSearch = () => {
    setSearchOpen(true);
  };
  const closeWorkspaceSearch = () => {
    setSearchOpen(false);
    setSearchInput("");
  };
  const getSearchBar = () => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <IconButton
          sx={{ mr: "24px", display: { xs: "flex", md: "none" } }}
          onClick={closeWorkspaceSearch}
        >
          <ArrowLeftSvg fill="black" />
        </IconButton>
        <TextField
          onChange={(e) => setSearchInput(e.target.value)}
          variant="standard"
          placeholder={"Search workspaces"}
          sx={{
            width: "100%",
            caretColor: Colors.primaryBtnColor,
            display: { xs: "flex", md: "none" },
            "& input": {
              color: Colors.black,
              fontSize: 14,
            },
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              backgroundColor: "transparent",
              color: Colors.white,
              paddingRight: 0,
              fontFamily: "Poppins",
            },
            endAdornment: (
              <Box sx={styles.searchSvgContainer}>
                <SearchSvg height="100%" width="100%" />
              </Box>
            ),
          }}
        />
        <TextField
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
          variant="standard"
          autoComplete="off"
          placeholder={"Search files"}
          sx={{
            width: "95%",
            border: "transparent !important",
            caretColor: Colors.primaryBtnColor,
            display: { xs: "none", md: "flex" },
            "& input": {
              color: Colors.black,
              fontSize: 14,
            },
            background: Colors.whiteFaded,
            borderRadius: "8px",
          }}
          InputProps={{
            disableUnderline: true,
            style: styles.textFieldInputProps,
            endAdornment: (
              <Box sx={styles.searchSvgContainer}>
                {searchInput.trim() ? (
                  <button onClick={() => setSearchInput("")}>
                    <CloseSvg stroke={Colors.black} height={16} width={16} />
                  </button>
                ) : (
                  <SearchSvg height="100%" width="100%" />
                )}
              </Box>
            ),
          }}
        />
      </Stack>
    );
  };

  const getHomeNav = useCallback(
    (location, openLeftSideBar) => {
      if (
        location.pathname === "/" ||
        location.pathname?.includes("invitation") ||
        location.pathname?.includes("/expired") ||
        location.pathname?.includes("/cancel")
      ) {
        return (
          <>
            <MobileHomeNavbar
              screen="Home"
              location={location}
              openLeftSideBar={openLeftSideBar}
            />
            <DesktopHomeNavbar location={location} />
          </>
        );
      }
    },
    [location.pathname]
  );

  const getSettingNav = useCallback(
    (location) => {
      const allowedPaths = ["settings", "members", "subscriptions"];
      if (allowedPaths.some((path) => location.pathname.includes(path))) {
        return (
          <>
            <MobileSettingNavbar
              location={location}
              openLeftSideBar={openLeftSideBar}
            />
            <SettingNavbar
              emails={emails}
              setEmails={setEmails}
              onClick={goBack}
              selectedTeam={selectedTeam}
            />
          </>
        );
      }
    },
    [openLeftSideBar, emails, goBack, selectedTeam]
  );

  const getUpgradeNav = useCallback(
    (location) => {
      if (
        location.pathname === "/upgrade" ||
        location.pathname.includes("/member/")
      ) {
        return (
          <>
            <MobileUpgradeNavbar
              location={location}
              openLeftSideBar={openLeftSideBar}
            />
            <SettingNavbar
              emails={emails}
              setEmails={setEmails}
              selectedTeam={selectedTeam}
            />
          </>
        );
      }
    },
    [location.pathname]
  );

  const getProfileNav = useCallback(
    (location) => {
      if (location.pathname === "/user-profile") {
        return (
          <>
            <MobileProfileNavbar
              openLeftSideBar={openLeftSideBar}
              location={location}
              screen="Profile"
            />
            <ProfileDesktopNavbar location={location} />
          </>
        );
      }
    },
    [location.pathname]
  );

  const getShowMemberNav = useCallback(
    (location) => {
      if (location.pathname === "/members") {
        return (
          <>
            <MobileShowMemberNavbar
              location={location}
              openLeftSideBar={openLeftSideBar}
            />
            <SettingNavbar
              emails={emails}
              setEmails={setEmails}
              selectedTeam={selectedTeam}
            />
          </>
        );
      }
    },
    [location.pathname]
  );

  function isPathAvoided(avoidPath, path) {
    return avoidPath.some((item) => path.includes(item));
  }

  const getWorkspaceNav = useCallback(
    (location) => {
      const avoidPath = [
        "/subscriptions",
        "/members",
        "/upgrade",
        "/user-profile",
        "/settings",
        "/invitation",
        "/viewer-invitation",
      ];
      let topPosition = "0px";
      if (
        isPathAvoided(avoidPath, location.pathname) ||
        location.pathname === "/" ||
        location.pathname.includes("/member/") ||
        location.pathname.includes("/expired") ||
        location.pathname.includes("/cancel")
      ) {
        return;
      }
      if (snackBarColor !== "ok") {
        topPosition = "48px";
      }

      return (
        <div className="custom-shadow">
          <SubMenu
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            menuItems={menuItems}
            width="17%"
            marginTop="25px"
          />
          <Stack sx={{ ...styles.workspaceDesktopNav, top: topPosition }}>
            <Stack direction="row" sx={styles.workspaceNav}>
              <Stack
                width="21%"
                pl="20px"
                pr="15px"
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Stack
                  sx={{
                    pr: "15px",
                  }}
                >
                  <SemiBoldText sx={styles.semiBoldText} variant="body1">
                    {checkStringLength(selectedTeam?.teamName, 25)}
                  </SemiBoldText>
                  <PlanBox plan={selectedTeam?.plan} />
                </Stack>
                <IconButton onClick={handleOpenSubMenu}>
                  <ArrowDownSvg />
                </IconButton>
              </Stack>
              <Box width="58%" textAlign="center">
                {getSearchBar()}
              </Box>
              <a
                href={`mailto:hey@ellenox.com`}
                className="text-newPrimary ml-auto mr-5"
                onClick={() =>
                  trackEvent("Workspace - Feedback", {
                    userName: user?.displayName,
                    email: user?.email,
                  })
                }
              >
                <MedText variant="body2">Submit Feedback</MedText>
              </a>
            </Stack>
            <hr style={styles.hr} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={styles.navbar}
          >
            <Stack direction="row" alignItems="center">
              <IconButton onClick={openLeftSideBar}>
                <MenuSvg height={12} width={18} />
              </IconButton>
              <SemiBoldText variant="body1" sx={styles.text}>
                {checkStringLength(selectedTeam?.teamName, 22)}
              </SemiBoldText>
            </Stack>
            <Stack direction="row">
              <IconButton onClick={openWorkspaceSearch}>
                <SearchSvg />
              </IconButton>
              <IconButton onClick={openRightSideBar}>
                <ProfileSvg height={24} width={24} fill="black" />
              </IconButton>
            </Stack>
          </Stack>
        </div>
      );
    },
    [
      anchorEl,
      getSearchBar,
      menuItems,
      open,
      openLeftSideBar,
      openRightSideBar,
      selectedTeam?.plan,
      selectedTeam?.teamName,
      snackBarColor,
    ]
  );

  const renderSearchScreen = useCallback(() => {
    const renderSearchResults = () => {
      if (workSpaceSearchResults?.length === 0 && searchInput) {
        return (
          <SemiBoldText
            variant="body1"
            sx={{ color: Colors.blackFaded54, mb: "10px" }}
            textAlign={"center"}
            mt="48px"
          >
            No File matching
            <SemiBoldText
              component="span"
              variant="body1"
              sx={{ color: Colors.black }}
              ml="5px"
            >
              {searchInput}
            </SemiBoldText>
          </SemiBoldText>
        );
      }
      if (workSpaceSearchResults?.length === 0 || !searchInput.trim()) return;
      return (
        <>
          <RegText variant="body1" sx={{ color: Colors.white, mb: "10px" }}>
            Search results
          </RegText>
          {workSpaceSearchResults?.map((item, index) => (
            <ProjectCard
              index={index}
              key={indexedDBLocalPersistence}
              name={item?.name}
              sessionTime={item?.sessionTime}
            />
          ))}
        </>
      );
    };

    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.navbar}
          className="custom-shadow"
        >
          {getSearchBar()}
        </Stack>
        <Stack sx={{ bgcolor: Colors.white, flex: 1, p: "16px" }} gap={2}>
          {renderSearchResults()}
        </Stack>
      </>
    );
  }, [searchOpen, userSearch, searchInput, workSpaceSearchResults]);

  const getSearchScreen = () => {
    return (
      <AppDrawer
        appDrawerProps={{
          open: searchOpen,
          anchor: "right",
          closeModal: closeWorkspaceSearch,
          drawerWidth: "100%",
        }}
        style={{ height: "100vh" }}
      >
        {renderSearchScreen()}
      </AppDrawer>
    );
  };

  const serviceEndDate = new Date(selectedTeam?.serviceEnd * 1000);
  const timeDiff = serviceEndDate?.getTime() - Date.now();
  const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  const onSubscriptionClick = async () => {
    if (!isActivePlan) return;

    setTempLoader(true);
    try {
      const { data } = await createPortalLink({
        returnUrl: `${process.env.REACT_APP_WEBSITE_URL}teams/${selectedTeam?.teamId}`,
      });
      window.location.assign(data?.url);
    } catch (error) {
      console.log(error);
      setTempLoader(false);
    }
    window.onload = () => {
      setTempLoader(false);
    };
    return;
  };
  const getServiceEndDateStatus = () => {
    if (location.pathname.includes("/teams") && serviceEndDate) {
      let type = "";
      let content = "";

      switch (true) {
        case daysLeft <= 1:
          type = "error";
          break;
        case daysLeft <= 7:
          type = "warning";
          break;
        default:
          type = "info";
          break;
      }

      if (type === "warning") {
        content = `Your ${truncateName}'s subscription plan expires within ${daysLeft} day(s).`;
      } else if (type === "error") {
        content = `Your ${truncateName}'s subscription plan has expired.`;
      } else {
        content = `Your ${truncateName}'s subscription plan status here.`;
      }

      // dispatch(
      //   toggleNotification({
      //     open: true,
      //     type,
      //     content,
      //     action: () => onSubscriptionClick(),
      //     btnText: "Pay Now",
      //   })
      // );
    }
  };

  useEffect(() => {
    if (!selectedTeam?.serviceEnd) {
      return;
    }
    getServiceEndDateStatus();
  }, [selectedTeam]);

  const renderModal = ({ heading, subHeading, open, setOpen, children }) => {
    return (
      <CommonModal
        heading={heading}
        subHeading={subHeading}
        open={open}
        setOpen={setOpen}
      >
        {children}
      </CommonModal>
    );
  };

  const renderLeaveTeam = () => {
    return renderModal({
      heading: "Leave team?",
      subHeading: isAdmin
        ? "Your files will not be deleted"
        : "Your files will remain active",
      open: openModal,
      setOpen: setOpenModal,
      children: (
        <Stack gap={2}>
          <CustomBtn type="error" onClick={handleLeave}>
            {loading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              "Leave"
            )}
          </CustomBtn>
          <CustomBtn type="secondary" onClick={() => setOpenModal(false)}>
            Cancel
          </CustomBtn>
        </Stack>
      ),
    });
  };

  const renderLeave = () => {
    return renderModal({
      heading: "You can't leave this team",
      subHeading:
        "To leave the team, please assign another member as an admin. ",
      open: leaveModal,
      setOpen: setLeaveModal,
      children: (
        <Stack gap={2}>
          <CustomBtn
            type="primary"
            onClick={() => {
              setLeaveModal(false);
              navigate(`/teams/${selectedTeam.teamId}/members`);
            }}
          >
            Update Roles
          </CustomBtn>
          <CustomBtn type="secondary" onClick={() => setLeaveModal(false)}>
            Close
          </CustomBtn>
        </Stack>
      ),
    });
  };
  useEffect(() => {
    try {
      (async function () {
        const res = await fetchCurrentTeamSubscription(selectedTeam?.teamId);
        setIsActivePlan(res);
      })();
    } catch (err) {
      console.log(err);
    }
  }, [selectedTeam?.teamId]);

  const truncateName =
    selectedTeam?.teamName?.length < 20
      ? `${selectedTeam?.teamName}`
      : `${selectedTeam?.teamName?.substring(0, 18)}...`;

  useEffect(() => {
    if (!selectedTeam?.teamId) return;
    const teamModerationRef = doc(
      firestoreDb,
      `TeamsModerations`,
      selectedTeam?.teamId
    );

    onSnapshot(teamModerationRef, (querySnapshot) => {
      if (querySnapshot?.data()?.isDeleted) {
        navigate("/");
        if (isAdmin) return;
        dispatch(
          toggleNotification({
            open: true,
            type: "warning",
            content: `${selectedTeam?.teamName} was deleted by ${
              querySnapshot.data().deletedBy
            }`,
          })
        );
      }
    });
  }, [selectedTeam]);

  return (
    <>
      <Notification tempLoader={tempLoader} />
      {renderLeave()}
      {renderLeaveTeam()}
      {getSearchScreen()}
      {getHomeNav(location, openLeftSideBar)}
      {getWorkspaceNav(location)}
      {getSettingNav(location, openLeftSideBar)}
      {getUpgradeNav(location, openLeftSideBar)}
      {getShowMemberNav(location, openLeftSideBar)}
      {getProfileNav(location, openLeftSideBar)}
    </>
  );
};

export default TopNavbar;
