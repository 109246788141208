import { useNavigate, useParams } from "react-router-dom";

import Select from "../../common/Select";
import CustomBtn from "../../common/CustomBtn";
import CommonModal from "../../common/CommonModal";

import { CheveronRightSvg, SmallUserSvg } from "../../../assets";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import {
  deleteMember,
  pendingMember,
  removePendingUser,
  teamModerationMember,
  updatePendingUserRole,
  updateRole,
} from "../../../services/member";
import { auth } from "../../../libraries/firebase";
import { Colors } from "../../../themes";
import { toggleNotification } from "../../../redux/appSlice";
import { getEditorSeats } from "../../../helper/validation";
import { MedText, SemiBoldText } from "../../../styles";
import { trackEvent } from "../../../utils/functions";

const MemberPage = () => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { selectedTeam } = useSelector((state) => state.user);
  const teamId = selectedTeam?.teamId;
  const [members, setMembers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [admins, setAdmins] = useState(0);
  const [noRoleChange, setNoRoleChange] = useState(false);
  const [noDelete, setNoDelete] = useState(false);
  const currentUser = auth?.currentUser;
  const isAdmin = selectedTeam?.admin?.some(
    (item) => item?.userId === currentUser?.uid
  );
  const editors = useMemo(
    () =>
      members.filter(
        (member) =>
          member.permission === "contributer" || member.permission === "admin"
      ),
    [members]
  );

  const dispatch = useDispatch();
  const editorSeats = getEditorSeats(selectedTeam?.plan);

  const fetchMembers = useCallback(async () => {
    setShowLoader(true);

    const teamModerationData = await teamModerationMember(teamId);

    const pendingInvitations = await pendingMember(teamId);

    const allMembers = new Set([]);

    function addMembersByRole(mems = [], role) {
      mems.forEach(async function (m) {
        allMembers.add({
          id: m.userId,
          name: m.name,
          email: m.email,
          permission: role,
          avatar: m.profileImage,
          status: "success",
        });
      });
    }

    addMembersByRole(teamModerationData.admin, "admin");
    if (teamModerationData?.viewers?.length) {
      addMembersByRole(teamModerationData.viewers, "viewer");
    }
    if (teamModerationData?.contributers?.length) {
      addMembersByRole(teamModerationData.contributers, "contributer");
    }

    pendingInvitations.forEach(function (inviteRef) {
      const invite = inviteRef.data();
      allMembers.add({
        id: inviteRef.id,
        email: invite.recipientEmail,
        permission: invite.role,
        status: "pending",
      });
    });
    setMembers(Array.from(allMembers));
    setShowLoader(false);

    setAdmins(teamModerationData.admin.length);
  }, [teamId]);

  useEffect(
    function () {
      if (teamId) {
        fetchMembers();
      }
    },
    [teamId, fetchMembers]
  );

  const member = members.find((m) => m.id === id);

  const handleDelete = async (member) => {
    if (!isAdmin) {
      return;
    }

    if (admins === 1 && member.id === currentUser?.uid) {
      setIsOpen(false);
      setNoDelete(true);
      return;
    }

    setShowLoader(true);

    await deleteMember(member?.id, teamId, member?.permission);
    trackEvent("Team Members - Remove");

    setShowLoader(false);
    setIsOpen(false);
    fetchMembers();
    navigate(`/teams/${teamId}/members`);
  };

  const handlePendingUserRole = async (member, event) => {
    const newRole = event.target.value;
    await updatePendingUserRole(member?.id, newRole);
    trackEvent("Team Members - Change Role", {
      old: member?.permission,
      new: newRole,
    });
    fetchMembers();
  };

  const handleUpdateRole = async (member, event) => {
    if (admins === 1 && member.id === currentUser?.uid) {
      setNoRoleChange(true);
      return;
    }
    setShowLoader(true);
    const newRole = event.target.value;

    await updateRole([member?.id], newRole, teamId);
    trackEvent("Team Members - Change Role", {
      old: member?.permission,
      new: newRole,
    });
    setShowLoader(false);

    fetchMembers();
  };

  const handleRemovePendingUser = async (member) => {
    await removePendingUser(member?.id);
    handleClose();
    navigate(`/teams/${teamId}/members`);
    fetchMembers();
  };

  const userName = (member) => {
    if (member?.status === "success") {
      return member?.name;
    } else {
      return (
        <MedText
          variant="caption"
          className="bg-black/30 text-white text-xs py-1 px-2   rounded-[8px] w-20"
        >
          Pending
        </MedText>
      );
    }
  };

  const userRemoveName = (member) => {
    if (member?.status === "success") {
      return member?.name;
    } else {
      return member?.email;
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenUpdateModal = () => {
    setNoRoleChange(false);
  };

  const handleOpenDeleteModal = () => {
    setNoDelete(false);
  };

  const showEmailSeatNotification = () => {
    dispatch(
      toggleNotification({
        open: true,
        content: "Editors seat limit reached!",
        type: "warning",
        action: () => {
          if (isAdmin) {
            dispatch(
              toggleNotification({
                open: false,
              })
            );
            navigate(`/teams/${teamId}/subscriptions`);
          }
        },
        btnText: isAdmin ? "Upgrade Plan" : "",
        persist: false,
      })
    );
  };

  return (
    <div className="text-black px-4">
      {showLoader && (
        <div className="flex justify-center items-center h-[50vh]">
          <CircularProgress sx={{ color: Colors.newPrimary }} size={30} />
        </div>
      )}
      {!showLoader && (
        <div>
          <div className="flex items-start items-center justify-start gap-3 py-4">
            <SemiBoldText
              variant="body1"
              className="text-black/60 font-semibold"
            >
              Members
            </SemiBoldText>
            <div className="scale-75 opacity-60">
              <CheveronRightSvg />
            </div>
            <SemiBoldText variant="body1">
              {member?.status === "pending" ? member?.email : member?.name}
            </SemiBoldText>
          </div>
          <div className="flex gap-2 py-2 ">
            {member?.avatar ? (
              <img
                src={member?.avatar}
                alt={member?.name}
                className="h-8 w-8 object-cover rounded-[2px]"
              />
            ) : (
              <SmallUserSvg height={32} width={32} />
            )}
            <div className="grow">
              <div className="">
                <h1 className="mt-1 pb-4 border-b border-b-black/20 font-medium text-sm">
                  {userName(member)}
                </h1>
              </div>
              <div className="py-3 border-b border-b-black/20">
                <MedText variant="body2" className="text-sm font-normal ">
                  Email ID
                </MedText>
                <MedText
                  variant="caption"
                  className="text-xs text-black/60 mt-2"
                >
                  {member?.email}
                </MedText>
              </div>
              <div className="flex justify-between items-center py-3 border-b border-b-white/20">
                <MedText variant="body2">Roles</MedText>
                <Select
                  value={member?.permission}
                  onChange={(event) => {
                    if (
                      event?.target?.value === "contributer" &&
                      editors.length >= editorSeats
                    ) {
                      showEmailSeatNotification();
                      return;
                    }

                    if (member.status === "pending") {
                      handlePendingUserRole(member, event);
                      return;
                    }
                    handleUpdateRole(member, event);
                  }}
                />
              </div>
              <button
                onClick={() => {
                  if (member.status === "pending") {
                    handleRemovePendingUser(member);
                    return;
                  }
                  handleOpen();
                }}
                className="text-red py-3 flex gap-2"
              >
                <MedText variant="body2">
                  {member?.status === "pending" ? "Cancel Invite" : "Remove"}{" "}
                </MedText>
                <MedText variant="body2" className="font-semibold">
                  {userRemoveName(member)}
                </MedText>
              </button>
            </div>
          </div>
          <CommonModal
            heading={`Remove ${userRemoveName(member)}`}
            subHeading={`Are you sure you want to remove ${userRemoveName(
              member
            )}?`}
            open={isOpen}
          >
            <div className="flex flex-col gap-4">
              <CustomBtn onClick={() => handleDelete(member)}>Remove</CustomBtn>
              <CustomBtn type="secondary" onClick={handleClose}>
                Cancel
              </CustomBtn>
            </div>
          </CommonModal>

          <CommonModal
            heading={"You can't change the role"}
            subHeading={"You are the only Admin you can't change your role."}
            open={noRoleChange}
          >
            <CustomBtn type="secondary" onClick={handleOpenUpdateModal}>
              Close
            </CustomBtn>
          </CommonModal>

          <CommonModal
            heading={"You can't delete yourself"}
            subHeading={"You are the only admin you can't delete yourself."}
            open={noDelete}
          >
            <CustomBtn type="secondary" onClick={handleOpenDeleteModal}>
              Close
            </CustomBtn>
          </CommonModal>
        </div>
      )}
    </div>
  );
};

export default MemberPage;
