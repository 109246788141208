import { Colors } from "../../../themes";

export const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    py: { xs: "18px", md: "10px" },
    direction: "row",
    justifyContent: "space-between",
    px: "16px",
    position: "sticky",
    top: 0,
    zIndex: 999,
  },
  showDesktop: { display: { xs: "none", md: "flex" } },
  button: {
    textTransform: "inherit",
    height: "25px",
    px: "12px",
    display: { xs: "none", md: "flex" },
    "&:hover": {
      bgcolor: Colors.black,
    },
  },
  payNowContainer: {
    borderRadius: "4px",
    p: "7px 12px",
    display: { xs: "flex", md: "none" },

    alignItems: "center",
    flexDirection: "row",
  },
  divider: {
    bgcolor: Colors.whiteFaded20,
    width: "2px",
    height: "16px",
  },
};
