import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { firestoreDb } from "../libraries/firebase";

export const useAnonymousLimit = (user) => {
  const [limitReached, setLimitReached] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe;

    if (user?.isAnonymous) {
      try {
        unsubscribe = onSnapshot(
          doc(firestoreDb, "AnonymousLimits", user.uid),
          (doc) => {
            const data = doc.data();

            if (data) {
              setLimitReached(data.limitReached);
            } else {
              setLimitReached(false);
            }
            setLoading(false);
            setError(null);
          },
          (error) => {
            console.error("Error in snapshot listener:", error);
            setError(error);
            setLoading(false);
          }
        );
      } catch (err) {
        console.error("Error setting up snapshot:", err);
        setError(err);
        setLoading(false);
      }
    } else {
      setLimitReached(false);
      setLoading(false);
      setError(null);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user]);

  return {
    limitReached,
    error,
    loading,
  };
};
