import { Box, IconButton, useMediaQuery } from "@mui/material";
import UserLogo from "../../../assets/svg/user.svg";
import { useRef } from "react";
import { EditThreeSvg } from "../../../assets";

const ProfilePic = ({ image, setImage, setImageError }) => {
  const ref = useRef(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg")); // Change to "lg"

  let svgIconStyle = {
    width: isSmallScreen ? 17 : 32,
    height: isSmallScreen ? 17 : 32,
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (
      selectedImage.type !== "image/png" &&
      selectedImage.type !== "image/jpeg" &&
      selectedImage.type !== "image/jpg"
    ) {
      setImageError("Please upload only PNG or JPEG images.");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(selectedImage);
    reader.onload = () => {
      setImage(reader.result);
      setImageError("");
    };
  };

  const renderImage = () => {
    if (image) {
      return (
        <img
          src={image}
          alt="Profile"
          className="h-[70.8px] w-[70.8px] object-cover lg:h-[126px] lg:w-[126px] rounded-lg" // Change to "lg"
        />
      );
    }
    return (
      <div className="w-[80.8px] h-[80.8px] lg:w-[126px] lg:h-[126px] flex align-middle justify-center rounded-lg bg-white/10 mx-auto mt-2 mb-10 p-[17px]">
        <img src={UserLogo} alt="userlogo" />
      </div>
    );
  };

  return (
    <div
      className="h-[70.8px] w-[70.8px] object-cover lg:h-[126px] lg:w-[126px] rounded-lg relative cursor-pointer"
      onClick={() => ref.current.click()}
    >
      {renderImage()}
      <div className="absolute bottom-[-18px] right-[-16px] lg:bottom-[-18px] lg:right-[-18px]">
        <IconButton>
          <EditThreeSvg style={svgIconStyle} />
        </IconButton>
      </div>
      <input
        ref={ref}
        type="file"
        id="profile-picture"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ProfilePic;
