import { useCallback, useEffect, useState } from "react";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TextArea from "../../common/TextArea";
import CustomBtn from "../../common/CustomBtn";
import { MedText, RegText, SemiBoldText } from "../../../styles";
import { Colors } from "../../../themes";
import { CloseSvg, DoThisLater, UnboardLogoSvg } from "../../../assets";
import { validateInput } from "../../../helper/validation";
import ProfilePic from "../../common/ProfilePic";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { firestoreDb, storage, storageRef } from "../../../libraries/firebase";
import { getDownloadURL, uploadString } from "firebase/storage";
import Input from "../../common/Input";
import InviteCollaborators from "../../common/InviteCollaborators";
import UpgradePlanScreen from "../UpgradePlanScreen";
import { Services } from "../../../services";
import { motion, AnimatePresence } from "framer-motion";
import { toggleNotification } from "../../../redux/appSlice";
import Notification from "../../common/Notification";
import { fetchInvoices } from "../../../services/payment";
import { capitalize } from "../../../helper/formatting";
import { trackEvent } from "../../../utils/functions";

const AddMemberScreen = ({
  userData,
  emails,
  setEmails,
  team,
  showUpgradePlan,
  setShowUpgradePlan,
  lastPlan,
  emailLimit,
  navigate,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getDisabledValue = () => {
    if (!emails.length || emails.length >= emailLimit - 1) return true;

    return false;
  };

  const handleInvite = async () => {
    if (!emails.length) return;

    const senderInfo = team?.admin?.filter(
      (adminItem) => adminItem.userId === userData?.uid
    )[0];

    const filteredEmail = emails.filter(
      (item) => item?.email !== userData?.email
    );

    setLoading(true);
    trackEvent("Create Team - Send Invites", {
      collaborators: Number(filteredEmail?.length),
    });
    const result = await Services.InviteServices.sendInviteEmails(
      team?.teamId,
      team?.teamName,
      senderInfo?.email,
      senderInfo?.name,
      filteredEmail
    );
    setLoading(false);

    if (result === "success") {
      setEmails([]);
      navigateToTeam();
    }
  };

  const navigateToTeam = () => {
    trackEvent("Create Team - Skip Invites");
    localStorage.removeItem("storedTeam");
    localStorage.removeItem("storedEmails");
    dispatch(
      toggleNotification({
        open: false,
      })
    );
    navigate(`/teams/${team?.teamId}`);
  };

  return (
    <div>
      <AnimatePresence>
        {showUpgradePlan && (
          <motion.div className="flex md:items-center justify-center h-screen md:mx-[40px] my-10">
            <UpgradePlanScreen
              setShowUpgradePlan={setShowUpgradePlan}
              type={lastPlan === "free" ? "create-team" : ""}
              teamId={team?.teamId}
              createdTeamInfo={team}
              lastPlan={lastPlan}
            />
          </motion.div>
        )}
        {emails?.length >= emailLimit - 1 && (
          <div className="fixed top-0 right-0 w-full z-20">
            <Notification />
          </div>
        )}
      </AnimatePresence>
      {!showUpgradePlan && (
        <div className="mx-auto w-[min(600px,_100%)] flex flex-col justify-center items-stretch text-black  px-4 h-[100vh]">
          <Stack gap="5px" alignItems="center" mb="20px">
            <MedText variant="body1">Invite Collaborators</MedText>
            <RegText
              variant="body2"
              textAlign="center"
              sx={{ color: Colors.blackFaded54 }}
            >
              You can update user permissions on the team page after setting up.
            </RegText>
          </Stack>

          <div className="h-[50vh] overflow-y-auto">
            <InviteCollaborators
              emails={emails}
              setEmails={setEmails}
              type="show-btn"
              team={team}
            />
          </div>
          <Stack gap="24px" alignItems="center">
            <CustomBtn
              type={"primary"}
              styles={{ width: { xs: "350px", md: "440px" } }}
              onClick={handleInvite}
              disabled={loading || getDisabledValue()}
            >
              {loading ? (
                <CircularProgress sx={{ color: Colors.white }} size={30} />
              ) : (
                "Send Invite & Continue"
              )}
            </CustomBtn>
            <CustomBtn
              type="secondary"
              onClick={navigateToTeam}
              disabled={loading}
            >
              Skip for now
            </CustomBtn>
          </Stack>
        </div>
      )}
    </div>
  );
};

const CreateTeamScreen = () => {
  const { step: paramsStep = 1 } = useParams();
  const { teams, data: userData } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.app);
  const storedTeam = JSON.parse(localStorage.getItem("storedTeam"));
  const storedEmails = JSON.parse(localStorage.getItem("storedEmails"));
  const [imageError, setImageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState(storedEmails ?? []);
  const [team, setTeam] = useState(storedTeam ?? null);
  const [image, setImage] = useState(team?.profileImage ?? null);
  const [teamName, setTeamName] = useState(team?.teamName ?? "");
  const [teamNameError, setTeamNameError] = useState("");
  const [about, setAbout] = useState(team?.teamAbout ?? "");
  const [aboutTeamError, setAboutTeamError] = useState("");
  const [step, setStep] = useState(1);
  const [showUpgradePlan, setShowUpgradePlan] = useState(false);
  const [lastPlan, setLastPlan] = useState("free");
  const navigate = useNavigate();
  const isDisabled = teamName.length < 2 || teamName.length > 25;
  const closeNavigateLink =
    teams.length > 0 ? `/teams/${teams[0].teamId}` : "/";
  const dispatch = useDispatch();
  const emailLimit = lastPlan === "free" ? 4 : lastPlan === "premium" ? 6 : 13;

  useEffect(() => {
    setStep(Number(paramsStep));
  }, [paramsStep]);

  useEffect(() => {
    if (emails.length >= emailLimit - 1) {
      console.log("limit");
      setShowUpgradePlan(true);
      dispatch(
        toggleNotification({
          open: true,
          content: `${capitalize(lastPlan)} Access only allows ${
            emailLimit - 1
          } editors`,
          type: "warning",
          action: () => {
            setShowUpgradePlan(true);
          },
          btnText: "Upgrade Plan",
          persist: true,
        })
      );
    }
  }, [emails, dispatch]);

  useEffect(() => {
    if (!team?.teamId) {
      return;
    }
    (async function getInvoices() {
      const response = await fetchInvoices(team?.teamId);
      const invoiceData =
        response?.[0]?.lines?.data[response?.[0]?.lines?.data?.length - 1];
      if (invoiceData) {
        setLastPlan(getPlanName(invoiceData?.price?.product)?.toLowerCase());
      }
    })();
  }, [team?.teamId]);

  const getPlanName = (productId) => {
    const planName =
      products?.filter((productItem) => productItem?.id === productId)[0]
        ?.name ?? "free";

    return capitalize(planName);
  };

  const imageUpload = async (teamId) => {
    if (!image) return;
    try {
      const imageRef = storageRef(storage, `Teams/${teamId}/profile`);
      const uploadedImage = await uploadString(imageRef, image, "data_url");
      const downloadUrl = await getDownloadURL(uploadedImage.ref);
      return downloadUrl;
    } catch (e) {
      console.log(e);
    }
  };

  const createTeam = async () => {
    if (team) {
      setLoading(true);
      const isImageChanged = team?.profileImage !== image;
      const res = await Services.TeamServices.updateTeam(
        team?.teamId,
        teamName,
        about,
        image,
        isImageChanged
      );

      if (res === "success") {
        setTeam({
          ...team,
          teamName: teamName,
          teamAbout: about,
          profileImage: image,
        });
        localStorage.setItem(
          "storedTeam",
          JSON.stringify({
            ...team,
            teamName: teamName,
            teamAbout: about,
            profileImage: image,
          })
        );
        setLoading(false);
        navigate("/create-team/2");
      }
      setLoading(false);
      return;
    }

    const batch = writeBatch(firestoreDb);
    setLoading(true);
    try {
      const userFullName = userData?.firstName + " " + userData?.lastName;
      const teamCollectionData = {
        name: teamName,
        about: about,
        createdAt: serverTimestamp(),
        profileImage: "",
        isDeleted: false,
      };
      const teamModerationsCollectionData = {
        admin: [
          {
            name: userFullName,
            email: userData?.email,
            profileImage: userData?.profileImage,
            userId: userData?.uid,
          },
        ],
        contributers: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        userIds: [userData?.uid],
        viewers: [],
        teamName,
        teamAbout: about,
        isDeleted: false,
      };
      const docRef = await addDoc(
        collection(firestoreDb, "Teams"),
        teamCollectionData
      );
      trackEvent("Create Team - Create", {
        teamName,
        teamID: docRef?.id,
      });
      const { id } = await Services.ProjectServices.createProject(
        docRef?.id,
        "Let’s get started"
      );
      Services.WorkSpacesServices.createWorkspace(
        docRef?.id,
        id,
        "Explore with your team"
      );
      const profileImage = await imageUpload(docRef.id);
      const teamDocRef = doc(firestoreDb, "Teams", docRef.id);
      const teamsModerationDocRef = doc(
        firestoreDb,
        "TeamsModerations",
        docRef.id
      );
      batch.update(teamDocRef, {
        profileImage: profileImage ?? "",
      });
      batch.set(teamsModerationDocRef, {
        ...teamModerationsCollectionData,
        profileImage: profileImage ?? "",
        teamId: docRef.id,
      });
      await batch.commit();
      setTeam({
        ...teamModerationsCollectionData,
        profileImage,
        teamId: docRef.id,
      });
      localStorage.setItem(
        "storedTeam",
        JSON.stringify({
          ...teamModerationsCollectionData,
          profileImage,
          teamId: docRef.id,
        })
      );
      handleSetPlan("free", docRef.id);
      navigate("/create-team/2");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleTeamNameChange = (e) => {
    const inputValue = e.target.value;
    validateInput(inputValue, setTeamNameError, "Team name");
    setTeamName(inputValue);
  };

  const handleAboutTeamChange = (e) => {
    const inputValue = e.target.value;
    validateInput(inputValue, setAboutTeamError, "About team");
    setAbout(inputValue);
  };

  const handleSetPlan = async (planType, teamIdParam) => {
    let teamIdToBeSet = teamIdParam;
    if (team?.teamId) {
      teamIdToBeSet = team?.teamId;
    }
    try {
      await Services.TeamServices.setPlanType(
        teamIdToBeSet,
        planType ?? "free"
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {step === 1 && (
        <div className="flex justify-between max-w-[83rem] custom-shadow md:!shadow-none px-4 mx-auto h-[60px] items-center mb-[29px]">
          <UnboardLogoSvg
            fill={Colors.newPrimary}
            className="h-[19px] w-[94px]"
            onClick={() => navigate(-1)}
            cursor="pointer"
          />
          <button
            className="text-newPrimary flex items-center gap-2"
            onClick={() => {
              trackEvent("Create Team - Later");
              navigate(-1);
            }}
          >
            <SemiBoldText variant="body2" className="!hidden md:!block">
              Do this later
            </SemiBoldText>
            <DoThisLater />
          </button>
        </div>
      )}

      {step === 1 && (
        <div className="">
          <div className="mx-auto w-[min(440px,_100%)] flex flex-col items-stretch text-black px-4">
            <h1 className="text-center font-semibold text-lg">Create a team</h1>
            <Stack alignItems="center" className="my-5 md:my-10">
              <ProfilePic
                image={image}
                setImage={setImage}
                setImageError={setImageError}
              />
              {imageError && (
                <RegText sx={styles.errorMessage}>{imageError}</RegText>
              )}
            </Stack>
            <Stack gap="25px" component="form">
              <Stack gap="10px">
                <Input
                  placeholder="Team name"
                  type="text"
                  value={teamName}
                  onChange={handleTeamNameChange}
                />
                {teamNameError && (
                  <RegText
                    sx={{
                      color: Colors.errorColor,
                      fontSize: "14px",
                    }}
                  >
                    {teamNameError}
                  </RegText>
                )}
              </Stack>
              <Stack>
                <TextArea
                  placeholder="About"
                  value={about}
                  onChange={handleAboutTeamChange}
                  marginBottom={10}
                />
                {aboutTeamError && (
                  <RegText sx={styles.aboutTeamErrorStyles}>
                    {aboutTeamError}
                  </RegText>
                )}
              </Stack>
            </Stack>
            <div className="flex flex-col justify-end mt-40 mb-10 md:my-8">
              <CustomBtn
                type={"primary"}
                onClick={createTeam}
                disabled={loading || isDisabled}
              >
                {loading ? (
                  <CircularProgress size={30} sx={{ color: Colors.white }} />
                ) : !!team ? (
                  "Save changes"
                ) : (
                  "Create a team"
                )}
              </CustomBtn>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <AddMemberScreen
          emails={emails}
          loading={loading}
          setEmails={setEmails}
          team={team}
          userData={userData}
          showUpgradePlan={showUpgradePlan}
          setShowUpgradePlan={setShowUpgradePlan}
          lastPlan={lastPlan}
          emailLimit={emailLimit}
          navigate={navigate}
        />
      )}
    </>
  );
};

export default CreateTeamScreen;
const styles = {
  imageErrorStyles: {
    color: Colors.errorColor,
    fontSize: "14px",
    marginTop: "24px",
  },
  aboutTeamErrorStyles: {
    color: Colors.errorColor,
    fontSize: "14px",
    mt: "10px",
  },
  errorMessage: {
    color: Colors.errorColor,
    fontSize: "14px",
    marginTop: "16px",
    marginBottom: "4px",
  },
};
