import { Colors } from "../../../themes";

export const styles = {
  descriptionText1: {
    variant: "body1",
    sx: {
      color: Colors.white,
      display: { xs: "none", md: "block" },
      textAlign: "center",
    },
  },
  customBtn: {
    type: "primary",
    styles: {
      width: { xs: "90%", md: "440px" },
      mt: { xs: "39px", md: "64px" },
      mb: {
        xs: "25%",
        md: "0",
      },
    },
  },
};
