import axios from "axios";
const chatgptUrl = "https://api.openai.com/v1/completions";
const chatgptModel = "text-davinci-002";
const apiKey = process.env.REACT_APP_CHAT_GPT_API_KEY;

export const getChatGptResponse = async (text) => {
  const params = {
    model: chatgptModel,
    temperature: 0.7,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    prompt: text,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };
  try {
    const response = await axios.post(chatgptUrl, params, { headers });
    if (response.data.choices[0].text) return response.data.choices[0].text;
    return ""; // default response if there is no choice response
  } catch (error) {
    // handle error
    console.log("error: ", error);
  }
};
