import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  auth,
  onSnapshot,
  collection,
  firestoreDb,
  query,
  where,
  orderBy,
} from "./libraries/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserData,
  fetchSubscriptionFeatures,
  fetchPricing,
  fetchProducts,
} from "./redux/asyncThunk";
import { setUserTeams, setUserRoles } from "./redux/userSlice";
import Loading from "./components/common/Loading";
import { getUserRolesInTeams } from "./utils/functions";
import { motion, AnimatePresence } from "framer-motion";
import { setInitialLoader } from "./redux/appSlice";
const PrivateRoutes = ({ children }) => {
  const { data, status } = useSelector((state) => state.user);
  const { loadInitialLoader } = useSelector((state) => state.app);
  const [loading, setLoading] = useState(true);
  const [user, userLoading] = useAuthState(auth);
  const [animationOver, setAnimationOver] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invitedTeamId, teamId } = useParams();
  useEffect(() => {
    if (invitedTeamId) {
      localStorage.setItem(
        "openInvitationAfterSignIn",
        JSON.stringify(invitedTeamId)
      );
    }
    if (teamId) {
      localStorage.setItem(
        "openViwerInvitationAfterSignIn",
        JSON.stringify(teamId)
      );
    }
  }, []);

  const fetchData = async () => {
    try {
      if (user) {
        const promises = [
          dispatch(fetchUserData(user?.uid)),
          dispatch(fetchSubscriptionFeatures()),
          dispatch(fetchPricing()),
          dispatch(fetchProducts()),
          new Promise((resolve) => {
            const unsub = onSnapshot(
              query(
                collection(firestoreDb, "TeamsModerations"),
                where("isDeleted", "==", false),
                where("userIds", "array-contains", user?.uid),
                orderBy("createdAt", "desc")
              ),
              (snap) => {
                const userTeams = snap.docs.map((item) => {
                  return {
                    ...item?.data(),
                    updatedAt: item?.data()?.updatedAt?.seconds,
                    createdAt: item?.data()?.createdAt?.seconds,
                    serviceEnd: item?.data()?.serviceEnd?.seconds,
                    serviceStart: item?.data()?.serviceStart?.seconds,
                  };
                });
                dispatch(setUserTeams(userTeams));
                dispatch(
                  setUserRoles(getUserRolesInTeams(userTeams, user?.uid))
                );
                resolve();
              }
            );
            return () => unsub();
          }),
        ];
        await Promise.all(promises);
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };
  const loadApp = async () => {
    try {
      if (status === "complete" && (!data || !data?.exist) && user) {
        navigate("/signup");
      } else {
        await fetchData();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!userLoading) {
      if (user?.isAnonymous) {
        setLoading(false);
        return;
      }
      loadApp();
    }
  }, [status, user, userLoading]);

  const isLoading = userLoading || loading;
  useEffect(() => {
    if (!isLoading) {
      dispatch(setInitialLoader(false));
    }
  }, [isLoading]);
  if (user !== null && user?.isAnonymous) {
    navigate("/signin");
    return
  }

  if (user !== null && user) {
    return <>{isLoading ? <Loading isLoading={isLoading} /> : children}</>;
  }
  if (loading) return;
  return <Navigate to="/signin" replace />;
};

export default PrivateRoutes;
