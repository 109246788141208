import { CircularProgress } from "@mui/material";
import React, { Fragment } from "react";
import { Colors } from "../../../themes";

const MemberPhone = ({ showLoader, members, userInfo }) => {
  return (
    <div className="md:hidden fixed right-0 left-0 bottom-[225px] top-[72px] overflow-y-auto">
      <div className="px-4 text-black">
        <div className="flex justify-between py-6">
          <h1 className="font-semibold ">Members</h1>
        </div>
        {members.map((member) => (
          <Fragment key={member.id}>{userInfo(member)}</Fragment>
        ))}
        {showLoader && (
          <div className="flex justify-center items-center h-[40vh]">
            <CircularProgress sx={{ color: Colors.white }} size={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberPhone;
