import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Select from "../../common/Select";
import CustomModal from "../../common/CommonModal";
import CustomBtn from "../../common/CustomBtn";
import SettingNav from "../../common/SettingNav";
import Invite from "../../../assets/svg/invite.svg";
import Close from "../../../assets/svg/close.svg";
import CommonModal from "../../common/CommonModal";

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import AppDrawer from "../../common/AppDrawer";
import { LightText, MedText, RegText } from "../../../styles";
import InviteCollaborators from "../../common/InviteCollaborators";
import { Colors } from "../../../themes";
import { styles } from "./styles";

import {
  ArrowLeftSvg,
  DotMenuSvg,
  CheveronRightSvg,
  SmallUserSvg,
  LinkSvg,
  CloseSvg,
  Images,
} from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMember,
  deleteSelectedMembers,
  pendingMember,
  removePendingUser,
  teamModerationMember,
  updatePendingUserRole,
  updateRole,
} from "../../../services/member";
import {
  auth,
  collection,
  doc,
  firestoreDb,
  onSnapshot,
  query,
  setDoc,
  where,
} from "../../../libraries/firebase";
import { AnimatePresence, motion } from "framer-motion";
import PlanBox from "../../common/PlanBox";
import MemberDetails from "../../common/MemberDetails";
import MemberPhone from "../../common/MembersPhone";
import { getEditorSeats, getEmailLimit } from "../../../helper/validation";
import { toggleNotification } from "../../../redux/appSlice";
import { capitalize } from "../../../helper/formatting";
import moment from "moment";
import {
  removeCommonElements,
  adjustPendingMembers,
  checkStringLength,
  trackEvent,
} from "../../../utils/functions";

const UserAvatar = ({ avatar, name }) => (
  <div className="">
    {avatar?.length ? (
      <img
        src={avatar}
        alt={name}
        className="h-8 w-8 object-cover rounded-full"
      />
    ) : (
      <SmallUserSvg height={32} width={32} />
    )}
  </div>
);

const UserInfo = ({
  member,
  showMemberCheckboxes,
  selectedMembers,
  handleMemberCheckboxChange,
}) => (
  <div className="hidden md:block">
    <div className="flex gap-2 items-center">
      {showMemberCheckboxes ? (
        <Checkbox
          checked={selectedMembers.includes(member.id)}
          style={{
            color: Colors.black,
            padding: 0,
          }}
          onChange={(event) => handleMemberCheckboxChange(event, member.id)}
        />
      ) : (
        <div
          style={{
            width: "24px",
            height: "24px",
          }}
        ></div>
      )}
      <UserAvatar avatar={member.avatar} name={member.name} />
      <MedText variant="body2" className="!text-black/80">
        {member.name}
      </MedText>
    </div>
  </div>
);

const PopUp = ({
  handleUpdateRoles,
  selectedMembers,
  handleCopyEmails,
  setOpenDeleteModal,
  setSelectedMembers,
  openDeleteModal,
  handleDeleteMembers,
  copied,
  setCopid,
}) => (
  <div className="rounded-lg flex justify-between items-center px-4 py-2 bg-black w-[600px] mx-auto">
    <p className="text-xs text-white/70">
      <p className="text-xs text-white/70">
        {`${selectedMembers.length} users selected`}
      </p>
    </p>
    <div className="flex gap-4">
      <div className="bg-white/20 text-xs text-white px-3 py-1.5 rounded-lg">
        <select
          className="bg-transparent"
          onChange={(event) => handleUpdateRoles(selectedMembers, event)}
        >
          <option selected disabled className="bg-grey2 text-white">
            Update user role
          </option>
          <option value="admin" className="bg-grey2">
            Admin
          </option>
          <option value="viewer" className="bg-grey2">
            Viewer
          </option>
          <option value="contributer" className="bg-grey2">
            Editor
          </option>
        </select>
      </div>
      <button
        className="bg-white/20 text-xs text-white px-3 py-1.5 rounded-lg"
        onClick={handleCopyEmails}
      >
        Copy Emails
      </button>
      <button
        className="bg-red/20 text-xs text-red px-3 py-1.5 rounded-lg"
        onClick={() => setOpenDeleteModal(true)}
      >
        Remove From Team
      </button>
      <button
        className="ml-4 text-white"
        onClick={() => setSelectedMembers([])}
      >
        <CloseSvg stroke={Colors.whiteFaded70} />
      </button>
    </div>
    <Snackbar
      open={copied}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={() => setCopid(false)}
    >
      <Alert
        variant="filled"
        severity="success"
        sx={{ backgroundColor: "white", color: "black" }}
      >
        Emails are copied
      </Alert>
    </Snackbar>
    <CommonModal
      open={openDeleteModal}
      heading="Remove all users?"
      subHeading={
        "Are you sure you want to remove all users? Once this action is done, it can't be undone."
      }
    >
      <div className="flex flex-col gap-4">
        <CustomBtn type="error" onClick={handleDeleteMembers}>
          Remove all
        </CustomBtn>
        <CustomBtn type="secondary" onClick={() => setOpenDeleteModal(false)}>
          Cancel
        </CustomBtn>
      </div>
    </CommonModal>
  </div>
);

const MobileUserInfo = ({ member, teamId }) => (
  <>
    <Link
      to={`/teams/${teamId}/member/${member.id}`}
      component="div"
      className="flex justify-between items-center py-2 md:hidden"
    >
      <Stack direction="row" alignItems="center" gap={"8px"}>
        <UserAvatar avatar={member.avatar} name={member.name} />
        <div className="">
          <MedText variant="body2">{member.name}</MedText>
          <MedText variant="caption" className="text-black/60">
            {member.permission}
          </MedText>
        </div>
      </Stack>

      <CheveronRightSvg />
    </Link>
    <hr className="border-black/25 ml-10 my-4" />
  </>
);

const PendingUserInfo = () => (
  <div className="hidden md:block">
    <div className="flex gap-2 items-center">
      <div
        style={{
          width: "24px",
          height: "24px",
        }}
      ></div>
      <SmallUserSvg height={32} width={32} />
      <Box {...styles.pendingTextWrapper}>
        <MedText style={styles.pendingText} sx={{ color: Colors.blackFaded54 }}>
          Pending
        </MedText>
      </Box>
    </div>
  </div>
);

const MobilePendingUserInfo = ({ member, teamId }) => (
  <>
    <Link
      to={`/teams/${teamId}/member/${member.id}`}
      component="div"
      className="flex justify-between items-center py-2 md:hidden"
    >
      <Stack direction="row" alignItems="center" gap={"8px"}>
        <img
          src={Images.userPlaceholder}
          height={32}
          width={32}
          alt="pending user"
        />
        <div className="">
          <MedText variant="body2">
            <span className="block sm:hidden">
              {checkStringLength(member?.email, 30)}
            </span>
            <span className="hidden sm:block">
              {checkStringLength(member?.email, 80)}
            </span>
          </MedText>
          <MedText variant="caption" className="text-black/60">
            {member?.permission}
          </MedText>
        </div>
      </Stack>

      <CheveronRightSvg />
    </Link>
    <hr className="border-black/25 ml-10 my-4" />
  </>
);
const ShowMembersScreen = () => {
  const { selectedTeam } = useSelector((state) => state.user);
  const teamId = selectedTeam?.teamId;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [clickedMemberId, setClickedMemberId] = useState(null);
  const [isDotClicked, setIsDotClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openInviteScreen, setOpenInviteScreen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const showMemberCheckboxes = Boolean(selectedMembers.length);
  const [showLoader, setShowLoader] = useState(false);
  const [componentLoader, setComponentLoader] = useState(false);
  const [isEndOfScreen, setIsEndOfScreen] = useState(false);
  const selectedMember = members.find(
    (member) => member.id === clickedMemberId
  );
  const [admins, setAdmins] = useState(0);
  const [noRoleChange, setNoRoleChange] = useState(false);
  const [noDelete, setNoDelete] = useState(false);
  const [copied, setCopid] = useState(false);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState();

  const currentUser = auth?.currentUser;
  const isAdmin = selectedTeam?.admin?.some(
    (item) => item?.userId === currentUser?.uid
  );

  const navigate = useNavigate();
  const editors = useMemo(
    () =>
      members.filter(
        (member) =>
          member.permission === "contributer" || member.permission === "admin"
      ),
    [members]
  );
  const emailLimit = getEmailLimit(selectedTeam?.plan);

  const updatedEditors = useMemo(() => [...emails, ...editors], [emails]);

  const editorSeats = getEditorSeats(selectedTeam?.plan);
  const dispatch = useDispatch();
  const seatCountRoles = ["admin", "contributer"];
  const availableSeats = editorSeats - editors?.length;

  useEffect(() => {
    const handleScroll = () => {
      const scrolledHeight = window.scrollY;
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      setIsEndOfScreen(scrolledHeight >= totalHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!selectedTeam?.teamId) return;

    if (!isAdmin) {
      navigate("/");
    }
  }, [selectedTeam, isAdmin]);

  useEffect(() => {
    if (updatedEditors?.length >= emailLimit) {
      showEmailLimitNotification();
    }
  }, [emails]);

  const fetchMembers = async () => {
    // const teamModerationData = await teamModerationMember(teamId);
    // const pendingInvitations = await pendingMember(teamId);
    // const allMembers = new Set([]);
    // function addMembersByRole(mems = [], role) {
    //   mems.forEach(async function (m) {
    //     allMembers.add({
    //       id: m.userId || m.id,
    //       name: m.name,
    //       email: m.email,
    //       permission: role,
    //       avatar: m.profileImage,
    //       status: "success",
    //     });
    //   });
    // }
    // addMembersByRole(teamModerationData.admin, "admin");
    // if (teamModerationData?.contributers?.length) {
    //   addMembersByRole(teamModerationData.contributers, "contributer");
    // }
    // if (teamModerationData?.viewers?.length) {
    //   addMembersByRole(teamModerationData.viewers, "viewer");
    // }
    // pendingInvitations.forEach(function (inviteRef) {
    //   const invite = inviteRef.data();
    //   allMembers.add({
    //     id: inviteRef.id,
    //     email: invite.recipientEmail,
    //     permission: invite.role,
    //     status: "pending",
    //   });
    // });
    // setMembers(Array.from(allMembers));
    // setShowLoader(false);
    // setAdmins(teamModerationData.admin.length);
  };

  useEffect(() => {
    if (!teamId) return;

    const teamSubscribe = onSnapshot(
      doc(firestoreDb, "TeamsModerations", teamId),
      (snapDoc) => {
        const allMembers = new Set([]);
        const teamModerationData = snapDoc.data();
        function addMembersByRole(mems = [], role) {
          mems.forEach(async function (m) {
            allMembers.add({
              id: m.userId || m.id,
              name: m.name,
              email: m.email,
              permission: role,
              avatar: m.profileImage,
              status: "success",
            });
          });
        }

        addMembersByRole(teamModerationData.admin, "admin");
        if (teamModerationData?.contributers?.length) {
          addMembersByRole(teamModerationData.contributers, "contributer");
        }
        if (teamModerationData?.viewers?.length) {
          addMembersByRole(teamModerationData.viewers, "viewer");
        }
        setCurrentMembers(Array.from(allMembers));
        setAdmins(teamModerationData.admin.length);
      }
    );

    return () => {
      teamSubscribe();
    };
  }, [teamId]);

  useEffect(() => {
    if (!teamId) return;

    const pendingSubscribe = onSnapshot(
      query(
        collection(firestoreDb, "Invitations"),
        where("teamId", "==", teamId),
        where("status", "==", "Sent"),
        where(
          "createdAt",
          ">=",
          moment(moment.now()).subtract(24, "hours").toDate()
        )
      ),
      (snapCollection) => {
        const allMembers = new Set([]);
        snapCollection.forEach((inviteRef) => {
          const invite = inviteRef.data();
          allMembers.add({
            id: inviteRef.id,
            email: invite.recipientEmail,
            permission: invite.role,
            status: "pending",
          });
        });
        const updateInvite = Array.from(allMembers)?.filter(
          (i) => i?.permission !== "viewer"
        );
        const currentEditors = [
          ...selectedTeam?.admin,
          ...selectedTeam?.contributers,
        ];

        if (
          [...updateInvite, ...currentEditors]?.length <=
          getEditorSeats(selectedTeam?.plan)
        ) {
          setPendingMembers(Array.from(allMembers));
          return;
        }

        const updatedEditors = adjustPendingMembers(
          currentEditors,
          Array.from(allMembers),
          getEditorSeats(selectedTeam?.plan)
        );
        setPendingMembers(updatedEditors);

        const remainingMembers = removeCommonElements(
          Array.from(allMembers),
          updatedEditors
        );

        if (remainingMembers) {
          remainingMembers?.map(async (m) => {
            await setDoc(
              doc(firestoreDb, "Invitations", m.id),
              {
                status: "notvalid",
              },
              { merge: true }
            );
          });
        }
      }
    );

    return () => {
      pendingSubscribe();
    };
  }, [teamId]);

  useEffect(() => {
    if (typeof pendingMembers !== "undefined") {
      setMembers([...currentMembers, ...pendingMembers]);
    }
  }, [currentMembers, pendingMembers]);

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const successMembers = members.filter(
        (member) => member.status === "success"
      );
      if (successMembers.length === selectedMembers.length) {
        setSelectedMembers([]);
      } else {
        setSelectedMembers(successMembers.map((member) => member.id));
      }
    } else {
      setSelectedMembers([]);
    }
  };

  const handleMemberCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedMembers(function (prevSelectedMembers) {
        return [...prevSelectedMembers, id];
      });
    } else {
      setSelectedMembers(function (prevSelectedMembers) {
        return prevSelectedMembers.filter(function (x) {
          return x !== id;
        });
      });
    }
  };

  function handleCopyEmails() {
    const selectedEmails = members
      .filter((member) => selectedMembers.includes(member.id))
      .map((member) => member?.email)
      .join(", ");
    navigator.clipboard.writeText(selectedEmails);
    setCopid(true);
  }

  const handleOpenDeleteModal = () => {
    setNoDelete(false);
    closeModal();
  };

  const handleDeleteMembers = async () => {
    setShowLoader(true);
    setSelectedMembers([]);

    if (!isAdmin) {
      return;
    }

    if (selectedMembers.includes(currentUser?.uid)) {
      setNoDelete(true);
      setShowLoader(false);
      setOpenDeleteModal(false);

      return;
    }

    await deleteSelectedMembers(teamId, selectedMembers);
    trackEvent("Team Members - Remove");

    setShowLoader(false);
    setOpenDeleteModal(true);
    fetchMembers();
  };

  const handleDelete = async (member) => {
    setSelectedMembers([]);

    if (!isAdmin) {
      return;
    }

    if (
      (member.id === currentUser?.uid || admins === 1) &&
      member.permission === "admin"
    ) {
      setShowModal(false);
      setNoDelete(true);
      return;
    }

    setComponentLoader(true);
    await deleteMember(member?.id, teamId, member?.permission);
    trackEvent("Team Members - Remove");
    setComponentLoader(false);
    setShowLoader(false);

    setShowModal(false);
    fetchMembers();
    navigate(`/teams/${teamId}/members`);
  };

  const handleOpenUpdateModal = () => {
    setNoRoleChange(false);
  };

  const handlePendingUserRole = async (member, event) => {
    const newRole = event.target.value;
    await updatePendingUserRole(member?.id, newRole);
    trackEvent("Team Members - Change Role", {
      old: member?.permission,
      new: newRole,
    });
    fetchMembers();
  };

  const handleUpdateRole = async (member, event) => {
    setSelectedMembers([]);
    if (member.id === currentUser?.uid && admins === 1) {
      setNoRoleChange(true);
      return;
    }
    const newRole = event.target.value;
    const res = await updateRole([member?.id], newRole, teamId);
    if (res === "success") {
      trackEvent("Team Members - Change Role", {
        old: member?.permission,
        new: newRole,
      });
      setTimeout(() => {
        fetchMembers();
      }, 2000);
    }
  };

  const handleUpdateRoles = async (member, event) => {
    const newRole = event.target.value;
    const uncheckedMembers = members?.filter(
      (mItem) => !selectedMembers.includes(mItem?.id)
    );
    const uncheckedAdminMembers = uncheckedMembers?.filter(
      (mItem) => mItem?.permission === "admin"
    )?.length;
    const checkedMembers = members?.filter((mItem) =>
      selectedMembers?.includes(mItem?.id)
    );

    if (newRole !== "admin" && uncheckedAdminMembers === 0) {
      setNoRoleChange(true);
      return;
    }

    const updatingRoleUsers = checkedMembers?.filter(
      (mItem) => mItem?.permission !== newRole
    );
    const updatingRoleUserIds = updatingRoleUsers?.map((mItem) => mItem?.id);

    if (!updatingRoleUserIds?.length) {
      console.log("No user to update role");
      return;
    }

    if (newRole !== "viewer") {
      const viewerMembers = updatingRoleUsers?.filter(
        (mItem) => mItem?.permission === "viewer"
      );
      if (viewerMembers?.length > availableSeats) {
        showEmailSeatNotification();
        return;
      }
    }

    const responses = updatingRoleUserIds?.map(async (memberId) => {
      await updateRole([memberId], newRole, teamId);
      trackEvent("Team Members - Change Role", {
        old: members?.find((item) => item?.id === memberId)?.permission,
        new: newRole,
      });
    });
    const allResponses = await Promise.all(responses);
    // const response = await updateRole(updatingRoleUserIds, newRole, teamId);
    if (allResponses) {
      setTimeout(() => {
        fetchMembers();
        setSelectedMembers([]);
      }, 2000);
    }
  };

  const handleRemovePendingUser = async (member) => {
    await removePendingUser(member?.id);
    closeModal();
  };

  const handleDotMenuClick = (memberId) => {
    setIsDotClicked(true);
    setClickedMemberId((prevMemberId) => {
      if (prevMemberId === memberId) {
        return null;
      }
      return memberId;
    });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const closeOpenIviteScreen = () => {
    setOpenInviteScreen(false);
  };
  const getTextColor = (member) => {
    if (member.status === "success") {
      return Colors.blackFaded80;
    }
    return Colors.blackFaded54;
  };

  const userRemoveName = () => {
    if (selectedMember?.status === "success") {
      return selectedMember?.name;
    } else {
      return selectedMember?.email;
    }
  };

  const showEmailLimitNotification = () => {
    dispatch(
      toggleNotification({
        open: true,
        content: `${capitalize(selectedTeam?.plan)} Access only allows ${
          emailLimit - 1
        } editors`,
        type: "warning",
        action: () => {},
        persist: false,
      })
    );
  };

  const showEmailSeatNotification = () => {
    dispatch(
      toggleNotification({
        open: true,
        content: "Editors seat limit reached!",
        type: "warning",
        action: () => {
          if (isAdmin) {
            dispatch(
              toggleNotification({
                open: false,
              })
            );
            navigate(`/teams/${teamId}/subscriptions`);
          }
        },
        btnText: isAdmin ? "Upgrade Plan" : "",
        persist: false,
      })
    );
  };

  const userInfo = (member) => {
    if (member.status === "success") {
      return (
        <>
          <UserInfo
            member={member}
            showMemberCheckboxes={showMemberCheckboxes}
            selectedMembers={selectedMembers}
            handleMemberCheckboxChange={handleMemberCheckboxChange}
          />
          <MobileUserInfo member={member} teamId={teamId} />
        </>
      );
    }
    return (
      <>
        <PendingUserInfo member={member} />
        <MobilePendingUserInfo member={member} teamId={selectedTeam?.teamId} />
      </>
    );
  };

  return (
    <div>
      <AppDrawer
        appDrawerProps={{
          open: openInviteScreen,
          anchor: "right",
          closeModal: closeOpenIviteScreen,
          drawerWidth: "100%",
          customZIndex: true,
        }}
        style={{ height: "100vh" }}
      >
        <Stack bgcolor={Colors.white} sx={{ flex: 1, pb: "13vh" }}>
          <Stack sx={styles.arrowBack} className="custom-shadow">
            <IconButton onClick={closeOpenIviteScreen}>
              <ArrowLeftSvg stroke={Colors.black} />
            </IconButton>
          </Stack>
          <Stack gap="5px" sx={styles.heading}>
            <MedText
              variant="body1"
              sx={{ color: Colors.black, textAlign: "center" }}
            >
              Invite Collaborators
            </MedText>
            <RegText
              variant="body2"
              sx={styles.subHeading}
              className="!text-black/70"
            >
              You can update user permissions on the team page after setting up.
            </RegText>
          </Stack>
          <Stack sx={{ px: "20px", flex: 1 }}>
            {members && (
              <InviteCollaborators
                emails={emails}
                setEmails={setEmails}
                setMembers={setMembers}
                members={members}
                setOpenInviteModal={setOpenInviteScreen}
                isButtonDisabled={updatedEditors?.length > emailLimit - 1}
                fetchMembers={fetchMembers}
                currentMembers={currentMembers}
              />
            )}
          </Stack>
        </Stack>
      </AppDrawer>
      <Stack px={"20px"}>
        <SettingNav />
        {currentMembers && typeof pendingMembers !== "undefined" && (
          <div className="hidden md:block pt-4 text-black pb-[100px]">
            {showLoader && (
              <div className="flex justify-center items-center h-[40vh]">
                <CircularProgress sx={{ color: Colors.white }} size={30} />
              </div>
            )}
            {!showLoader && (
              <table className="w-full">
                <thead>
                  <tr className="border-b border-b-black/20 text-left font-medium text-sm">
                    <th className="flex items-center ml-0.5">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedMembers.length ===
                              members.filter((m) => m.status === "success")
                                .length
                            }
                            style={{
                              color: Colors.black,
                            }}
                            onChange={handleHeaderCheckboxChange}
                          />
                        }
                        label=""
                      />
                      <MedText variant="body2" ml={-2}>
                        Name
                      </MedText>
                    </th>
                    <th>
                      <MedText variant="body2">Email ID</MedText>
                    </th>
                    <th>
                      <MedText variant="body2">Permissions</MedText>
                    </th>
                    <th>
                      <MedText variant="body2">Actions</MedText>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {members?.map((member, i) => (
                    <tr
                      className="border-b border-b-black/20 text-sm text-left"
                      key={member.id}
                    >
                      <td className="py-3 flex mr-30">{userInfo(member)}</td>
                      <td>
                        <RegText
                          variant="body2"
                          sx={{ color: getTextColor(member) }}
                        >
                          <span className="hidden 2xl:block">
                            {member?.email}
                          </span>
                          <span className="hidden md:block 2xl:hidden">
                            {checkStringLength(member?.email, 36)}
                          </span>
                        </RegText>
                      </td>
                      <td>
                        <Select
                          value={member.permission}
                          onChange={(event) => {
                            const oldRole = member.permission;
                            if (
                              seatCountRoles.includes(event?.target?.value) &&
                              !seatCountRoles.includes(oldRole) &&
                              editors.length >= editorSeats
                            ) {
                              showEmailSeatNotification();
                              return;
                            }

                            if (member.status === "pending") {
                              handlePendingUserRole(member, event);
                              return;
                            }
                            handleUpdateRole(member, event);
                          }}
                          customStyle={{ color: Colors.blackFaded80 }}
                        />
                      </td>
                      <td onMouseLeave={() => setIsDotClicked(false)}>
                        <div className="cursor-pointer">
                          <button
                            onClick={() => handleDotMenuClick(member.id)}
                            className="cursor-pointer py-2 outline-none"
                          >
                            <DotMenuSvg stroke="black" />
                          </button>
                        </div>

                        {clickedMemberId === member.id && isDotClicked && (
                          <div
                            className="bg-grey6 text-white py-2 px-4 absolute rounded-lg cursor-pointer"
                            onClick={() => {
                              if (member.status === "pending") {
                                handleRemovePendingUser(member);
                                return;
                              }
                              openModal();
                            }}
                            onMouseLeave={() => setIsDotClicked(false)}
                          >
                            <p className="outline-none whitespace-nowrap">
                              {member.status === "pending"
                                ? "Cancel Invite"
                                : "Remove"}
                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                  <CustomModal
                    heading={`Remove ${userRemoveName()}?`}
                    subHeading={`Are you sure you want to remove ${userRemoveName()}?`}
                    open={showModal}
                  >
                    <div className="flex flex-col gap-4">
                      <CustomBtn
                        onClick={() => handleDelete(selectedMember)}
                        disabled={componentLoader}
                      >
                        {componentLoader ? (
                          <CircularProgress
                            sx={{ color: Colors.white }}
                            size={25}
                          />
                        ) : (
                          `Remove`
                        )}
                      </CustomBtn>
                      <CustomBtn type="secondary" onClick={closeModal}>
                        Cancel
                      </CustomBtn>
                    </div>
                  </CustomModal>
                </tbody>
              </table>
            )}

            {showMemberCheckboxes && (
              <div className="fixed left-0 right-0 bottom-8 z-[99]">
                <PopUp
                  handleUpdateRoles={handleUpdateRoles}
                  selectedMembers={selectedMembers}
                  handleCopyEmails={handleCopyEmails}
                  setOpenDeleteModal={setOpenDeleteModal}
                  setSelectedMembers={setSelectedMembers}
                  openDeleteModal={openDeleteModal}
                  handleDeleteMembers={handleDeleteMembers}
                  copied={copied}
                  setCopid={setCopid}
                />
              </div>
            )}

            <CommonModal
              heading={"You can't delete yourself"}
              subHeading={"Admin can't delete themselves."}
              open={noDelete}
            >
              <CustomBtn type="secondary" onClick={handleOpenDeleteModal}>
                Close
              </CustomBtn>
            </CommonModal>

            <CommonModal
              heading={"You can't change the role"}
              subHeading={
                "You are the only admin. Assign another admin before changing your role."
              }
              open={noRoleChange}
            >
              <CustomBtn type="secondary" onClick={handleOpenUpdateModal}>
                Close
              </CustomBtn>
            </CommonModal>
          </div>
        )}
      </Stack>
      <MemberPhone
        showLoader={showLoader}
        members={members}
        userInfo={userInfo}
      />
      {currentMembers && typeof pendingMembers !== "undefined" && (
        <MemberDetails
          members={members}
          isEndOfScreen={isEndOfScreen}
          setMembers={setMembers}
          editors={editors}
          setOpenInviteScreen={setOpenInviteScreen}
          fetchMembers={fetchMembers}
          pendingViewers={pendingMembers.filter(
            (p) => p.permission === "viewer"
          )}
          currentMembers={currentMembers}
        />
      )}
    </div>
  );
};

export default ShowMembersScreen;
