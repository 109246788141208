import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../../themes";
import { useState, useRef, useEffect } from "react";
import Input from "../Input";
import {
  CircularProgress,
  Divider,
  Stack,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import AppDrawer from "../AppDrawer";
import {
  AddChatSvg,
  DotMenuSvg,
  EditTwoSvg,
  DeleteSvg,
  PointsSvg,
} from "../../../assets";
import CustomBtn from "../CustomBtn";
import CommonModal from "../CommonModal";
import { MedText, RegText } from "../../../styles";
import { Services } from "../../../services";
import { fetchProjectsData } from "../../../services/project";
import {
  clearSearchQuery,
  setProjectsData,
  setSelectedProjectId,
} from "../../../redux/userSlice";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  checkStringLength,
  getUserRole,
  trackEvent,
} from "../../../utils/functions";
import { auth } from "../../../libraries/firebase";
import { motion } from "framer-motion";
import { toggleLeftMenu } from "../../../redux/appSlice";

const Contributers = ({ children }) => {
  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);
  const [openProjectRenameModal, setOpenProjectRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [newProjectName, setNewProjectName] = useState("");
  const [renameProject, setRenameProject] = useState("");
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const { selectedTeam, projectsData } = useSelector((state) => state.user);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isLgScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const { notification } = useSelector((state) => state.app);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedProjectId } = useSelector((state) => state.user);
  const [hoveringProject, setHoveringProject] = useState("");
  const [hoverProjectId, setHoverProjectId] = useState("");
  const [newProjectNameError, setNewProjectNameError] = useState("");
  const [reNameError, setRenameError] = useState("");
  const [deleteProjectError, setDeleteProjectError] = useState("");
  const [user] = useAuthState(auth);
  const userRole = getUserRole(user?.uid, selectedTeam);

  const dispatch = useDispatch();
  let padding = 5;
  let topPosition = "84px";
  if (notification) {
    topPosition = "132px";
  }
  if (children) {
    padding = 0;
  }
  useEffect(() => {
    const checkProjectNameLength = (projectName, setError) => {
      if (projectName?.length >= 25) {
        setError("Project name should be less than 25 characters.");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    };

    checkProjectNameLength(newProjectName, setNewProjectNameError);
    checkProjectNameLength(renameProject, setRenameError);
  }, [newProjectName, renameProject]);
  const handleOpenMenu = (itemId) => {
    setOpenMenu((prevItemId) => {
      if (prevItemId === itemId) {
        return null;
      }
      return itemId;
    });
  };

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!selectedTeam?.teamId) return;
    const fetchData = async () => {
      try {
        await fetchProjectsData(selectedTeam?.teamId, (projects) => {
          dispatch(setProjectsData(projects));
        });
      } catch (error) {
        console.error(error);
      }
    };
    const unsubscribe = fetchData();
    return () => unsubscribe;
  }, [selectedTeam?.teamId]);

  const createNewProject = async () => {
    if (!newProjectName.trim()) {
      setIsLoading(false);
      setSelectedProject(selectedProjectId);
      setNewProjectNameError("Project name cannot be empty");
      setIsLoading(false);
      return;
    }
    if (newProjectName.trim().length >= 25) {
      setNewProjectNameError(
        "Project name cannot be greater than 25 characters"
      );
      setIsLoading(false);
      return;
    }
    if (projectsData?.find((item) => item?.name === newProjectName?.trim())) {
      setNewProjectNameError("Project name already exists.");
      setIsLoading(false);
      setTimeout(() => {
        setNewProjectNameError("");
      }, 2000);
      return;
    }
    setNewProjectNameError("");
    try {
      setIsLoading(true);
      const { id } = await Services.ProjectServices.createProject(
        selectedTeam?.teamId,
        newProjectName?.trim()
      );
      trackEvent("Workspace - New Project", {
        projectName: newProjectName,
      });
      setOpenCreateProjectModal(false);
      setNewProjectName("");
    } catch (error) {
      setIsLoading(false);
      setSelectedProject(selectedProjectId);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProject = async () => {
    if (!renameProject.trim()) {
      setRenameError("Project name cannot be empty");
      return;
    }
    if (renameProject.trim().length >= 25) {
      setRenameError("Project name should be less than 25 characters.");
      return;
    }
    if (projectsData?.find((item) => item?.name === renameProject?.trim())) {
      setRenameError("Project name already exists.");
      setTimeout(() => {
        setRenameError("");
      }, 2000);
      return;
    }
    try {
      setIsLoading(true);
      await Services.ProjectServices.updateProjectName(
        selectedTeam?.teamId,
        renameProject?.trim(),
        hoveringProject
      );
      trackEvent("Workspace - Project - Rename", {
        projectName: renameProject,
      });
      setHoveringProject("");
      setOpenProjectRenameModal(false);
    } catch (error) {
      setHoveringProject("");
      console.log(error);
    } finally {
      setHoveringProject("");
      setIsLoading(false);
    }
  };

  const handleDeleteProject = async (selectedProject) => {
    if (selectedProject?.name !== deleteProjectName) {
      setDeleteProjectError("Project name does't match");
      return;
    }
    setDeleteProjectError("");

    try {
      setIsLoading(true);
      const response = await Services.ProjectServices.deleteProject(
        selectedTeam?.teamId,
        hoveringProject
      );
      if (response === "success") {
        trackEvent("Workspace - Project - Delete", {
          projectName: deleteProjectName,
        });
        setDeleteProjectName("");
        setShowDeleteModal(false);
        setShowDeleteConfirmationModal(true);
        setHoveringProject("");
      }
    } catch (error) {
      console.log(error);
      setHoveringProject("");
    } finally {
      setHoveringProject("");
      setIsLoading(false);
    }
  };

  const setSelectedProject = (projectId) => {
    dispatch(setSelectedProjectId(projectId));
  };
  const selectedStyle = (project) =>
    selectedProjectId === project.id ? "bg-newPrimary" : "";

  const renderModal = ({ heading, open, setOpen, children, onSubmit }) => {
    const closeAppDrawer = () => {
      setOpen(false);
      setNewProjectName("");
      setNewProjectNameError("");
    };
    const btnText = heading === "Create a new project" ? "Create" : "Save";
    const isDisabled =
      heading === "Create a new project"
        ? !newProjectName?.length
        : !renameProject?.length;

    if (isSmallScreen) {
      return (
        <>
          <AppDrawer
            type="swipeable"
            style={{ backgroundColor: "transparent" }}
            appDrawerProps={{
              open,
              anchor: "bottom",
              drawerWidth: "100%",
              closeModal: closeAppDrawer,
            }}
          >
            <Stack
              sx={{
                p: "24px 16px 32px 16px",
                backgroundColor: Colors.white,
                mt: 1,
              }}
              gap="70px"
            >
              {children}

              <CustomBtn
                type="primary"
                onClick={onSubmit}
                disabled={isLoading || isDisabled}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: Colors.white }} size={30} />
                ) : (
                  btnText
                )}
              </CustomBtn>
            </Stack>
          </AppDrawer>
        </>
      );
    }

    return (
      <CommonModal heading={heading} open={open} setOpen={setOpen}>
        {children}
        <Stack gap="24px" mt="40px">
          <CustomBtn
            type="primary"
            onClick={onSubmit}
            disabled={isLoading || isDisabled}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              btnText
            )}
          </CustomBtn>
          <CustomBtn type="secondary" onClick={closeAppDrawer}>
            Cancel
          </CustomBtn>
        </Stack>
      </CommonModal>
    );
  };

  const renderCreateProjectModal = () => {
    let details = {
      backgroundColor: "",
      placeholder: "Enter project name",
    };

    if (isSmallScreen) {
      details = { backgroundColor: "transparent", placeholder: "Project name" };
    }

    return renderModal({
      heading: "Create a new project",
      open: openCreateProjectModal,
      setOpen: setOpenCreateProjectModal,
      children: (
        <Stack>
          <Input
            value={newProjectName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.trim().length < 25) {
                setNewProjectNameError("");
              }
              if (inputValue.length < newProjectName.length) {
                setNewProjectName(inputValue);
                return;
              }
              if (inputValue.length <= 25) {
                setNewProjectName(inputValue);
              }
            }}
            style={{
              backgroundColor: details.backgroundColor,
              width: "100%",
            }}
            placeholder={details.placeholder}
            label={"Project name"}
          />

          <p className="!text-red mt-4 text-sm h-4">{newProjectNameError}</p>
        </Stack>
      ),
      onSubmit: () => {
        setSelectedProject();
        setIsLoading(true);
        setTimeout(() => {
          createNewProject();
        }, 500);
      },
    });
  };

  const renderProjectRenameModal = () => {
    return renderModal({
      heading: "Rename project",
      open: openProjectRenameModal,
      setOpen: setOpenProjectRenameModal,
      children: (
        <Stack>
          <Input
            value={renameProject}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length < renameProject.length) {
                setRenameProject(inputValue);
                return;
              }
              if (inputValue.length <= 25) {
                setRenameProject(inputValue);
              }
            }}
            style={{ backgroundColor: "transparent", width: "100%" }}
            placeholder="Enter project name"
            label={"Project name"}
          />
          <p className="!text-red mt-4 text-sm h-4">{reNameError}</p>
        </Stack>
      ),
      onSubmit: updateProject,
    });
  };
  const renderCreateProjectBtn = () => {
    if (!userRole) return <></>;
    if (userRole === "contributer" || userRole === "admin") {
      return (
        <button
          onClick={() => setOpenCreateProjectModal(true)}
          className="border-newPrimary border-[1px] py-3 px-2  rounded-lg text-newPrimary text-sm flex gap-2 items-center w-full md:w-full bg-white sticky top-0 mb-2"
        >
          <AddChatSvg stroke={Colors.newPrimary} height={12} />
          <MedText variant="body2">New Project</MedText>
        </button>
      );
    }
  };

  const renderProjects = (project, i) => {
    const selectedProject = projectsData.find(
      (item) => item.id === hoveringProject
    );
    const isSelectedProject = project.id === selectedProjectId;
    const commonClassNameForDotMenu = `!absolute !duration-1000 right-0  px-4 top-1/2 h-4    flex items-center justify-center -translate-y-1/2 ${
      selectedProjectId === project?.id ? "!bg-newPrimary " : " "
    }`;
    const renderDeleteModal = () => (
      <CommonModal
        heading="Delete project?"
        subHeading="All of your files and data will be lost. You can’t undo this action. Type in the project name to continue."
        open={showDeleteModal}
      >
        <div className="mb-4">
          <Input
            onChange={(e) => {
              setDeleteProjectName(e.target.value);
            }}
            placeholder="Enter project name"
            label="Project name"
          />
          <p className="!text-red mt-4 text-sm h-4">{deleteProjectError}</p>
        </div>
        <Stack gap="16px" mt="40px">
          <div className="flex flex-col gap-4">
            <CustomBtn
              type="error"
              onClick={() => handleDeleteProject(selectedProject)}
              disabled={isLoading || !deleteProjectName?.trim()}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: Colors.white }} size={30} />
              ) : (
                "Delete"
              )}
            </CustomBtn>
            <CustomBtn
              type="secondary"
              onClick={() => {
                setHoveringProject("");
                setShowDeleteModal(false);
                setDeleteProjectName("");
                setDeleteProjectError("");
              }}
            >
              Cancel
            </CustomBtn>
          </div>
        </Stack>
      </CommonModal>
    );

    return (
      <motion.div
        key={project.id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.01 * i } }}
        className="relative"
      >
        <button
          key={project.id}
          onMouseEnter={() => {
            setRenameProject(project?.name);
            setHoveringProject(project.id);
            setHoverProjectId(project?.id);
          }}
          onMouseLeave={() => setHoverProjectId("")}
          onClick={() => {
            trackEvent("Workspace - Select Project", {
              teamID: selectedTeam?.teamId,
              projectID: project?.id,
            });
            dispatch(clearSearchQuery());
            setSelectedProject(project.id);
            dispatch(toggleLeftMenu(false));
          }}
          className={`flex justify-between items-center relative w-full  overflow-hidden md:w-full  mt-3 py-3 px-2 ${
            !isSelectedProject && "hover:bg-newPrimary/20"
          } rounded-lg duration-300 ${selectedStyle(project)} text-black`}
        >
          <div
            className={`flex gap-2 items-center ${
              isSelectedProject ? "text-white" : "text-black"
            }`}
          >
            <PointsSvg fill={"currentColor"} />

            {isLgScreen
              ? checkStringLength(project?.name, 25)
              : checkStringLength(project?.name, 17)}
          </div>

          {!isSmallScreen &&
            hoverProjectId === project?.id &&
            userRole !== "viewer" && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenMenu(project.id);
                }}
                className={commonClassNameForDotMenu}
              >
                <DotMenuSvg stroke={"white"} />
              </button>
            )}
          {isSmallScreen && userRole !== "viewer" && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleOpenMenu(project.id);
              }}
              className={commonClassNameForDotMenu}
            >
              <div
                className={
                  project.id === selectedProjectId
                    ? "text-white"
                    : "text-newPrimary/30"
                }
              >
                <DotMenuSvg stroke={"currentColor"} />
              </div>
            </button>
          )}
        </button>
        {project.id === openMenu && (
          <div
            className="rounded-lg  absolute  bg-white  shadow-md right-0 z-20  w-[100%] "
            ref={menuRef}
          >
            <button
              className="flex gap-3 rounded-t-lg items-center hover:bg-gray-100 py-3 border-b px-2 border-b-black/20 font-normal w-full"
              onClick={() => setOpenProjectRenameModal(true)}
            >
              <EditTwoSvg fill="black" />
              <RegText variant="body2" className="!text-black">
                Rename
              </RegText>
            </button>
            <button
              className="flex gap-3 rounded-b-lg items-center py-3 px-2 hover:bg-gray-100 font-normal  w-full "
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteModalId(project.id);
              }}
            >
              <DeleteSvg fill={Colors.errorColor} />
              <RegText variant="body2" sx={{ color: Colors.errorColor }}>
                Delete
              </RegText>
            </button>
          </div>
        )}

        {showDeleteModal && deleteModalId === project.id && renderDeleteModal()}
      </motion.div>
    );
  };
  const renderDeleteConfirmationModal = () => (
    <CommonModal
      heading="Project deleted"
      subHeading="You & your team members have no access to that project"
      open={showDeleteConfirmationModal}
    >
      <CustomBtn
        type="secondary"
        onClick={() => setShowDeleteConfirmationModal(false)}
      >
        Close
      </CustomBtn>
    </CommonModal>
  );
  return (
    <>
      {showDeleteConfirmationModal && renderDeleteConfirmationModal()}
      {renderCreateProjectModal()}
      {renderProjectRenameModal()}
      <div
        className={`p-${padding}   md:overflow-auto text-white  w-[35vw]  md:w-[20.5%] min-h-full md:max-h-[90vh] md:fixed md:top-[${topPosition}] bg-newPrimaryFaded px-3 md:px-5 md:pt-5 z-50 flex flex-col flex-1 `}
      >
        {children ?? children}
        {renderCreateProjectBtn()}
        <div className="flex-1 flex flex-col md:w-full overflow-auto pb-[50%] md:pb-[80%]">
          {projectsData.length > 0 &&
            projectsData
              ?.map((project, i) => renderProjects(project, i))
              .reverse()}
        </div>
      </div>
    </>
  );
};

export default Contributers;
