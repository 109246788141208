import { Colors } from "../../../themes";

export const styles = {
  dividerScreen: {
    bgcolor: Colors.whiteFaded20,
    height: "96%",
    mt: "20px",
  },
  newWorkSpace: {
    display: { xs: "none", md: "flex" },
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  projectsBox: {
    width: "21%",
    display: { xs: "none", md: "flex" },
  },

  projectsStack: {
    width: { xs: "100%", md: "58%" },
    p: { xs: "20px", md: "34px 24px" },
  },
  projectTitle: {
    color: Colors.black,
  },
  item: {
    height: "140px",
    borderRadius: "8px",
    backgroundColor: Colors.whiteFaded05,
    padding: "4px 8px",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  stack: {
    gap: 1.5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
  },
  addChatSvg: {
    height: "11px",
    width: "11px",
  },
  text: {
    color: Colors.white,
  },
};
