import { ArrowDownSvg } from "../../../assets";
import { Colors } from "../../../themes";

const Select = ({ value, customStyle, onChange }) => {
  return (
    <div className="flex items-center w-[110px]">
      <select
        value={value}
        style={{ ...styles.select, ...customStyle }}
        onChange={onChange}
        className="flex-1"
      >
        <option value="admin" className="bg-grey2">
          Admin
        </option>
        <option value="viewer" className="bg-grey2">
          Viewer
        </option>
        <option value="contributer" className="bg-grey2">
          Editor
        </option>
      </select>
      <ArrowDownSvg className="ml-[-20px]"/>
    </div>
  );
};

export default Select;
const styles = {
  select: {
    fontFamily: `"Poppins", sans-serif`,
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: "transparent",
    color: Colors.white,
    outline: "none",
    zIndex: 10,
    cursor: "pointer",
  },
};
