import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stack, Chip, Backdrop, styled, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { Services } from "../../../services";
import Bubble from "../../common/Bubble";
import "./styles.css";

import LinkCard from "../../common/LinkCard";
import { onValue, realDb, realTimeRef } from "../../../libraries/firebase";
import PhoneNav from "../../common/PhoneNav";
import AppDrawer from "../../common/AppDrawer";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { getSharedRoomId } from "../../../services/room";
import SharedSidebar from "../../common/SharedSidebar";
import SharedBubble from "../../common/SharedBubble";

const SharedRoom = () => {
  const { id } = useParams();

  const [sharedRoomId, setSharedRoomId] = useState("");
  const [collaborators, setCollaborators] = useState({});
  const [messages, setMessages] = useState([]);
  const [threads, setThreads] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState("");
  const [conversation, setConversation] = useState([]);
  const [history, setHistory] = useState([]);
  const [creatingThread, setCreatingThread] = useState(false);
  const [toggleNewThread, setToggleNewThread] = useState(false);
  const [appDrawerProps, setAppDrawerProps] = useState({
    open: false,
    anchor: "left",
  });
  const chatRef = useRef(null);
  const conversationRef = useRef(null);

  const handleNewWorkspace = async () => {
    window.open(process.env.REACT_APP_WEBSITE_URL, "_blank");
  };

  const handleNewThread = useCallback(() => {
    setCurrentThreadId("");
    setConversation([]);
    setHistory([]);
  }, []);

  const handleChangeThread = (changedThreadId) => {
    setCurrentThreadId(changedThreadId);
  };

  const getChatMessages = async () => {
    const response = await Services.ChatServices.fetchChatMessages(
      id,
      setMessages
    );

    if (response?.status === "failed") {
      alert("Something went wrong!");
    }
  };

  const getCollaborators = async () => {
    const response = await Services.ChatServices.fetchCollaborators(
      id,
      setCollaborators,
      "share"
    );

    if (response?.status === "failed") {
      alert("Something went wrong!");
    }
  };

  const getThreads = useCallback(async () => {
    const response = await Services.ThreadServices.fetchThreads(
      id,
      setThreads,
      "share"
    );

    if (response?.status === "failed") {
      alert("Something went wrong!");
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const loadRoom = async () =>
      await Promise.all([getChatMessages(), getCollaborators(), getThreads()]);
    loadRoom();
  }, [id]);

  useEffect(() => {
    if (!id || currentThreadId === "") {
      return;
    }

    const docRef = realTimeRef(
      realDb,
      `share/${id}/threads/${currentThreadId}/thread`
    );

    const unsubscribe = onValue(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let conversationList = [];

        Object.keys(data).forEach((key) => {
          conversationList.push({
            id: key,
            question: data[key]?.["request"],
            answer: data[key]?.["response"],
            requesterId: data[key]?.["requesterId"],
            parentMessageId: data[key]?.["parentMessageId"],
          });
        });
        setConversation(conversationList);
      } else {
        setConversation([]);
      }
    });

    return () => unsubscribe();
  }, [id, currentThreadId]);

  useEffect(() => {
    if (!id || currentThreadId === "") {
      return;
    }

    const docRef = realTimeRef(
      realDb,
      `share/${id}/threads/${currentThreadId}/parentHistory`
    );

    const unsubscribe = onValue(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let historyList = [];

        Object.keys(data).forEach((key) => {
          historyList.push({
            id: key,
            question: data[key]?.["request"],
            answer: data[key]?.["response"],
            requesterId: data[key]?.["requesterId"],
            parentMessageId: data[key]?.["parentMessageId"],
          });
        });
        setHistory(historyList);
      } else {
        setHistory([]);
      }
    });

    return () => unsubscribe();
  }, [id, currentThreadId]);

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    conversationRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  useEffect(() => {
    if (!id || currentThreadId !== "") {
      return;
    }
    setCurrentThreadId(threads.length - 1 < 0 ? "" : threads[0]?.id);
  }, [threads]);

  useEffect(() => {
    setCurrentThreadId(threads.length - 1 < 0 ? "" : threads[0]?.id);
  }, [toggleNewThread]);

  const loadMessagesComponent = () => {
    if (!conversation[0] && !history[0])
      return (
        <div className="flex flex-1 justify-center items-center mb-[10%]">
          <p className="text-grey text-base text-center font-semibold">
            Start a conversation...
          </p>
        </div>
      );
    return (
      <div className="flex flex-1 flex-col overflow-y-auto pb-[8%] pr-2">
        {[...history, ...conversation].map((item, index) => {
          return (
            <div key={item.id} className="mb-8">
              <SharedBubble
                name={collaborators[item?.requesterId]}
                type="question"
                message={item?.question}
                customStyle="mb-4"
                svgText={collaborators[item?.requesterId]}
                isRequester={index !== 0}
              />
              <Bubble type="answer" message={item?.answer} customStyle="pr-3" />
            </div>
          );
        })}

        <div ref={conversationRef} />
      </div>
    );
  };

  const renderMainChat = () => {
    return (
      <SharedSidebar
        data={threads}
        onPressCreate={handleNewThread}
        onPressWorkspace={handleNewWorkspace}
        onChangeThread={handleChangeThread}
        setCreatingThread={setCreatingThread}
        currentThreadId={currentThreadId}
        creatingThread={creatingThread}
      />
    );
  };
  const renderBothChats = () => {
    if (appDrawerProps.anchor === "left") {
      return renderMainChat();
    } else {
      return (
        <div className="w-full px-4 py-4 bg-grey5">
          {/* <LinkCard /> */}
          <Stack direction="row" gap={1} mt={2}>
            <FacebookShareButton
              url={window.location.href}
              description="Unboard Chat room"
              quote="Unboard Chat room"
            >
              <FacebookIcon size={52} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              url={window.location.href}
              description="Unboard Chat room"
              quote="Unboard Chat room"
            >
              <TwitterIcon size={52} round />
            </TwitterShareButton>
          </Stack>
        </div>
      );
    }
  };
  return (
    <>
      <PhoneNav
        type="cloned"
        appDrawerProps={appDrawerProps}
        setAppDrawerProps={setAppDrawerProps}
      />
      <Stack>
        <AppDrawer
          appDrawerProps={appDrawerProps}
          setAppDrawerProps={setAppDrawerProps}
        >
          {renderBothChats()}
        </AppDrawer>
        <div className="flex flex-1 h-screen bg-primary">
          <div className="w-1/5 fs:hidden fmd:block">{renderMainChat()}</div>
          <Box className="fs:w-[100%] fmd:w-[100%] h-screen fs:pt-14 fmd:p-6 bg-grey4">
            <div className="flex flex-col bg-secondary h-full w-full py-4 fs:px-4 fmd:px-8 fmd:rounded-2xl relative">
              <p className="font-bold text-white fs:hidden fmd:block">
                Unboard
              </p>
              <hr className="fs:hidden fmd:block mt-3 mb-4 bg-grey3 opacity-20"></hr>
              {loadMessagesComponent()}
            </div>
          </Box>
        </div>
      </Stack>
    </>
  );
};

export default SharedRoom;
