import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Stack, Chip, Backdrop, styled, Box } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { AvatarSvg, BackArrowSvg } from "../../../assets";
import { Services } from "../../../services";
import SideBar from "../../common/SideBar";
import Bubble from "../../common/Bubble";
import ChatInput from "../../common/ChatInput";
import { sendMessages } from "../../../services/chat";
import "./styles.css";
import ActiveMemberCard from "../../common/ActiveMembersCard";
import { stringToColour } from "../../../helper/formatting";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../libraries/firebase";

import LinkCard from "../../common/LinkCard";
import { styles } from "./styles";
import CustomModal from "../../common/CustomModal";
import {
  createSubThread,
  onValue,
  realDb,
  realTimeRef,
} from "../../../libraries/firebase";
import PhoneNav from "../../common/PhoneNav";
import AppDrawer from "../../common/AppDrawer";
import Notification from "../../common/Notification";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { getSharedRoomId } from "../../../services/room";
import { getUserRole, trackEvent } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  checkMessageLimitAcrossProjects,
  fetchAllWorkspacesForTeam,
  fetchIndividualTeamWorkspacesMessages,
} from "../../../services/workspaces";
import { setSelectedTeam } from "../../../redux/userSlice";
import { toggleNotification } from "../../../redux/appSlice";
import { updateLastSession } from "../../../services/thread";
import { Colors } from "../../../themes";

const GptTextField = styled(ChatInput)({
  "& .MuiOutlinedInput-root": {
    "& .MuiInputBase-input": {
      color: "white",
      padding: "0 36px 0 6px",
    },
    "& fieldset": {
      borderColor: "#727272",
      color: "white",
      borderRadius: 12,
    },
    "&:hover fieldset": {
      borderColor: "#727272",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#727272",
    },
  },
});

const ChatTextField = styled(ChatInput)({
  "& .MuiOutlinedInput-root": {
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& fieldset": {
      borderColor: "#4B4B4B",
      color: "white",
      borderRadius: 12,
      opacity: 0.2,
    },
    "&:hover fieldset": {
      borderColor: "#D8CECE",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D8CECE",
    },
  },
});

const Room = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sharedRoomId, setSharedRoomId] = useState("");
  const [name, setName] = useState(localStorage.getItem("name") ?? "");
  const [value, setValue] = useState("");
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  // const [collaborators, setCollaborators] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [threads, setThreads] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState("");
  const [conversation, setConversation] = useState([]);
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [creatingThread, setCreatingThread] = useState(false);
  const [toggleNewThread, setToggleNewThread] = useState(false);
  const [appDrawerProps, setAppDrawerProps] = useState({
    open: false,
    anchor: "left",
  });
  const chatRef = useRef(null);
  const conversationRef = useRef(null);
  const [user] = useAuthState(auth);
  const [currentUserId, setCurrentUserId] = useState(user?.uid);
  const {
    selectedTeam,
    totalMessagesForTeam,
    workspaceIdsForTeam,
    selectedProjectId,
  } = useSelector((state) => state.user);

  const [currentTeamSubscription, setCurrentTeamSubscription] = useState(null);
  const [workspaceName, setWorkspaceName] = useState("Unboard");

  // const serviceEndDate = currentTeamSubscription[0]?.current_period_end;
  const messagesLimit = getMessagesLimit();
  const dispatch = useDispatch();
  const isViewer = selectedTeam?.viewers?.some(
    (viewer) => viewer?.userId === user?.uid
  );
  const isAdmin = useMemo(
    () => selectedTeam?.admin?.some((admin) => admin?.userId === user?.uid),
    [selectedTeam]
  );
  useEffect(() => {
    updateLastSession(id);
  }, []);
  function getMessagesLimit() {
    if (selectedTeam?.plan === "free") return 25;
    if (selectedTeam?.plan === "premium") return 5000;
    return 10000;
  }

  let collaborators = [];
  if (
    selectedTeam?.hasOwnProperty("admin") &&
    selectedTeam?.hasOwnProperty("contributers") &&
    selectedTeam?.hasOwnProperty("viewers")
  ) {
    collaborators = [
      ...selectedTeam?.admin,
      ...selectedTeam?.contributers,
      ...selectedTeam?.viewers,
    ];
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (defaultName) => {
    const userName = defaultName ?? name;
    const userId = currentUserId ?? uuid();

    if (userName === "") return;

    const response = await Services.RoomServices.joinAsCollaborator(
      id,
      userId,
      userName
    );

    if (response === "success") {
      setOpen(false);
      setCurrentUserId(userId);
      localStorage.setItem("name", userName);
      localStorage.setItem("userId", userId);
    }
  };

  const handleCreateRoom = useCallback(async () => {
    let roomId = (Math.random() + 1).toString(36).substring(7);
    let shareRoomId = (Math.random() + 1).toString(36).substring(7);
    const response = await Services.RoomServices.createCollabRoom(
      roomId,
      shareRoomId
    );

    if (response === "success") {
      navigate(`/room/${roomId}`);
      trackEvent("Created Room", {
        room_id: roomId,
      });
    }
  }, [navigate]);

  const handleNewWorkspace = async () => {
    trackEvent("Clicked on New Workspace");
    window.open(process.env.REACT_APP_WEBSITE_URL, "_blank");
  };

  const handleCloneWorkspace = async () => {
    trackEvent("Clicked on Clone Workspace", {
      cloned_room_id: id,
    });

    let roomId = (Math.random() + 1).toString(36).substring(7);
    let sharedRoomId = (Math.random() + 1).toString(36).substring(7);

    const response = await Services.RoomServices.createCloneRoom(
      roomId,
      id,
      currentUserId,
      name,
      sharedRoomId
    );

    if (response === "success") {
      window.open(
        `${process.env.REACT_APP_WEBSITE_URL}room/${roomId}`,
        "_blank"
      );
      return;
    }
    alert("Something went wrong! Try again later.");
  };

  const handleCreateThread = async () => {
    if (text.trim() === "") return;

    let newThreadId = currentThreadId;
    // if (newThreadId === "") {
    //   setCreatingThread(true);
    // }
    setCreatingThread(false);

    if (totalMessagesForTeam >= messagesLimit) {
      dispatch(
        toggleNotification({
          open: true,
          content: `Looks like you've hit the limit on your current plan.`,
          type: "warning",
          action: () => {
            if (isAdmin) {
              dispatch(
                toggleNotification({
                  open: false,
                })
              );
              navigate(`/teams/${selectedTeam?.teamId}/subscriptions`);
            }
          },
          btnText: isAdmin ? "Upgrade Plan" : "",
          persist: false,
        })
      );
      return;
    }

    setIsLoading(true);

    try {
      const currentConversationId = threads?.filter(
        (threadItem) => threadItem?.id === newThreadId
      )[0]?.conversationId;
      const response = await Services.ThreadServices.createThread({
        roomId: id,
        threadId: newThreadId,
        question: text.trim(),
        requesterId: currentUserId,
        conversationId: currentConversationId ?? "",
        parentMessageId:
          conversation[conversation.length - 1]?.parentMessageId ?? "",
        isSubThread: history.length > 0,
        isFirstMessageOfNewSubThread:
          !conversation.length && history.length ? true : null,
        conversationLength: conversation?.length,
        teamId: selectedTeam?.teamId,
        projectId: selectedProjectId,
      });

      if (response?.status === "success") {
        setCurrentThreadId(response.data);
      }
      if (response?.status === "success" && newThreadId === "") {
        trackEvent("Created Thread", {
          thread_id: response.data,
          room_id: id,
        });
        trackEvent("Chat - New Thread", {
          teamID: selectedTeam?.teamId,
          projectID: selectedProjectId,
          fileID: id,
          source: "Threads",
        });
      }
    } catch (error) {
      console.log("error: ", error);
    }
    setIsLoading(false);
    setCreatingThread(false);
    setText("");
  };

  const handleNewThread = useCallback(() => {
    trackEvent("Clicked New Thread", {
      room_id: id,
    });

    setCurrentThreadId("");
    setConversation([]);
    setHistory([]);
  }, []);

  const handleChangeThread = (changedThreadId) => {
    trackEvent("Switching Thread", {
      old_thread_id: currentThreadId,
      new_thread_id: changedThreadId,
      room_id: id,
    });

    setCurrentThreadId(changedThreadId);
  };

  const handleSubThread = async (bubbleItem, index, setLoading) => {
    try {
      trackEvent("Creating Sub Thread", {
        parent_thread_id: currentThreadId,
        room_id: id,
      });

      const fullConvo = [...history, ...conversation];
      const newConvo = fullConvo.slice(0, index);
      const currentConversationId = threads?.filter(
        (threadItem) => threadItem?.id === currentThreadId
      )[0]?.conversationId;

      setLoading(true);
      const response = await createSubThread({
        parentThreadId: currentThreadId,
        parentHistory: newConvo,
        roomId: id,
        previousConversationId: currentConversationId ?? "",
        previousMessageId: bubbleItem?.parentMessageId,
      });
      setLoading(false);

      if (response?.data?.status === "success") {
        trackEvent("Chat - New Thread", {
          teamID: selectedTeam?.teamId,
          projectID: selectedProjectId,
          fileID: id,
          source: "New Questions",
        });
        setToggleNewThread(!toggleNewThread);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChatMessages = async () => {
    const response = await Services.ChatServices.fetchChatMessages(
      id,
      setMessages
    );

    if (response?.status === "failed") {
      alert("Something went wrong!");
    }
  };

  // const getCollaborators = async () => {
  //   const response = await Services.ChatServices.fetchCollaborators(
  //     id,
  //     setCollaborators
  //   );

  //   if (response?.status === "failed") {
  //     alert("Something went wrong!");
  //   }
  // };
  const getThreads = useCallback(async () => {
    const response = await Services.ThreadServices.fetchThreads(id, setThreads);
    if (response?.status === "failed") {
      alert("Something went wrong!");
    }
  }, [id]);

  const onSendHandler = () => {
    if (value.trim() === "") return;

    sendMessages(
      id,
      currentUserId,
      value,
      selectedTeam?.teamId,
      selectedProjectId
    );
    setValue("");
  };

  useEffect(() => {
    dispatch(setSelectedTeam());
  }, []);

  useEffect(() => {
    const fetchWorkspaceDetail = async () => {
      const response = await Services.RoomServices.getWorkspaceDetail(
        selectedTeam?.teamId,
        id
      );

      if (response.status === "success") {
        setWorkspaceName(response.data);
      }
    };
    if (Object.keys(selectedTeam).length) {
      fetchWorkspaceDetail();
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (!id) {
      handleCreateRoom();
      return;
    }
    if (!name) {
      handleClickOpen();
    } else {
      handleSubmit();
    }
  }, [id]);

  useEffect(() => {
    (async function () {
      const response = await Services.TeamServices.getCurrentTeamSubscription(
        selectedTeam?.teamId
      );
      setCurrentTeamSubscription(response);
    })();
  }, []);

  useEffect(() => {
    if (selectedTeam?.teamId) {
      fetchAllWorkspacesForTeam(selectedTeam?.teamId, dispatch);
    }
  }, [selectedTeam?.teamId]);

  useEffect(() => {
    if (workspaceIdsForTeam) {
      checkMessageLimitAcrossProjects(
        selectedTeam?.teamId,
        workspaceIdsForTeam,
        dispatch
      );
    }
  }, [workspaceIdsForTeam, conversation]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const loadRoom = async () =>
      await Promise.all([
        getChatMessages(),
        // getCollaborators(),
        getThreads(),
        // getSharedRoomId(id, setSharedRoomId),
      ]);
    loadRoom();
  }, [id]);

  useEffect(() => {
    if (!id || currentThreadId === "") {
      return;
    }

    const docRef = realTimeRef(realDb, `AIChats/${id}/${currentThreadId}`);

    const unsubscribe = onValue(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let conversationList = [];

        Object.keys(data).forEach((key) => {
          conversationList.push({
            id: key,
            question: data[key]?.["request"],
            answer: data[key]?.["response"],
            requesterId: data[key]?.["requesterId"],
            parentMessageId: data[key]?.["parentMessageId"],
            timestamp: data[key]?.["timestamp"],
          });
        });
        setConversation(conversationList);
      } else {
        setConversation([]);
      }
    });

    return () => unsubscribe();
  }, [id, currentThreadId]);

  useEffect(() => {
    if (!id || currentThreadId === "") {
      return;
    }

    const docRef = realTimeRef(
      realDb,
      `Threads/${id}/${currentThreadId}/parentHistory`
    );

    const unsubscribe = onValue(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let historyList = [];

        Object.keys(data).forEach((key) => {
          historyList.push({
            id: key,
            question: data[key]?.["request"],
            answer: data[key]?.["response"],
            requesterId: data[key]?.["requesterId"],
            parentMessageId: data[key]?.["parentMessageId"],
            timestamp: data[key]?.["timestamp"],
          });
        });
        setHistory(historyList);
      } else {
        setHistory([]);
      }
    });

    return () => unsubscribe();
  }, [id, currentThreadId]);

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    conversationRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  useEffect(() => {
    if (!id || currentThreadId !== "") {
      return;
    }
    setCurrentThreadId(threads.length - 1 < 0 ? "" : threads[0]?.id);
  }, [threads]);

  useEffect(() => {
    setCurrentThreadId(threads.length - 1 < 0 ? "" : threads[0]?.id);
  }, [toggleNewThread]);

  useEffect(() => {
    if (isOpen) {
      trackEvent("Chat - View Collab Members", {
        teamID: selectedTeam?.teamId,
        projectID: selectedProjectId,
        fileID: id,
      });
    }
  }, [isOpen]);

  const renderBubble = (message) => {
    const isMsgOwner = message.id === currentUserId;
    const senderName = collaborators?.find(
      (item) => item?.userId === message?.id
    )?.name;

    return (
      <Stack
        direction="row"
        spacing={0.5}
        key={message.messageId}
        className={`my-3 max-w-[80%] ${isMsgOwner && "self-end"}`}
      >
        {!isMsgOwner && (
          <AvatarSvg width={30} height={30} fill={stringToColour(senderName)} />
        )}
        <div
          className={`flex-1 rounded-md ${
            isMsgOwner
              ? "bg-white rounded-tr-none"
              : "bg-newPrimary/10 rounded-tl-none"
          } px-2 py-1`}
        >
          {!isMsgOwner && (
            <p className="text-dark2 text-xs">{senderName ?? "Unknown"}</p>
          )}

          <p className="text-black text-sm text-break">{message.text}</p>
          <p
            className={`${
              isMsgOwner ? "text-dark3" : "text-dark3"
            } text-xs font-light text-right`}
          >
            {message?.timestamp}
          </p>
        </div>
      </Stack>
    );
  };

  const loadMessagesComponent = () => {
    const role = getUserRole(user?.uid, selectedTeam);
    if (!conversation[0] && !history[0])
      return (
        <div className="flex flex-1 justify-center items-center mb-[10%]">
          <p className="text-grey text-base text-center font-semibold">
            {role === "viewer"
              ? "There are no conversations"
              : "Start a conversation..."}
          </p>
        </div>
      );
    return (
      <div className="flex flex-1 flex-col overflow-y-auto pb-[8%] pr-2 m-4 md:m-0 gpt-text-container">
        {[...history, ...conversation].map((item, index) => {
          const senderName = collaborators?.find(
            (i) => i?.userId === item?.requesterId
          )?.name;
          return (
            <div key={item.id} className="mb-8">
              <Bubble
                name={senderName}
                type="question"
                message={item?.question}
                customStyle="mb-4"
                svgText={senderName}
                isRequester={index !== 0}
                onPress={(setLoading) =>
                  handleSubThread(item, index, setLoading)
                }
                isViewer={isViewer}
              />
              <Bubble type="answer" message={item?.answer} customStyle="pr-3" />
            </div>
          );
        })}
        {/* Mocked the chatgpt thinking state */}
        {/* {isLoading && (
          <div className="mb-8">
            <Bubble
              name={localStorage.getItem("name")}
              type="question"
              message={text}
              customStyle="mb-4 pr-3"
            />
            <Bubble type="answer" message={"..."} customStyle="pr-3" />
          </div>
        )} */}
        <div ref={conversationRef} />
      </div>
    );
  };

  const handleBackPress = () => {
    setAppDrawerProps((prev) => ({ ...prev, open: false }));
  };

  const renderCollaboratorChat = () => {
    return (
      <Box className="flex flex-col h-screen bg-newPrimaryFaded relative">
        {isOpen && (
          <div className="absolute left-4 right-4 z-[500] top-[67px]">
            <ActiveMemberCard
              data={collaborators}
              onClose={() => setIsOpen(false)}
            />
          </div>
        )}
        <div className="fs:hidden fmd:block w-full px-4 mt-6">
          {/* <LinkCard title={"Invite Link"} /> */}
        </div>

        <div className="flex flex-row justify-between items-center px-4 pb-4 fs:mt-4 md:mt-0">
          <div className="flex flex-row items-center">
            <BackArrowSvg
              width={15}
              height={15}
              className="fmd:hidden fs:block fs:mr-4"
              onClick={handleBackPress}
              fill="black"
            />
            <p className="text-base text-black font-semibold">
              Collaborator Chat
            </p>
          </div>
          <AvatarGroup
            max={3}
            onClick={() => {
              setIsOpen((prev) => !prev);
              trackEvent("View Active Collaborators", {
                room_id: id,
              });
            }}
            sx={{
              "& .MuiAvatar-root": {
                width: 24,
                height: 24,
                fontSize: 12,
                background: Colors.black,
                border: "none",
              },
            }}
          >
            {selectedTeam?.contributers &&
              selectedTeam?.admin &&
              selectedTeam?.viewers &&
              [
                ...selectedTeam?.contributers,
                ...selectedTeam?.viewers,
                ...selectedTeam?.admin,
              ].map((item) => {
                return (
                  <Avatar
                    alt="Remy Sharp"
                    src={item?.profileImage}
                    className="!h-6 !w-6 !cursor-pointer !border-none"
                  />
                );
              })}
          </AvatarGroup>
        </div>

        <div className="flex flex-col px-4 h-[80%] overflow-y-scroll pb-[10%]">
          {messages.map((message) => renderBubble(message))}
          <div ref={chatRef} />
        </div>
        <div className="flex flex-1 w-full fs:fixed fmd:absolute bottom-[40px] px-4">
          <ChatTextField
            className="flex rounded-xl"
            value={value}
            onChange={(e) => setValue(e?.target?.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter" && event.shiftKey) {
                event.preventDefault();
                setValue(`${value}\n`);
              }
            }}
            onKeyPress={(e) => {
              if (e?.key !== "Enter") {
                return;
              }
              e?.preventDefault();
              onSendHandler();
              trackEvent("Using Enter Key to Send Collaborator Message", {
                message: value,
                room_id: id,
              });
            }}
            onSend={() => {
              onSendHandler();
              trackEvent("Using Send Button to Send Collaborator Message", {
                message: value,
                room_id: id,
              });
            }}
            placeholder="Message collaborators"
            maxRows={7}
            containerStyles={"py-[11px] px-4 text-field"}
          />
        </div>
      </Box>
    );
  };
  const renderMainChat = () => {
    return (
      <SideBar
        data={threads}
        onPressCreate={handleNewThread}
        onPressWorkspace={handleNewWorkspace}
        onCloneWorkspace={handleCloneWorkspace}
        onChangeThread={handleChangeThread}
        setCreatingThread={setCreatingThread}
        currentThreadId={currentThreadId}
        creatingThread={creatingThread}
        isViewer={isViewer}
        roomId={id}
        projectId={selectedProjectId}
      />
    );
  };
  const renderBothChats = () => {
    if (appDrawerProps.anchor === "left") {
      return renderMainChat();
    } else if (appDrawerProps.anchor === "right") {
      return renderCollaboratorChat();
    } else {
      return (
        <div className="w-full px-4 py-4 bg-grey5">
          {/* <LinkCard title={"Invite Link"} />
          <div className="mb-2" />
          <LinkCard
            title={"View Invite Link"}
            link={`${process.env.REACT_APP_WEBSITE_URL}share/${sharedRoomId}`}
          /> */}
          <Stack direction="row" gap={1} mt={2}>
            <FacebookShareButton
              url={window.location.href}
              description="Unboard Chat room"
              quote="Unboard Chat room"
            >
              <FacebookIcon size={52} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              url={window.location.href}
              description="Unboard Chat room"
              quote="Unboard Chat room"
            >
              <TwitterIcon size={52} round />
            </TwitterShareButton>
          </Stack>
        </div>
      );
    }
  };
  return (
    <>
      <Notification />
      <PhoneNav
        appDrawerProps={appDrawerProps}
        setAppDrawerProps={setAppDrawerProps}
        workspaceName={workspaceName}
      />
      <Stack>
        <AppDrawer
          appDrawerProps={appDrawerProps}
          setAppDrawerProps={setAppDrawerProps}
        >
          {renderBothChats()}
        </AppDrawer>
        <div className="flex flex-1 h-screen bg-primary">
          <div className="w-1/5 fs:hidden fmd:block">{renderMainChat()}</div>
          <Box className="fs:w-[100%] fmd:w-[55%] h-screen fs:pt-14 fmd:p-6 bg-white">
            <div className="flex flex-col  h-full w-full  relative">
              <p className="font-bold text-black fs:hidden fmd:block">
                {workspaceName}
              </p>
              <hr className="fs:hidden fmd:block mt-3 mb-4 bg-dark9"></hr>
              {loadMessagesComponent()}
              <div className="flex pb-[14px] px-4 md:px-0 w-full bg-white">
                {!isViewer && (
                  <GptTextField
                    // loading={isLoading}
                    value={text}
                    onChange={(e) => setText(e?.target?.value)}
                    placeholder="Ask a question"
                    containerStyles={
                      "gpt-field py-[11px] px-4 backdrop-blur-3xl"
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && event.shiftKey) {
                        event.preventDefault();
                        setText(`${text}\n`);
                      }
                    }}
                    onKeyPress={(e) => {
                      if (e.key !== "Enter") {
                        return;
                      }
                      e.preventDefault();
                      handleCreateThread();
                      trackEvent("Using Enter Key to Send Request", {
                        request: text,
                        room_id: id,
                      });
                    }}
                    onSend={() => {
                      handleCreateThread();
                      trackEvent("Using Send Button to Send Request", {
                        request: text,
                        room_id: id,
                      });
                    }}
                    maxRows={7}
                  />
                )}
              </div>
            </div>
          </Box>
          <div className=" fs:hidden fmd:block fmd:w-1/4 ">
            {renderCollaboratorChat()}
          </div>
        </div>
      </Stack>
    </>
  );
};

export default Room;
