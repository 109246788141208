import { Divider, Drawer, Stack, SwipeableDrawer } from "@mui/material";
import React from "react";
import { Colors } from "../../../themes";

const AppDrawer = ({
  children,
  appDrawerProps,
  setAppDrawerProps,
  style,
  type,
}) => {
  const { open, anchor, drawerWidth, closeModal, customZIndex } =
    appDrawerProps;
  const DrawerType = type === "swipeable" ? SwipeableDrawer : Drawer;
  const drawerPaperStyle = {
    boxSizing: "border-box",
    width: drawerWidth,
    overflow: "hidden",
    ...style,
  };
  const getDivider = () => {
    if (type === "swipeable") {
      return (
        <Divider
          sx={{
            bgcolor: Colors.whiteFaded60,
            height: "4px",
            width: "56px",
            borderRadius: "8px",
            mx: "auto",
          }}
        />
      );
    }
  };
  const getConditionalZIndex = () => {
    if (customZIndex) {
      return {
        zIndex: 100,
      };
    }
    return {};
  };

  return (
    <DrawerType
      anchor={anchor}
      open={open}
      sx={{
        ...{
          "& .MuiDrawer-paper": drawerPaperStyle,
        },
        ...getConditionalZIndex(),
      }}
      onClose={() => {
        if (closeModal) {
          closeModal();
        }
        if (!setAppDrawerProps) return;
        setAppDrawerProps((prev) => ({ ...prev, open: false }));
      }}
    >
      {getDivider()}
      {children}
    </DrawerType>
  );
};

export default AppDrawer;
