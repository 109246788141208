import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  createCheckoutSession,
  onCurrentUserSubscriptionUpdate,
} from "@stripe/firestore-stripe-payments";
import {
  ArrowDownSvg,
  CloseSvg,
  LeftArrowSvg,
  NoSvg,
  YesSvg,
} from "../../../assets";
import SubscriptionCard from "../../common/SubscriptionCard";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { Colors } from "../../../themes";
import { MedText, RegText } from "../../../styles";
import CustomBtn from "../../common/CustomBtn";
import { payments } from "../../../libraries/stripe";
import { fetchCurrentTeamSubscription } from "../../../services/payment";
import { auth, createPortalLink } from "../../../libraries/firebase";
import Loading from "../../common/Loading";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { trackEvent } from "../../../utils/functions";
import { capitalize } from "../../../helper/formatting";

const UpgradePlanScreen = ({
  type,
  teamId,
  existingPlan,
  setChangePlan,
  createdTeamInfo,
  setShowUpgradePlan,
  lastPlan,
}) => {
  const { pricing, products } = useSelector((state) => state.app);
  const { selectedTeam: currentTeam } = useSelector((state) => state.user);
  const selectedTeam = createdTeamInfo ?? currentTeam;

  const [isExpanded, setIsExpanded] = useState({
    free: false,
    premium: false,
    ultra: false,
  });

  const [currentPlan, setCurrentPlan] = useState(
    existingPlan ?? lastPlan ?? "free"
  );
  const [selectedPlan, setSelectedPlan] = useState(
    type === "create-team" ? "premium" : currentPlan
  );
  const [initialPlan, setInitialPlan] = useState(
    type === "create-team" ? null : currentPlan
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isActivePlan, setIsActivePlan] = useState(null);
  const [tempLoader, setTempLoader] = useState(false);
  const currentUser = auth?.currentUser;
  const isAdmin = selectedTeam?.admin?.some(
    (item) => item?.userId === currentUser?.uid
  );
  const navigate = useNavigate();

  useEffect(() => {
    onCurrentUserSubscriptionUpdate(payments, (snapshot) => {
      for (const change in snapshot.changes) {
        const plan = products?.find(
          (item) =>
            item?.name.toLowerCase() ===
            change?.subscription?.product?.toLowerCase()
        )?.name;
        if (change.type === "added") {
          trackEvent(`Subscriptions - ${capitalize(plan)} - Enabled`);
        }

        if (change.type === "modified") {
          trackEvent("Subscriptions - Change Plan", {
            plan: capitalize(plan),
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!selectedTeam?.teamId) return;
    if (!isAdmin) {
      navigate("/");
    }
  }, [selectedTeam, isAdmin]);

  useEffect(() => {
    if (lastPlan) {
      setCurrentPlan(lastPlan);
    }
  }, [lastPlan]);

  const quantity = useMemo(() => {
    return selectedTeam?.admin?.length + selectedTeam?.contributers?.length;
  }, [selectedTeam]);

  useEffect(() => {
    if (type === "create-team") return;
    if (initialPlan === "free") {
      setInitialPlan("premium");
      return;
    }
    if (initialPlan === "premium") {
      setInitialPlan("ultra");
      return;
    }
    if (initialPlan === "ultra") {
      setInitialPlan("premium");
      return;
    }
    setInitialPlan("free");
  }, []);

  useEffect(() => {
    (async function () {
      const res = await fetchCurrentTeamSubscription(selectedTeam?.teamId);
      setIsActivePlan(res);
    })();
  }, [selectedTeam?.teamId]);

  const handleExpand = (key) => {
    setIsExpanded({ ...isExpanded, [key]: !isExpanded[key] });
  };

  const renderLoader = () => {
    if (!tempLoader) return;
    return <Loading />;
  };

  const onSubscriptionHandler = async (planType) => {
    try {
      const url =
        type === "create-team"
          ? `${process.env.REACT_APP_WEBSITE_URL}create-team/2`
          : `${process.env.REACT_APP_WEBSITE_URL}teams/${selectedTeam?.teamId}/subscriptions`;

      const filterProducts = products.find(
        (item) => item?.name.toLowerCase() === planType.toLowerCase()
      );
      setIsLoading(true);
      const session = await createCheckoutSession(payments, {
        price: filterProducts?.prices[0]?.id,
        metadata: {
          teamId: `${teamId}`,
        },
        quantity: 1,
        success_url: url,
        cancel_url: url,
        allow_promotion_codes: true,
      });
      setIsLoading(false);
      window.location.assign(session.url);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubscriptionClick = async (planType) => {
    trackEvent(`Subscriptions - ${capitalize(planType)}`);

    setSelectedPlan(planType);
    setCurrentPlan(planType);
    onSubscriptionHandler(planType);
  };

  const getButtonText = (planType) => {
    if (selectedTeam?.plan === planType) {
      return "Current Plan";
    }
    // if (
    //   (currentPlan === "premium" && planType === "free") ||
    //   (currentPlan === "ultra" && planType === "free") ||
    //   (currentPlan === "ultra" && planType === "premium")
    // ) {
    //   return "Downgrade";
    // }
    // return "Upgrade";

    if (planType === "free") return "Free Access";

    if (planType === "premium") return "Elite Access";

    if (planType === "ultra") return "Unlock Superpowers";
  };

  const getButtonTextForCreateTeam = (planType) => {
    if (planType === "free") {
      return "Continue with Free Access";
    }
    if (planType === "premium") {
      return "Get Premium Access";
    }
    return "Unlock Superpowers";
  };

  const getAppStyles = (isSelectedPlan) => {
    let style = {
      mobHeading: Colors.blackFaded80,
      optionContainer: Colors.blackFaded54,
      fontColor: Colors.blackFaded90,
    };
    if (isSelectedPlan) {
      style = {
        mobHeading: Colors.white,
        optionContainer: Colors.whiteFaded60,
        fontColor: Colors.whiteFadedO90,
      };
    }
    return style;
  };

  const getButtonBg = (isSelectedPlan, planType) => {
    if (type !== "create-team" && planType === currentPlan) {
      if (selectedPlan === currentPlan && !initialPlan) {
        return Colors.primaryBtnColor;
      }
      return "transparent";
    }
    if (isSelectedPlan) {
      return Colors.primaryBtnColor;
    }
    return "transparent";
  };

  const renderFeatures = (planFeatures, fontColor) => {
    return planFeatures?.map((feature, index) => (
      <Stack direction="row" alignItems="center" key={index} gap="10px">
        {planFeatures?.includes(feature) ? <YesSvg /> : <NoSvg />}
        <MedText variant="body1" sx={{ color: fontColor }}>
          {feature}
        </MedText>
      </Stack>
    ));
  };

  const renderPlanActionBtn = (planType, priceAmount) => {
    priceAmount = priceAmount * 100;

    if (currentPlan === planType && isLoading) {
      return <CircularProgress sx={{ color: Colors.white }} size={30} />;
    }
    const customComponent = (
      <MedText variant="body2" component="span">
        {type === "create-team"
          ? getButtonTextForCreateTeam(planType)
          : getButtonText(planType)}
      </MedText>
    );
    return customComponent;
  };

  return (
    <div
      className={`mt-4 md:mt-0 px-4 py-8 text-white h-auto ${
        type === "create-team" ? "bg-fadedBlue" : "bg-white"
      } rounded-3xl mx-5 md:mx-auto md:w-full relative`}
    >
      {lastPlan && (
        <div className="flex justify-end px-4 md:absolute top-7 right-8">
          <IconButton onClick={() => setShowUpgradePlan(false)}>
            <CloseSvg stroke={Colors.black} />
          </IconButton>
        </div>
      )}
      <div
        className={`flex flex-row  items-start ${
          existingPlan ? "justify-start" : "justify-center"
        }`}
      >
        {existingPlan && (
          <IconButton onClick={() => setChangePlan(false)}>
            <LeftArrowSvg />
          </IconButton>
        )}
        <div className="ml-4">
          <div
            className={`flex gap-5 mb-3 ${
              existingPlan ? "justify-start" : "justify-center"
            } md: 
          "justify-center"
        items-center`}
          >
            <MedText variant="body1" textAlign={"center"} color={Colors.black}>
              Choose the Perfect Plan for Your Needs
            </MedText>
          </div>
          <RegText
            variant="body2"
            sx={{
              color: Colors.blackFaded70,
              textAlign: existingPlan ? "left" : "center",
              width: { xs: "80%", md: "100%" },
              mx: "auto",
            }}
          >
            Upgrade Your Experience with Our Membership Plans
          </RegText>
        </div>
      </div>
      <Stack
        direction={{ xs: "column", md: "row" }}
        gap="24px"
        mt={{ xs: "24px", md: "40px" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {pricing.map((pricingItem, index) => {
          const planType = pricingItem?.name?.toLowerCase();
          const renderPlan = initialPlan ? initialPlan : selectedPlan;
          const isSelectedPlan = planType === renderPlan;
          const priceAmount = pricingItem?.price;
          const buttonBg = getButtonBg(isSelectedPlan, planType);
          const { mobHeading, optionContainer, fontColor } =
            getAppStyles(isSelectedPlan);
          return (
            <motion.div
              key={pricingItem?.name}
              initial={{ x: 100, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: { delay: 0.1 * index, duration: 0.2 },
              }}
            >
              <SubscriptionCard
                heading={pricingItem?.name}
                price={priceAmount}
                subHeading={pricingItem?.description}
                key={planType}
                planType={planType}
                isSelectedPlan={isSelectedPlan}
                setSelectedPlan={setSelectedPlan}
                setInitialPlan={setInitialPlan}
                currentPlan={currentPlan}
                type={type}
              >
                <Stack mt="21px" sx={{ display: { xs: "flex", md: "none" } }}>
                  {!isExpanded[planType] && (
                    <button
                      className="flex justify-between items-center font-medium w-full"
                      onClick={() => handleExpand(planType)}
                    >
                      <MedText variant="body1" sx={{ color: mobHeading }}>
                        View all features
                      </MedText>
                      <ArrowDownSvg stroke={mobHeading} />
                    </button>
                  )}
                  {isExpanded[planType] && (
                    <ul className="text-black/60">
                      <Stack
                        gap="16px"
                        component="ul"
                        sx={{ color: optionContainer }}
                      >
                        {renderFeatures(pricingItem?.features, fontColor)}
                      </Stack>
                    </ul>
                  )}
                </Stack>
                <div className="hidden md:block">
                  <Stack
                    gap="16px"
                    component="ul"
                    sx={{
                      color: optionContainer,
                      mt: "20px",
                    }}
                  >
                    {renderFeatures(pricingItem?.features, fontColor)}
                  </Stack>
                </div>
                <CustomBtn
                  type={
                    isSelectedPlan
                      ? ""
                      : selectedTeam?.plan === planType &&
                        type !== "create-team"
                      ? "secondary"
                      : "outlined"
                  }
                  styles={{
                    mt: "58px",
                  }}
                  disabled={currentPlan === planType && type !== "create-team"}
                  onClick={() => {
                    if (currentPlan === planType && type !== "create-team")
                      return;

                    onSubscriptionClick(planType);
                  }}
                >
                  {renderPlanActionBtn(planType, priceAmount)}
                </CustomBtn>
              </SubscriptionCard>
              {renderLoader()}
            </motion.div>
          );
        })}
      </Stack>
    </div>
  );
};

export default UpgradePlanScreen;
