import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import SettingNav from "../../common/SettingNav";
import { Stack } from "@mui/material";
import { useState, useEffect } from "react";
import UpgradePlanScreen from "../UpgradePlanScreen";
import {
  fetchCurrentTeamSubscription,
  fetchInvoices,
} from "../../../services/payment";
import { formatDate } from "../../../helper/date";
import { capitalize } from "../../../helper/formatting";

const BillingScreen = () => {
  const { selectedTeam } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.app);
  const [invoices, setInvoices] = useState([]);
  const [isActivePlan, setIsActivePlan] = useState(null);
  const [changePlan, setChangePlan] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    (async function getInvoices() {
      const response = await fetchInvoices(selectedTeam?.teamId);
      setIsFetching(false);
      setInvoices(response);
    })();
  }, [selectedTeam?.teamId]);

  useEffect(() => {
    (async function () {
      const res = await fetchCurrentTeamSubscription(selectedTeam?.teamId);
      setIsActivePlan(res);
    })();
  }, [selectedTeam?.teamId]);

  const getPlanName = (productId) => {
    const planName =
      products?.filter((productItem) => productItem?.id === productId)[0]
        ?.name ?? "free";

    return capitalize(planName);
  };

  const getPlanAmount = (invoiceLineData, invoiceIndex) => {
    if (
      invoiceLineData[invoiceIndex - 1] &&
      invoiceLineData[invoiceIndex - 1]?.amount < 0 &&
      Math.abs(invoiceLineData[invoiceIndex - 1]?.amount) <
        Math.abs(invoiceLineData[invoiceIndex]?.amount)
    ) {
      return (
        (invoiceLineData[invoiceIndex]?.amount -
          Math.abs(invoiceLineData[invoiceIndex - 1]?.amount)) /
        100
      )?.toFixed(2);
    }

    return Math.abs(invoiceLineData[invoiceIndex]?.amount / 100)?.toFixed(2);
  };

  const renderMobileBilling = () => {
    return (
      <div className="md:hidden">
        {invoices?.map((invoice) => {
          return invoice?.lines?.data?.map((invoiceData, i) => {
            const invoiceAmount = getPlanAmount(invoice?.lines?.data, i);

            return (
              <div
                key={i}
                className="flex justify-between py-3 border-b border-b-black/20"
              >
                <div className="">
                  <h1 className="text-black/70 text-base mb-3">
                    {formatDate(invoice?.created * 1000)}
                  </h1>
                  <h2 className="text-sm font-medium mb-2">
                    {getPlanName(invoiceData?.price?.product)}
                  </h2>
                  <h3 className="text-black/70 text-sm mb-4">
                    {`${formatDate(
                      invoiceData?.period?.start * 1000
                    )} - ${formatDate(invoiceData?.period?.end * 1000)}`}
                  </h3>
                </div>
                <div className="">
                  <h4 className="font-medium text-lg">${invoiceAmount}</h4>
                  <p className="text-black/70">
                    {invoice?.status === "paid" ||
                    invoice?.status === "open" ? (
                      <Link
                        onClick={() => {
                          window.open(invoice?.hosted_invoice_url, "_blank");
                        }}
                        className="text-sm text-newPrimary text-medium"
                      >
                        View invoice
                      </Link>
                    ) : (
                      invoice?.status
                    )}
                  </p>
                </div>
              </div>
            );
          });
        })}
      </div>
    );
  };

  const renderDesktopBilling = () => {
    return (
      <div className=" hidden md:block">
        <table class="w-full">
          <thead>
            <tr className="border-b border-b-grayBlueFaded text-left font-medium text-sm">
              <th className="py-3">Date</th>
              <th>Plan</th>
              <th>Service period</th>
              <th>Total</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {invoices?.map((invoice) => {
              return invoice?.lines?.data?.map((invoiceData, i) => {
                const invoiceAmount = getPlanAmount(invoice?.lines?.data, i);

                return (
                  <tr
                    key={i}
                    className="border-b border-b-grayBlueFaded text-sm"
                  >
                    <td className="py-3">
                      {formatDate(invoice?.created * 1000)}
                    </td>
                    <td>{getPlanName(invoiceData?.price?.product)}</td>
                    <td>{`${formatDate(
                      invoiceData?.period?.start * 1000
                    )} - ${formatDate(invoiceData?.period?.end * 1000)}`}</td>
                    <td>$ {invoiceAmount}</td>
                    <td>
                      {invoice?.status === "paid" ||
                      invoice?.status === "open" ? (
                        <Link
                          onClick={() => {
                            window.open(invoice?.hosted_invoice_url, "_blank");
                          }}
                          className="text-sm text-newPrimary text-medium"
                        >
                          View invoice
                        </Link>
                      ) : (
                        invoice?.status
                      )}
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderContent = () => {
    const invoiceData =
      invoices?.[0]?.lines?.data[invoices?.[0]?.lines?.data?.length - 1];
    const invoiceAmount = (invoiceData?.amount / 100)?.toFixed(2);

    if (isFetching) return null;

    if (changePlan) {
      return (
        <UpgradePlanScreen
          existingPlan={getPlanName(invoiceData?.price?.product)?.toLowerCase()}
          teamId={selectedTeam?.teamId}
          setChangePlan={setChangePlan}
        />
      );
    }

    if (invoices?.length > 0) {
      return (
        <div className="text-black px-4 py-4 ">
          <div className="bg-newPrimaryFadedTwo grid grid-cols-1 py-4 px-2 rounded-lg overflow-auto md:grid-cols-4 md:py-7">
            <div className="pr-4 py-4 border-b border-l-grayBlueFaded flex justify-between md:block md:border-r md:border-b-0">
              <div className="mb-4">
                <h2 className="text-sm mb-2">Your next billing date</h2>
                <h1 className="text-xl font-medium">
                  {formatDate(invoiceData?.period?.end * 1000)}
                </h1>
              </div>
              <div className="">
                <h1 className="text-xl font-medium md:hidden mb-2">
                  ${invoiceAmount}
                </h1>
              </div>
            </div>
            <div className="pr-4 pl-2 py-4 hidden border-r border-grayBlueFaded md:block">
              <h2 className="text-sm mb-2">Invoice total</h2>
              <h1 className="text-xl font-medium">${invoiceAmount}</h1>
            </div>
            <div className="pr-4 pl-2 py-4 border-b border-grayBlueFaded flex justify-between md:block md:border-r md:border-b-0">
              <div className="">
                <h2 className="text-sm mb-2">Your plan</h2>
                <h1 className="text-xl font-medium mb-4">
                  {getPlanName(invoiceData?.price?.product)}
                </h1>
              </div>
              <Link
                onClick={() => setChangePlan(true)}
                className="text-sm text-newPrimary text-medium"
              >
                Change plan
              </Link>
            </div>
            <div className="pl-2 py-4">
              <h2 className="text-sm mb-2">Invoices sent to</h2>
              <h1 className="text-xl font-medium">
                {invoices?.[0]?.customer_email}
              </h1>
            </div>
          </div>
          <div className="mt-10">
            <h1 className="font-medium text-lg  pb-5 md:border-b md:border-b-grayBlueFaded">
              Invoices
            </h1>
            {invoices?.length > 0 && (
              <>
                {renderMobileBilling()}
                {renderDesktopBilling()}
              </>
            )}
          </div>
        </div>
      );
    }

    if (!isActivePlan && typeof invoices === "object") {
      return <UpgradePlanScreen teamId={selectedTeam?.teamId} />;
    }
  };

  return (
    <Stack px={{ xs: 0, md: '20px' }} className="pb-8 md:pb-0 overflow-hidden">
      <SettingNav />
      {renderContent()}
    </Stack>
  );
};

export default BillingScreen;
