import { Colors } from "../../../../themes";

export const styles = {
  root: {
    padding: "14px 20px 0 20px",
    display: { xs: "none", md: "flex" },
    bgcolor: Colors.white,
    position: "sticky",
    top: 0,
    zIndex: 20,
  },
  navbar: {
    bgcolor: Colors.white,
    py: "24px",
    px: "8px",
    display: { xs: "flex", md: "none" },
    position: "sticky",
    top: 0,
    zIndex: 2,
    maxHeight: "72px",
  },
  text: {
    color: Colors.black,
    ml: "16px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    paddingY: "11px",
  },
  workspaceNav: {
    alignItems: "center",
    marginBottom: "12px",
  },
  semiBoldText: {
    color: Colors.black,
  },
  button: {
    borderRadius: "8px",
    padding: "8px 16px",
    width: "128px",
    height: "48px",
    textTransform: "inherit",
    ml: "auto",
  },
  medText: {
    color: Colors.newPrimary,
  },
  hr: {
    border: `0.5px solid ${Colors.whiteFaded2}`,
    borderBottom: "none",
    marginLeft: 12,
    marginRight: 12,
  },
  searchSvgContainer: { width: "20px", height: "20px", mr: "13.5px" },
  textFieldInputProps: {
    backgroundColor: Colors.whiteFaded02,
    borderRadius: "8px",
    height: "48px",
    paddingLeft: "11.35px",
    color: Colors.dark,
    fontFamily: `"Poppins", sans-serif`,
  },
  workspaceDesktopNav: {
    position: "sticky",
    display: { xs: "none", md: "flex" },
    bgcolor: Colors.white,
    zIndex: 10,
    pt: "12px",
  },
};
