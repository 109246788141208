import React from "react";
import SideNavbar from "./SideNavbar";
import { Stack } from "@mui/material";
import TopNavbar from "./TopNavbar";
import { Colors } from "../../../themes";
import PrivateRoutes from "../../../ProtectedRoutes";

const Navigation = ({ children }) => {
  return (
    <PrivateRoutes>
      <Stack direction="row">
        <SideNavbar children={children} />
        <Stack flex={1} bgcolor={Colors.white} minHeight="100vh">
          <TopNavbar />
          {children}
        </Stack>
      </Stack>
    </PrivateRoutes>
  );
};

export default Navigation;
