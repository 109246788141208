import { Colors } from "../../../themes";

export const styles = {
  heading: {
    mb: "8px",
    color: Colors.black,
  },
  description: {
    color: Colors.white,
    opacity: 0.6,
  },
};
