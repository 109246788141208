import {
  set,
  realDb,
  realTimeRef,
  update,
  get,
  child,
  onValue,
  firestoreDb,
  getDoc,
  doc,
} from "../libraries/firebase";

export const createCollabRoom = async (roomId, sharedRoomId) => {
  try {
    const pathRef = realTimeRef(realDb, `room/${roomId}`);
    const sharedPathRef = realTimeRef(realDb, `share/${sharedRoomId}`);

    await set(pathRef, {
      collaborators: "null",
      chats: "null",
      sharedRoomId,
    });
    await set(sharedPathRef, {
      collaborators: "null",
      chats: "null",
    });

    return "success";
  } catch (error) {
    console.log(error);
    return "failed";
  }
};

export const joinAsCollaborator = async (roomId, uid, name) => {
  try {
    const pathRef = realTimeRef(realDb, `room/${roomId}/collaborators`);

    await update(pathRef, {
      [uid]: name,
    });

    return "success";
  } catch (error) {
    console.log(error);
    return "failed";
  }
};

export const createCloneRoom = async (
  roomId,
  currentRoomId,
  userId,
  username,
  sharedRoomId
) => {
  try {
    const pathRef = realTimeRef(realDb);
    const sharedPathRef = realTimeRef(realDb, `share/${sharedRoomId}`);

    get(child(pathRef, `room/${currentRoomId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const cloneRoomRef = realTimeRef(realDb, `room/${roomId}`);

          set(cloneRoomRef, {
            ...snapshot.val(),
            collaborators: {
              [userId]: username,
            },
            chats: "null",
            sharedRoomId,
          });
          set(sharedPathRef, {
            ...snapshot.val(),
            collaborators: {
              [userId]: username,
            },
            sharedRoomId: null,
            chats: "null",
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return "success";
  } catch (error) {
    console.log(error);
    return "failed";
  }
};

export const getSharedRoomId = async (currentRoomId, setSharedRoomId) => {
  try {
    const pathRef = realTimeRef(realDb);
    const snapshot = await get(child(pathRef, `room/${currentRoomId}`));
    if (snapshot.exists()) {
      const { sharedRoomId = "" } = snapshot.val();
      setSharedRoomId(sharedRoomId);
    } else {
      console.log("No data available");
    }
    return "success";
  } catch (error) {
    console.log(error);
  }
};

export const getWorkspaceDetail = async (teamId, workspaceId) => {
  try {
    const workspaceRef = doc(
      firestoreDb,
      `Teams`,
      `${teamId}`,
      `Workspaces`,
      `${workspaceId}`
    );
    const snapshot = await getDoc(workspaceRef);
    if (snapshot.exists()) {
      return {
        data: snapshot?.data()?.name,
        status: "success",
      };
    }
    return {
      data: null,
      status: "failed",
    };
  } catch (error) {
    console.log(error);
  }
};
