import React from "react";
import { Box, Stack } from "@mui/material";
import { LogoSvg, UnboardLogoSvg } from "../../../assets";
import { MedText, RegText, SemiBoldText } from "../../../styles";
import CustomBtn from "../../common/CustomBtn";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { trackEvent } from "../../../utils/functions";

const HomeScreen = () => {
  const { data, teams } = useSelector((state) => state.user);
  const openInvitationAfterSignIn = JSON.parse(
    localStorage.getItem("openInvitationAfterSignIn")
  );
  const navigate = useNavigate();

  if (openInvitationAfterSignIn) {
    return (
      <Navigate to={`/teams/${openInvitationAfterSignIn}/invitation`} replace />
    );
  }
  if (teams.length > 0 && teams[0]?.teamId) {
    return <Navigate to={`/teams/${teams[0]?.teamId}`} replace />;
  }

  return (
    <div className="bg-white  h-full text-center">
      <div className=" flex flex-col items-center justify-center h-full">
        <div className="text-black mt-[86px] md:mt-0">
          <UnboardLogoSvg
            fill="currentColor"
            className="h-[61.88px] md:h-[99px] w-[132.5px] md:w-[212px]"
          />
        </div>
        <SemiBoldText className="!text-[20px] md:!text-[36px] !mt-5 md:!mt-10 !leading-[32px]md:!leading-[46px]">
          Welcome to Unboard, {data?.displayName}
        </SemiBoldText>
        <MedText className="md:!text-[22px] !leading-[32px]">
          Are you ready to innovate together?
        </MedText>
        <RegText className="!mt-auto md:!mt-6 !leading-[22px]">
          Unboard on almost anything with
          <br className="block md:hidden" /> your people in Files,
          <br className="md:block hidden" />
          <br className="block md:hidden" />
          and compile them into individual
          <br className="block md:hidden" /> projects for different teams.
        </RegText>
        <CustomBtn
          {...styles.customBtn}
          onClick={() => {
            trackEvent("Create Team - New Team", {
              Source: "New User",
            });
            localStorage.removeItem("storedTeam");
            localStorage.removeItem("storedEmails");
            navigate("/create-team");
          }}
        >
          Create a new team
        </CustomBtn>
      </div>
    </div>
  );
};

export default HomeScreen;
