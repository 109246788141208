import React from "react";
import { RegText, StyledMenu } from "../../../styles";
import { Divider, MenuItem, Stack } from "@mui/material";
import { Colors } from "../../../themes";

const SubMenu = ({
  anchorEl,
  open,
  handleClose,
  menuItems,
  width,
  marginTop,
}) => {
  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      variant="menu"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      width={width}
      marginTop={marginTop}
    >
      {menuItems?.map(({ icon, text, color, action, onClick }, index) => (
        <MenuItem
          key={text}
          component={Stack}
          gap={1.5}
          pl={"8px !important"}
          disableRipple={true}
          onClick={onClick}
        >
          <Stack
            direction="row"
            gap={1.5}
            alignItems="center"
            alignSelf="flex-start"
          >
            {icon}
            <RegText variant="body2" sx={{ color: color ?? Colors.black }}>
              {text}
            </RegText>
          </Stack>
          {index !== menuItems.length - 1 && (
            <hr className="w-[90%] absolute bottom-0 left-1/2 -translate-x-1/2" />
          )}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

export default SubMenu;
