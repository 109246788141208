import * as RoomServices from "./room";
import * as ChatServices from "./chat";
import * as ThreadServices from "./thread";
import * as ApiServices from "./api";
import * as SettingsServices from "./settings";
import * as ProjectServices from "./project";
import * as WorkSpacesServices from "./workspaces";
import * as TeamServices from "./team";
import * as InviteServices from "./invite";

export const Services = {
  RoomServices,
  ChatServices,
  ThreadServices,
  ApiServices,
  SettingsServices,
  ProjectServices,
  WorkSpacesServices,
  TeamServices,
  InviteServices,
};
