import { createSlice } from "@reduxjs/toolkit";

export const testSlice = createSlice({
  name: "test",
  initialState: {
    selectedProject: 0,
    appProjects: [],
    userSearch: {
      input: "",
      result: [],
    },
  },
  reducers: {
    deleteProject: (state, action) => {
      state.appProjects = state.appProjects.filter(
        (project) => project.id !== action.payload
      );
    },
    addProject: (state, action) => {
      state.appProjects.push(action.payload);
    },
    deleteWorkspace: (state, action) => {
      state.appProjects.forEach((project) => {
        project.workspaces = project.workspaces.filter(
          (workspace) =>
            workspace.id !== action.payload.workspaceId &&
            project.id === action.payload.projectId
        );
      });
    },
    addWorkspace: (state, action) => {
      state.appProjects.forEach((project) => {
        if (project.id === state.selectedProject) {
          project.workspaces.push(action.payload);
        }
      });
    },
    changeSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    getUserSearch: (state, action) => {
      if (action.payload.length === 0) {
        state.userSearch.result = [];
        state.userSearch.input = "";
        return;
      }
      state.userSearch.result = [];
      state.userSearch.input = action.payload;
      state.appProjects.forEach((project) => {
        project.workspaces.forEach((workspace) => {
          if (
            workspace.name.toLowerCase().includes(action.payload.toLowerCase())
          ) {
            state.userSearch.result.push(workspace);
          }
        });
      });
    },
  },
});
export const {
  deleteProject,
  addProject,
  deleteWorkspace,
  addWorkspace,
  changeSelectedProject,
  getUserSearch,
} = testSlice.actions;

export default testSlice.reducer;
