import React, { useState } from "react";
import { Icons } from "../../../assets";
import { AvatarSvg } from "../../../assets";
import { stringToColour } from "../../../helper/formatting";

const renderIcon = (type, svgText) => {
  if (type === "answer") {
    return (
      <img
        src={Icons.chatGpt}
        alt="user"
        className="w-[30px] h-[30px] rounded-sm"
      />
    );
  }
  return (
    <div className="w-8 h-8">
      <AvatarSvg fill={stringToColour(svgText)} />
    </div>
  );
};

const SharedBubble = ({
  type,
  name,
  message,
  customStyle,
  svgText,
  isRequester = false,
  onPress,
}) => {
  const [showSubThread, setShowSubThread] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className={`flex flex-1 flex-row justify-between items-start relative ${customStyle}`}
      onMouseEnter={() => setShowSubThread(true)}
      onMouseLeave={() => setShowSubThread(false)}
    >
      <div className="flex flex-row">
        {renderIcon(type, svgText)}
        <div className="flex flex-col ml-4 break-keep">
          {name && <p className="text-grey text-xs">{name}</p>}
          <p
            dangerouslySetInnerHTML={{
              __html: message.toString().replace(/\n/g, "<br/>"),
            }}
            className={`text-white text-sm w-full ${
              type === "answer" ? "font-normal" : "font-medium"
            }`}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default SharedBubble;
