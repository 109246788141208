import { getDownloadURL, uploadString } from "firebase/storage";
import {
  firestoreDb,
  doc,
  writeBatch,
  storage,
  storageRef,
  collection,
  addDoc,
  getDoc,
  setDoc,
} from "../libraries/firebase";
import {
  collectionGroup,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

const imageUpload = async (teamId, image) => {
  try {
    const imageRef = storageRef(storage, `teams/${teamId}/profile`);
    const uploadedImage = await uploadString(imageRef, image, "data_url");
    const downloadUrl = await getDownloadURL(uploadedImage.ref);
    return downloadUrl;
  } catch (e) {
    console.log(e);
  }
};

export const updateTeam = async (
  teamId,
  teamName,
  teamAbout,
  image,
  isImageChanged
) => {
  try {
    const batch = writeBatch(firestoreDb);
    const teamDocRef = doc(firestoreDb, `Teams`, teamId);
    let newProfileImage = image;
    if (isImageChanged) {
      newProfileImage = await imageUpload(teamId, image);
    }
    batch.update(
      teamDocRef,
      {
        name: teamName,
        about: teamAbout,
        updatedAt: serverTimestamp(),
        profileImage: newProfileImage,
      },
      { merge: true }
    );

    const teamModerationRef = doc(firestoreDb, `TeamsModerations`, teamId);

    batch.update(
      teamModerationRef,
      {
        teamName,
        teamAbout,
        updatedAt: serverTimestamp(),
        profileImage: newProfileImage,
      },
      { merge: true }
    );
    await batch.commit();

    return "success";
  } catch (error) {
    console.log(error);
  }
};

const getAmountForPlan = (plan) => {
  if (plan === "ultra") {
    return "50";
  } else if (plan === "premium") {
    return "25";
  }
  return "0";
};

export const setPlanType = async (teamId, plan) => {
  try {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 30);

    const amount = getAmountForPlan(plan);

    let paymentData = {
      amount,
      carDetail: "3333",
      createdAt: serverTimestamp(),
      paymentConfirmationDate: serverTimestamp(),
      paymentMethod: "card",
      plan,
      serviceEndDate: futureDate,
      status: "Paid",
      teamId,
      updatedAt: serverTimestamp(),
    };

    if (plan === "ultra" || plan === "premium") {
      const paymentCollRef = collection(firestoreDb, "Payments");
      const result = await addDoc(paymentCollRef, paymentData);
      await updateDoc(doc(firestoreDb, "TeamsModerations", teamId), {
        plan,
        serviceEndDate: futureDate,
      });
      return "success";
    }

    await updateDoc(doc(firestoreDb, "TeamsModerations", teamId), { plan });

    return "success";
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const getTeamData = async (teamId) => {
  try {
    const docRef = doc(firestoreDb, "Teams", teamId);
    const querySnapshot = await getDoc(docRef);

    return querySnapshot.exists() ? querySnapshot.data() : {};
  } catch (error) {
    console.log(error);
  }
};

export const getTeamModData = async (teamId) => {
  try {
    const docRef = doc(firestoreDb, "TeamsModerations", teamId);
    const querySnapshot = await getDoc(docRef);

    return querySnapshot.exists() ? querySnapshot.data() : {};
  } catch (error) {
    console.log(error);
  }
};

export const deleteTeam = async (teamId, username) => {
  try {
    const teamModerationRef = doc(firestoreDb, `TeamsModerations`, teamId);

    await setDoc(
      teamModerationRef,
      {
        isDeleted: true,
        deletedAt: serverTimestamp(),
        deletedBy: username,
      },
      { merge: true }
    );

    return "success";
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentTeamSubscription = async (teamId) => {
  if (!teamId) return;

  try {
    const subscriptionRef = collectionGroup(firestoreDb, "subscriptions");
    const subscriptionQuery = query(
      subscriptionRef,
      where("metadata.teamId", "==", teamId),
      where("status", "==", "active"),
      orderBy("created", "desc")
    );

    const querySnapShot = await getDocs(subscriptionQuery);

    if (querySnapShot.empty) return null;

    return [querySnapShot.docs[0].data()];
  } catch (error) {
    console.log(error);
  }
};
