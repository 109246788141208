import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { LightText, MedText, RegText, StyledMenu } from "../../../styles";
import {
  CheckSvg,
  DeleteSvg,
  DotMenuSvg,
  EditTwoSvg,
  MoveFileSvg,
  PointsSvg,
} from "../../../assets";
import { Colors } from "../../../themes";
import SubMenu from "../SubMenu";
import CommonModal from "../CommonModal";
import CustomBtn from "../CustomBtn";
import Input from "../Input";
import AppDrawer from "../AppDrawer";
import { useSelector } from "react-redux";
import { styles } from "./styles";
import { Services } from "../../../services";
import { auth, moveWorkspace } from "../../../libraries/firebase";
import { useNavigate } from "react-router-dom";
import {
  checkStringLength,
  getUserRole,
  trackEvent,
} from "../../../utils/functions";
import { motion } from "framer-motion";
import { useAuthState } from "react-firebase-hooks/auth";
import moment from "moment";
import { formattingSessionTime } from "../../../helper/formatting";

const ProjectCard = ({ name, sessionTime, id, setRefetch, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [workSpaceName, setWorkSpaceName] = useState(name);
  const [workSpaceNameError, setWorkSpaceNameError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { selectedTeam, selectedProjectId, projectsData, workspacesData } =
    useSelector((state) => state.user);
  const [currentProjectId, setCurrentProjectId] = useState(selectedProjectId);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const userRole = getUserRole(user?.uid, selectedTeam);

  const handleOpenSubMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      icon: <EditTwoSvg fill="black" />,
      text: "Rename",
      onClick: () => {
        setOpenEditModal(true);
        handleClose();
      },
    },
    {
      icon: <MoveFileSvg fill="black" />,
      text: "Move to Project",
      onClick: () => {
        setOpenMoveModal(true);
        handleClose();
      },
    },
    {
      icon: <DeleteSvg fill={Colors.errorColor} />,
      text: "Delete",
      color: Colors.errorColor,
      onClick: () => {
        setOpenDeleteModal(true);
        handleClose();
      },
    },
  ];

  const onRenameHandler = async () => {
    if (!workSpaceName?.trim()) {
      setWorkSpaceNameError("File cannot be empty");
      return;
    }
    if (workSpaceName?.length >= 25) {
      setWorkSpaceNameError("File name should be less than 25 characters.");
      return;
    }
    if (workspacesData?.find((item) => item?.name === workSpaceName?.trim())) {
      setWorkSpaceNameError("File name already exists.");
      return;
    }
    try {
      setIsLoading(true);
      await Services.WorkSpacesServices.renameWorkSpace(
        selectedTeam?.teamId,
        id,
        workSpaceName?.trim()
      );
      trackEvent("Workspace - File - Rename");
      setRefetch((oldValue) => !oldValue);
      setOpenEditModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteHandler = async () => {
    try {
      setIsLoading(true);
      await Services.WorkSpacesServices.deleteWorkSpace(
        selectedTeam?.teamId,
        id
      );
      trackEvent("Workspace - File - Delete");
      setRefetch((oldValue) => !oldValue);
      setOpenDeleteModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSessionTime = () => {
    if (!sessionTime || sessionTime === "") return `last session ago`;

    return `last session ${formattingSessionTime(
      moment(sessionTime * 1000).fromNow()
    )}`;
  };

  const renderRenameModal = () => {
    const closeAppDrawer = () => setOpenEditModal(false);
    const handleFileNameChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue.length <= 25) {
        setWorkSpaceName(inputValue);
        setWorkSpaceNameError(
          inputValue.length >= 25
            ? "File name should be less than 25 characters."
            : ""
        );
      }
    };
    if (isSmallScreen) {
      return (
        <>
          <AppDrawer
            type="swipeable"
            style={{
              backgroundColor: "transparent",
            }}
            appDrawerProps={{
              open: openEditModal,
              anchor: "bottom",
              drawerWidth: "100%",
              closeModal: closeAppDrawer,
            }}
          >
            <Stack sx={styles.renameModal} gap="70px">
              <Stack>
                <Input
                  style={{
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter file name"
                  value={workSpaceName}
                  onChange={handleFileNameChange}
                />
                <RegText className="text-red !text-sm !mt-4 h-4">
                  {workSpaceNameError}
                </RegText>
              </Stack>

              <CustomBtn
                type="primary"
                onClick={onRenameHandler}
                disabled={isLoading || !workSpaceName?.length}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: Colors.white }} size={30} />
                ) : (
                  "Save"
                )}
              </CustomBtn>
            </Stack>
          </AppDrawer>
        </>
      );
    }
    return (
      <CommonModal
        open={openEditModal}
        setOpen={(value) => {
          setOpenEditModal(value);
          setWorkSpaceName(name);
        }}
        heading="Rename File"
      >
        <Input
          placeholder="Enter file name"
          style={{ width: "100%" }}
          value={workSpaceName}
          onChange={handleFileNameChange}
        />
        <RegText className="text-red !text-sm !mt-4 h-4">
          {workSpaceNameError}
        </RegText>

        <Stack gap="24px" style={{ marginTop: "40px" }}>
          <CustomBtn
            type="primary"
            onClick={onRenameHandler}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              "Save"
            )}
          </CustomBtn>
          <CustomBtn
            type="secondary"
            onClick={() => {
              closeAppDrawer();
              setWorkSpaceName(name);
            }}
          >
            Cancel
          </CustomBtn>
        </Stack>
      </CommonModal>
    );
  };

  const renderDeleteModal = () => {
    return (
      <CommonModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        heading="Delete File"
        subHeading="Once you deleted, you & your team members don’t have access to this file."
      >
        <CustomBtn type="error" onClick={onDeleteHandler} disabled={isLoading}>
          {isLoading ? (
            <CircularProgress sx={{ color: Colors.white }} size={30} />
          ) : (
            "Delete"
          )}
        </CustomBtn>
        <CustomBtn
          type="secondary"
          styles={{ marginTop: "24px" }}
          onClick={() => setOpenDeleteModal(false)}
        >
          Cancel
        </CustomBtn>
      </CommonModal>
    );
  };
  const renderMoveModal = () => {
    const selectedStyle = (project) =>
      currentProjectId === project.id ? "bg-newPrimaryFadedTwo" : "";
    const selectedIcon = (project) =>
      currentProjectId === project.id ? (
        <CheckSvg stroke="black" />
      ) : (
        <PointsSvg fill="black" />
      );

    const handleMoveWorkspace = async () => {
      try {
        setIsLoading(true);
        const response = await moveWorkspace({
          newProjectId: currentProjectId,
          teamId: selectedTeam?.teamId,
          workspaceId: id,
        });

        if (response?.data?.status === "success") {
          trackEvent("Workspace - File - Move to Project");
          setOpenMoveModal(false);
          setRefetch((oldValue) => !oldValue);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <CommonModal
        open={openMoveModal}
        setOpen={setOpenMoveModal}
        heading={`Move ${name}`}
      >
        <RegText
          variant="body2"
          sx={{ color: Colors.blackFaded80, mb: "16px" }}
        >
          All of your files will be moved to the selected project.
        </RegText>
        <MedText sx={{ color: Colors.black }}>{selectedTeam?.teamName}</MedText>
        <Divider sx={{ bgcolor: Colors.blackFaded35, my: "16px" }} />
        <Box sx={{ maxHeight: "240px", overflow: "auto" }}>
          {projectsData
            ?.map((project) => (
              <button
                key={project.id}
                onClick={() => {
                  setCurrentProjectId(project?.id);
                }}
                className={`flex gap-2 items-center w-[95%]   overflow-hidden md:w-full  mt-3 py-3 px-2 hover:bg-black/10 rounded-lg duration-300 ${selectedStyle(
                  project
                )}`}
              >
                {selectedIcon(project)}
                <RegText variant="body1" sx={{ color: Colors.black }}>
                  {project?.name}
                </RegText>
              </button>
            ))
            .reverse()}
        </Box>
        <Stack gap="24px" mt="24px">
          <CustomBtn
            type="primary"
            onClick={handleMoveWorkspace}
            disabled={isLoading || currentProjectId === selectedProjectId}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              "Move"
            )}
          </CustomBtn>
          <CustomBtn type="secondary" onClick={() => setOpenMoveModal(false)}>
            Cancel
          </CustomBtn>
        </Stack>
      </CommonModal>
    );
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <SubMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        menuItems={menuItems}
      />
      <div key={id} className="cursor-pointer">
        {renderMoveModal()}
        {renderDeleteModal()}
        {renderRenameModal()}
        <Stack
          sx={styles.projectCardContainer}
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            trackEvent("Workspace - Select File");
            navigate(`/teams/${selectedTeam?.teamId}/r/${id}`);
          }}
          className="bg-newPrimaryFadedTwo border-[1px] border-grayBlueFaded"
        >
          {userRole !== "viewer" && (
            <IconButton sx={styles.iconButton} onClick={handleOpenSubMenu}>
              <DotMenuSvg
                style={{ width: "16px", height: "100%" }}
                stroke="currentColor"
              />
            </IconButton>
          )}

          <MedText variant="body2" className="cursor-pointer text-black">
            {checkStringLength(name, 20)}
          </MedText>
          <Stack sx={styles.lastSessionContainer} alignItems="center">
            <hr style={styles.divider} />
            <LightText sx={styles.lastSession}>{getSessionTime()}</LightText>
          </Stack>
        </Stack>
      </div>
    </Grid>
  );
};
export default ProjectCard;
