import { Button } from "@mui/material";
import { Colors } from "../../../../themes";

export const styles = {
  noProfileImage: {
    bgcolor: Colors.whiteFaded15,
    height: "48px",
    width: "48px",
    p: 1.5,
    borderRadius: "8px",
  },
  buttonContainer: {
    component: Button,
    position: "relative",
    padding: "12px",
    minHeight: "72px",
    width: "72px",
    alignItems: "center",
    disableFocusRipple: true,
    disableTouchRipple: true,
  },
  divider: {
    margin: "2px 0 ",
    border: `0.5px solid ${Colors.eclipse}`,
    borderBottom: "none",
    width: "48px",
  },
  dummyChildren: {
    minHeight: "100vh",
    width: "100vw",
    bgcolor: Colors.white,
    flex: 1,
    opacity: 0.9,
  },
  logo: {
    arrow: {
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
    },
  },
  createWorkspace: {
    stack: {
      alignItems: "center",
      justifyContent: "center",

      height: 48,
      width: 48,
      borderRadius: "8px",
    },
  },

  profileImageStyle: {
    component: "img",
    objectFit: "cover",
    height: 48,
    width: 48,
    borderRadius: "8px",
  },
  container: {
    backgroundColor: Colors.black,
    position: "sticky",
    top: 0,
    height: "100%",
    maxHeight: "100vh",
    width: 72,
    zIndex: 50,
  },
  mobileContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
  },
  mobileContainerRight: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
  },
  premiumContainer: {
    background: Colors.newPrimary,
    borderRadius: "3px",
    height: "16px",
    width: "max-content",
    p: "2px 5px",
    justifyContent: "center",
  },
  teamName: {
    color: Colors.white,
    fontSize: "8px",
    lineHeight: "12px",
  },
  hr: {
    border: `0.5px solid ${Colors.grayishBlue}`,
    borderBottom: "none",
    margin: "16px 0px",
    width: "100%",
  },
  navbar: {
    bgcolor: Colors.white,
    py: "24px",
    px: "16px",
    display: { xs: "flex", md: "none" },
    position: "sticky",
    top: 0,
    zIndex: 2,
    maxHeight: "72px",
    alignItems: "flex-start",
  },
};
