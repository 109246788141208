import { Box, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import AppDrawer from "../AppDrawer";
import { Colors } from "../../../themes";
import { useSelector } from "react-redux";
import { RegText, SemiBoldText } from "../../../styles";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import copy from "copy-to-clipboard";
import { Images } from "../../../assets";
import { trackEvent } from "../../../utils/functions";
const TeamProfileLink = ({ route, title, onClick, index }) => {
  const { selectedTeam } = useSelector((state) => state.user);
  const [copied, setCopied] = useState(false);

  const viewerLink = `${process.env.REACT_APP_WEBSITE_URL}viewer-invitation/${selectedTeam?.teamId}`;
  const handleCopyViewerLink = () => {
    copy(viewerLink);
    trackEvent("Team Members - Copy Viewer Link");
    if (!copied) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };
  if (title === "Members") {
    return (
      <>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <RegText className="!text-black" variant="body2" py={"10px"}>
              {title}
            </RegText>
          </AccordionSummary>
          <AccordionDetails className="bg-newPrimaryFadedTwo mx-4 rounded-md">
            <a href={route}>
              <RegText className="!text-black" variant="body2" py={1}>
                View All members
              </RegText>
            </a>
            <RegText
              className="!text-black"
              variant="body2"
              py={1}
              onClick={handleCopyViewerLink}
            >
              {copied ? "Copied" : "Copy Viewer Link"}
            </RegText>
          </AccordionDetails>
        </Accordion>
        <div className="px-4">
          <Divider sx={styles.teamProfileLinkDivider} />
        </div>
      </>
    );
  }
  return (
    <Stack
      component={Link}
      to={route}
      onClick={onClick}
      sx={styles.teamProfileLinkTitle}
      px={"16px"}
    >
      <RegText className="!text-black" variant="body2" py={"20px"}>
        {title}
      </RegText>
      {index !== 2 && <Divider sx={styles.teamProfileLinkDivider} />}
    </Stack>
  );
};

const PhoneTeamSetting = ({ open, setOpen, closeSideBar }) => {
  const { selectedTeam } = useSelector((state) => state.user);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleLinkClick = () => {
    setOpen(false);
    closeSideBar();
  };
  const renderTeamProfileLinks = () => {
    return [
      {
        route: `/teams/${selectedTeam?.teamId}/settings`,
        title: "Team Profile",
      },
      {
        route: `/teams/${selectedTeam?.teamId}/members`,
        title: "Members",
      },
      {
        route: `/teams/${selectedTeam?.teamId}/subscriptions`,
        title: "Subscriptions",
      },
    ].map((link, i) => (
      <TeamProfileLink
        key={i}
        route={link.route}
        title={link.title}
        onClick={handleLinkClick}
        index={i}
      />
    ));
  };
  return (
    <AppDrawer
      type="swipeable"
      appDrawerProps={{
        open,
        closeModal: handleCloseModal,
        anchor: "bottom",
        drawerWidth: "100%",
      }}
      style={{ backgroundColor: "transparent" }}
    >
      <Stack sx={styles.teamProfileContainer}>
        <Stack
          gap="16px"
          direction={"row"}
          className="bg-newPrimary py-6 px-4 rounded-t-[15px] items-center"
        >
          <img
            src={
              selectedTeam?.profileImage.length > 0
                ? selectedTeam?.profileImage
                : Images.userPlaceholder
            }
            className="object-cover h-16 w-16 rounded-lg"
          />
          <SemiBoldText variant="body1" sx={styles.teamProfileName}>
            {selectedTeam?.teamName}
          </SemiBoldText>
        </Stack>
        <Stack justifyContent="space-between" className="px-4">
          {renderTeamProfileLinks()}
        </Stack>
      </Stack>
    </AppDrawer>
  );
};

export default PhoneTeamSetting;
