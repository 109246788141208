import { Colors } from "../../../themes";

export const styles = {
  lastSessionContainer: { position: "absolute", bottom: 0, width: "90%" },
  divider: {
    border: `0.5px solid ${Colors.grayishBlue}`,
    borderBottom: "none",
    borderRight: "none",
    borderLeft: "none",
    width: "100%",
  },
  lastSession: { color: Colors.blackFaded80, my: "4px", fontSize: "11px" },
  iconButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: Colors.black,
  },
  projectCardContainer: {
    p: "4px 8px",
    position: "relative",
    height: "140px",
    borderRadius: "8px",
  },
  renameModal: {
    p: "24px 16px 32px 16px",
    backgroundColor: Colors.white,
    mt: 1,
    borderRadius: "16px 16px 0 0",
  },
};
