import React from "react";
import { Images } from "../../../assets";

const ToolSelector = ({ open, setOpen, selectedTool }) => {
  return (
    <button
      onClick={() => setOpen(!open)}
      className={
        "bg-white relative min-w-[138px] lg:min-w-[141px] pt-[15px] py-3 pl-6 pr-[7px] h-12 flex items-center leading-[24px] font-helvetica-medium text-[#686868]"
      }
    >
      {selectedTool}
      <div className="h-6 w-8 flex items-center justify-center">
        <img
          src={Images.OptionsLp}
          className="w-3 h-[5.62px] object- absolute right-4"
        />
      </div>
    </button>
  );
};

export default ToolSelector;
