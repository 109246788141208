import { Colors } from "../../../themes";

export const styles = {
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mx: "15px",
    pb: "70px",
  },
  logoBox: {
    mt: { xs: "64px", md: "40px" },
    height: { xs: "79px", md: "126px" },
    width: { xs: "79px", md: "126px" },
    sx: {
      objectFit: "contain",
      bgcolor: Colors.whiteFaded1,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
      p: "17px",
    },
  },
  welcomeText: {
    textAlign: "center",
    variant: "h5",
    sx: { color: Colors.black, mt: "40px", mb: "24px" },
  },
  descriptionText1: {
    variant: "body1",
    sx: {
      color: Colors.blackFaded60,
      display: { xs: "none", md: "block" },
      textAlign: "center",
    },
  },
  descriptionText2: {
    variant: "body2",
    sx: {
      color: Colors.white,
      display: { xs: "block", md: "none" },
      textAlign: "center",
    },
  },
  customBtn: {
    type: "primary",
    styles: {
      mt: "auto",
      width: { xs: "100%", md: "440px" },
    },
  },
  customBtnReject: {
    type: "secondary",
    styles: {
      mt: "24px",
      width: { xs: "100%", md: "440px" },
    },
  },
};
