export const privacyPolicy = `<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Last updated: Feb 2, 2024</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Interpretation and Definitions</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Interpretation</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Definitions</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For the purposes of this Privacy Policy:</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Account</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;means a unique account created for You to access our Service or parts of our Service.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Company</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;(referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Ellenox Pvt Ltd, the creators of Unboard.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Country</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to: Telangana, India</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Device</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">means any device that can access the Service such as a computer, a cellphone or a digital tablet.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">is any information that relates to an identified or identifiable individual.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Service</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to the Website</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Service Provider</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Usage Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Website</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to Unboard, accessible from</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">https://www.unboard.app</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">You</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Collecting and Using Your Personal DataTypes of Data Collected Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:Email addressFirst name and last namePhone numberAddress, State, Province, ZIP/Postal code, CityUsage Data</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Usage Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Usage Data is collected automatically when using the Service.Usage Data may include information such as Your Device&#39;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Tracking Technologies and Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Cookies or Browser Cookies.</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Web Beacons.</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. We use both Session and Persistent Cookies for the purposes set out below:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&zwj;</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Necessary / Essential Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Type: Session Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Administered by: Us</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Cookies Policy / Notice Acceptance Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Type: Persistent Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Administered by: Us</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Functionality Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Use of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The Company may use Personal Data for the following purposes:</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To provide and maintain our Service</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;including to monitor the usage of our Service.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To manage Your Account:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For the performance of a contract:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To contact You:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application&#39;s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To provide You</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To manage Your requests:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;To attend and manage Your requests to Us.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For business transfers</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For other purposes</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.We may share Your personal information in the following situations</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With Service Providers:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For business transfers:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With Affiliates:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With business partners:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share Your information with Our business partners to offer You certain products, services or promotions.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With other users:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With Your consent</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We may disclose Your personal information for any other purpose with Your consent.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Retention of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Transfer of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Your information, including Personal Data, is processed at the Company&#39;s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to &mdash; and maintained on &mdash; computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Delete Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.Our Service may give You the ability to delete certain information about You from within the Service.You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Disclosure of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Business Transactions</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Law enforcement</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Other legal requirements</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:Comply with a legal obligationProtect and defend the rights or property of the CompanyPrevent or investigate possible wrongdoing in connection with the ServiceProtect the personal safety of Users of the Service or the publicProtect against legal liability</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Security of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Children&#39;s Privacy</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent&#39;s consent before We collect and use that information.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Links to Other Websites</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party&#39;s site. We strongly advise You to review the Privacy Policy of every site You visit.We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Changes to this Privacy Policy</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Contact Us</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">If you have any questions about this Privacy Policy, You can contact us:</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">By email: contact@ellenox.com</span></span></span></p>

<p>&nbsp;</p>
`;

export const termsOfUse = `<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Last updated: Feb 2, 2024</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Interpretation and Definitions</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Interpretation</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Definitions</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For the purposes of this Terms &amp; Conditions:</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Account</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;means a unique account created for You to access our Service or parts of our Service.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Company</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;(referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Ellenox Pvt Ltd (the creators of Unboard)</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Country</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to: India</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Device</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">means any device that can access the Service such as a computer, a cellphone or a digital tablet.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">is any information that relates to an identified or identifiable individual.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Service</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to the Website</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Service Provider</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Usage Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Website</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">refers to Unboard, accessible from</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">https://www.unboard.app</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">You</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Collecting and Using Your Personal DataTypes of Data Collected Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:Email addressFirst name and last namePhone numberAddress, State, Province, ZIP/Postal code, CityUsage Data</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Usage Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Usage Data is collected automatically when using the Service.Usage Data may include information such as Your Device&#39;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Tracking Technologies and Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Cookies or Browser Cookies.</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Web Beacons.</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. We use both Session and Persistent Cookies for the purposes set out below:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&zwj;</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Necessary / Essential Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Type: Session Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Administered by: Us</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Cookies Policy / Notice Acceptance Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Type: Persistent Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Administered by: Us</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Functionality Cookies</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Use of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The Company may use Personal Data for the following purposes:</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To provide and maintain our Service</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;including to monitor the usage of our Service.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To manage Your Account:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For the performance of a contract:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To contact You:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application&#39;s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To provide You</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">To manage Your requests:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;To attend and manage Your requests to Us.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For business transfers</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For other purposes</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.We may share Your personal information in the following situations</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With Service Providers:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">For business transfers:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With Affiliates:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With business partners:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;We may share Your information with Our business partners to offer You certain products, services or promotions.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With other users:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">&nbsp;when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">With Your consent</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We may disclose Your personal information for any other purpose with Your consent.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Retention of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Transfer of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Your information, including Personal Data, is processed at the Company&#39;s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to &mdash; and maintained on &mdash; computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Delete Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.Our Service may give You the ability to delete certain information about You from within the Service.You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Disclosure of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Business Transactions</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Law enforcement</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Other legal requirements</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:Comply with a legal obligationProtect and defend the rights or property of the CompanyPrevent or investigate possible wrongdoing in connection with the ServiceProtect the personal safety of Users of the Service or the publicProtect against legal liability</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Security of Your Personal Data</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Children&#39;s Privacy</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent&#39;s consent before We collect and use that information.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Links to Other Websites</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party&#39;s site. We strongly advise You to review the Privacy Policy of every site You visit.We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Changes to this Terms &amp; Conditions</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">We may update Our Terms &amp; Conditions from time to time. We will notify You of any changes by posting the new Terms &amp; Conditions on this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Terms &amp; Conditions. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</span></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">Contact Us</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">If you have any questions about this Terms &amp; Conditions, You can contact us:</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Poppins,sans-serif"><span style="color:#000000">By email: contact@ellenox.com</span></span></span></p>

<p>&nbsp;</p>

<p>&nbsp;</p>
`;
export const TOOLS = [
  "ChatGPT",
  // "Midjourney",
  // "Claude",
  // "Perplexity",
  "Dall-e",
];
