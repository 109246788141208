import React, { useMemo, useState } from "react";
import CommonModal from "../CommonModal";
import InviteCollaborators from "../InviteCollaborators";
import PlanBox from "../PlanBox";
import { LightText, MedText, RegText } from "../../../styles";
import { useDispatch, useSelector } from "react-redux";
import { CopiedSvg, LinkSvg } from "../../../assets";
import CustomBtn from "../CustomBtn";
import copy from "copy-to-clipboard";
import { AnimatePresence, motion } from "framer-motion";
import Notification from "../Notification";
import { useEffect } from "react";
import { toggleNotification } from "../../../redux/appSlice";
import { capitalize } from "../../../helper/formatting";
import { getEditorSeats, getEmailLimit } from "../../../helper/validation";
import { trackEvent } from "../../../utils/functions";

const animateProps = {
  initial: { y: "100%" },
  animate: {
    y: "0%",
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  exit: {
    y: "1000%",
    transition: { duration: 0.5, ease: "easeInOut" },
  },
};

const MemberDetails = ({
  isEndOfScreen,
  setMembers,
  editors,
  setOpenInviteScreen,
  fetchMembers,
  pendingViewers,
  members,
  currentMembers,
}) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [emails, setEmails] = useState([]);
  const { selectedTeam } = useSelector((state) => state.user);
  const [copied, setCopied] = useState(false);
  const viewerLink = `${process.env.REACT_APP_WEBSITE_URL}viewer-invitation/${selectedTeam?.teamId}`;
  const editorSeats = getEditorSeats(selectedTeam?.plan);
  const dispatch = useDispatch();
  const emailLimit = getEmailLimit(selectedTeam?.plan);
  const editorInvitationMails = useMemo(
    () => [...editors, ...emails],
    [emails, editors]
  );
  const totalViewers = pendingViewers?.length
    ? [...selectedTeam?.viewers, ...pendingViewers]
    : selectedTeam?.viewers;

  const availableSeats = editorSeats - editors?.length;

  const handleCopyViewerLink = () => {
    copy(viewerLink);
    trackEvent("Team Members - Copy Viewer Link");
    if (!copied) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (editorInvitationMails.length >= emailLimit) {
      dispatch(
        toggleNotification({
          open: true,
          content: `${capitalize(selectedTeam?.plan)} Access only allows ${
            emailLimit - 1
          } editors`,
          type: "warning",
          action: () => {},
          // btnText: "Upgrade Plan",
        })
      );
    }
  }, [emails]);

  const getViewersText = () => {
    if (totalViewers?.length > 1) {
      return "s";
    }

    return "";
  };

  const renderPhoneTeamDetails = () => {
    return (
      <div className="fixed h-[225px] bottom-0 left-0 right-0 bg-newPrimaryFaded rounded-t-3xl px-6 pt-8 md:hidden z-50">
        <div className="flex justify-between">
          <PlanBox plan={selectedTeam?.plan} />
          <RegText className="text-black/80" variant="body2">
            {availableSeats < 0 ? 0 : availableSeats}/{editorSeats} editor
            invites left
          </RegText>
        </div>
        <div className="pt-4 flex flex-col gap-6 pb-[40px]">
          <CustomBtn
            type={"primary"}
            disabled={editorInvitationMails.length >= emailLimit - 1}
            onClick={() => {
              trackEvent("Team Members - Invite Editors");
              setOpenInviteScreen(true);
            }}
          >
            Invite Editors
          </CustomBtn>
          <CustomBtn type={"secondary"} onClick={handleCopyViewerLink}>
            {copied ? (
              <div className="flex items-center gap-2">
                <MedText variant="body1" className="text-newPrimary ">
                  Copied
                </MedText>
                <CopiedSvg />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <MedText variant="body1" className="text-newPrimary ">
                  Copy viewer link
                </MedText>
                <LinkSvg />
              </div>
            )}
          </CustomBtn>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="fixed top-0 right-0 w-full z-20">
        <Notification />
      </div>
      <div className="fixed bg-white bottom-[0px] left-[72px] right-0  border-t-[1px]  pb-4 pt-2.5 px-[52px] text-black justify-between hidden lg:flex z-50">
        <CommonModal
          open={openInviteModal}
          setOpen={setOpenInviteModal}
          heading="Invite Collaborators"
        >
          <InviteCollaborators
            emails={emails}
            setEmails={setEmails}
            setOpenInviteModal={setOpenInviteModal}
            openInviteModal={openInviteModal}
            selectedTeam={selectedTeam}
            isButtonDisabled={editorInvitationMails.length >= emailLimit}
            setMembers={setMembers}
            fetchMembers={fetchMembers}
            members={members}
            currentMembers={currentMembers}
          />
        </CommonModal>
        <div className="flex  gap-[80px] w-1/2">
          <div className="gap-2">
            <MedText variant="body1">
              {editors?.length} Editor
              {editors?.length > 1 && "s"}
            </MedText>
            <LightText
              variant="caption"
              className="!text-black/80 whitespace-nowrap"
            >
              {availableSeats < 0 ? 0 : availableSeats}/{editorSeats} empty
              seats
            </LightText>
          </div>

          <div className="flex gap-6">
            <div className="gap-2">
              <MedText variant="body1">
                {totalViewers?.length} Viewer
                {getViewersText()}
              </MedText>
              <LightText
                variant="caption"
                className="text-black/80 whitespace-nowrap"
              >
                Unlimited seats
              </LightText>
            </div>
            <button className="flex items-end" onClick={handleCopyViewerLink}>
              {copied ? (
                <div className="flex items-center gap-2">
                  <MedText
                    variant="body1"
                    className="!text-newPrimary bg-newPrimaryFaded px-2.5 rounded-lg"
                  >
                    Copied
                  </MedText>
                  <CopiedSvg />
                </div>
              ) : (
                <div className="flex items-center gap-2 bg-newPrimaryFaded px-2.5 rounded-lg">
                  <MedText
                    variant="body1"
                    className="text-newPrimary whitespace-nowrap"
                  >
                    Copy viewer link
                  </MedText>
                  <LinkSvg />
                </div>
              )}
            </button>
          </div>
        </div>
        <CustomBtn
          type={"primary"}
          onClick={() => {
            trackEvent("Team Members - Invite Editors");
            setOpenInviteModal(true);
          }}
        >
          Invite Editors
        </CustomBtn>
      </div>

      {renderPhoneTeamDetails()}
    </>
  );
};

export default MemberDetails;
