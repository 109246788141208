import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { MedText } from "../../../styles";
import { Divider, Stack } from "@mui/material";
import { Colors } from "../../../themes";
import { motion } from "framer-motion";

const SettingNav = () => {
  const { selectedTeam } = useSelector((state) => state.user);
  const location = useLocation();

  const navLinks = [
    {
      path: `/teams/${selectedTeam?.teamId}/settings`,
      text: "Team Profile",
    },
    {
      path: `/teams/${selectedTeam?.teamId}/members`,
      text: "Members",
    },
    {
      path: `/teams/${selectedTeam?.teamId}/subscriptions`,
      text: "Subscriptions",
    },
  ];

  return (
    <div className="text-black mb-6 hidden md:block">
      <nav className="">
        <Stack
          component={"ul"}
          className="flex pt-4 text-sm"
          gap="126px"
          direction="row"
        >
          {navLinks.map(({ path, text }) => {
            const active = location.pathname === path;
            return (
              <Stack component={Link} to={path} key={path} alignItems="center">
                <li className="mb-3">
                  <MedText
                    variant="body2"
                    className={active ? "!text-black" : "!text-black/60"}
                  >
                    {text}
                  </MedText>
                </li>
                {active && (
                  <motion.div layoutId="team-settings">
                    <Divider sx={{ bgcolor: Colors.black, width: "32px" }} />
                  </motion.div>
                )}
              </Stack>
            );
          })}
        </Stack>
      </nav>
    </div>
  );
};

export default SettingNav;
