import { Stack } from "@mui/material";
import React from "react";
import { RegText } from "../../../styles";
import { capitalize } from "../../../helper/formatting";
import { Colors } from "../../../themes";

const PlanBox = ({ plan }) => {
  return (
    <Stack sx={styles.premiumContainer}>
      <RegText sx={styles.teamName}>{capitalize(plan)}</RegText>
    </Stack>
  );
};

export default PlanBox;

const styles = {
  premiumContainer: {
    background: Colors.newPrimary,
    borderRadius: "3px",
    height: "17px",
    width: "max-content",
    p: "2px 5px",
    justifyContent: "center",
    mb: "0.5px",
  },
  teamName: {
    color: Colors.white,
    fontSize: "10px",
    lineHeight: "12px",
  },
};
