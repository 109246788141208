import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSubscriptionFeatures,
  fetchPricing,
  fetchProducts,
} from "./asyncThunk";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    menuLeftState: false,
    menuRightState: false,
    notification: {
      open: false,
      content: "",
      action: null,
      type: "",
      btnText: "",
    },
    subscriptionFeatures: [],
    pricing: [],
    loading: false,
    error: null,
    status: "pending",
    products: [],
    loadInitialLoader: true,
    totalEmails: [],
  },
  reducers: {
    toggleLeftMenu: (state, action) => {
      state.menuLeftState = action.payload;
    },
    toggleRightMenu: (state, action) => {
      state.menuRightState = action.payload;
    },
    toggleNotification: (state, action) => {
      state.notification = action.payload;
    },
    setSubscriptionFeatures: (state, action) => {
      state.subscriptionFeatures = action.payload;
    },
    setPricing: (state, action) => {
      state.pricing = action.payload;
    },
    setInitialLoader: (state, action) => {
      state.loadInitialLoader = action.payload;
    },
    setTotalEmails: (state, action) => {
      state.totalEmails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionFeatures.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubscriptionFeatures.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionFeatures = action.payload;
        state.status = "complete";
      })
      .addCase(fetchSubscriptionFeatures.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = "rejected";
      });
    builder
      .addCase(fetchPricing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPricing.fulfilled, (state, action) => {
        state.loading = false;
        state.pricing = action.payload;
        state.status = "complete";
      })
      .addCase(fetchPricing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = "rejected";
      });
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
        state.status = "complete";
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = "rejected";
      });
  },
});
export const {
  toggleLeftMenu,
  toggleRightMenu,
  toggleNotification,
  setSubscriptionFeatures,
  setPricing,
  setInitialLoader,
  setTotalEmails,
} = appSlice.actions;

export default appSlice.reducer;
