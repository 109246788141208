import { doc, firestoreDb, getDoc } from "../libraries/firebase";

export const getMixpanelKey = async () => {
  try {
    const docRef = doc(firestoreDb, "Settings", "settings");
    const result = await getDoc(docRef);

    return result.data().mixpanelKey;
  } catch (error) {
    console.log(error);
  }
};
