import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { CircularProgress } from "@mui/material";
import { Icons } from "../../../assets";
import { CssTextField, RegText } from "../../../styles";
import { Colors } from "../../../themes";

const onSubmitComponent = (loading) => {
  if (loading) {
    return (
      <CircularProgress
        sx={{ color: Colors.white }}
        color="success"
        size={15}
      />
    );
  }
  return (
    <img
      src={Icons.send}
      alt="send"
      className="w-[14px] h-[14px] hover:opacity-50"
    />
  );
};

const ChatInput = ({ onSend, loading, ...props }) => {
  return (
    <div
      className={`flex w-full items-center  rounded-xl  gap-2 ${props.containerStyles}`}
    >
      <CssTextField
        hiddenLabel
        id="outlined-basic"
        variant="standard"
        size="small"
        fullWidth
        disabled={loading}
        InputProps={{
          disableUnderline: true,
          sx: {
            pt: "5px",
          },
        }}
        multiline={true}
        {...props}
      />
      <button onClick={onSend}>{onSubmitComponent(loading)}</button>
    </div>
  );
};

export default ChatInput;
