import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MedText, RegText, SemiBoldText } from "../../../styles";
import { Colors } from "../../../themes";
import { AddChatSvg } from "../../../assets";
import { styles } from "./styles";
import Contributers from "../../common/Contributers";
import ViewersAndContributers from "../../common/ViewersAndContributers";
import CustomBtn from "../../common/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import ProjectCard from "../../common/ProjectCard";
import { Services } from "../../../services";
import {
  findWorkspaces,
  setSelectedProjectId,
  setWorkspaceData,
  setWorkspaceLoading,
} from "../../../redux/userSlice";
import CommonModal from "../../common/CommonModal";
import Input from "../../common/Input";
import AppDrawer from "../../common/AppDrawer";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../libraries/firebase";
import {
  checkStringLength,
  getUserRole,
  trackEvent,
} from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import { fetchWorkspaces } from "../../../redux/asyncThunk";

const WorkSpaces = () => {
  const navigate = useNavigate();
  const {
    projectsData,
    workspacesData,
    workSpaceSearchResults,
    workspaceSearchQuery,
    workspaceLoading,
    projectLoading,
  } = useSelector((state) => state.user);
  const { selectedTeam, selectedProjectId } = useSelector(
    (state) => state.user
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateWorkspaceOpen, setIsCreateWorkspaceOpen] = useState(false);
  const [newWorkSpaceName, setNewWorkSpaceName] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [currentProjectId, setCurrentProjectId] = useState("");

  const dispatch = useDispatch();
  const currentProject = projectsData?.find(
    (item) => item.id === selectedProjectId
  );

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [user] = useAuthState(auth);
  const userRole = getUserRole(user?.uid, selectedTeam);

  useEffect(() => {
    const prevProjectId = localStorage.getItem("prevProjectId");

    const currentProjectId = projectsData[projectsData.length - 1]?.id;

    if (prevProjectId !== currentProjectId) {
      dispatch(setSelectedProjectId(currentProjectId));
    }

    localStorage.setItem("prevProjectId", currentProjectId);
  }, [selectedTeam, projectsData, dispatch]);

  const loadData = async () => {
    setCurrentProjectId(selectedProjectId);
    const res = await dispatch(
      fetchWorkspaces({
        selectedTeamId: selectedTeam?.teamId,
        selectedProjectId,
      })
    );
  };
  useEffect(() => {
    if (selectedTeam && !projectsData?.length && !projectLoading) {
      dispatch(setWorkspaceData([]));
      dispatch(setWorkspaceLoading(false));

      return;
    }
    if (!selectedTeam || !projectsData || !selectedProjectId) return;
    if (currentProjectId !== selectedProjectId) {
      loadData();
    }
  }, [selectedTeam, projectsData, dispatch, selectedProjectId, refetch]);
  useEffect(() => {
    if (newWorkSpaceName.length > 25) {
      setErrorText("File name should be less than 25 characters.");
      setTimeout(() => {
        setErrorText("");
      }, 2000);
      return;
    }
    if (workspacesData.find((item) => item.name === newWorkSpaceName)) {
      setErrorText("File name already exists.");
      setTimeout(() => {
        setErrorText("");
      }, 2000);
      return;
    }
  }, [newWorkSpaceName]);
  const createWorkSpace = async () => {
    if (newWorkSpaceName === "") return;
    if (newWorkSpaceName.trim().length >= 25) {
      return;
    }
    if (!selectedProjectId || !selectedTeam) return;

    if (!newWorkSpaceName.trim()) {
      setErrorText("File name cannot be empty.");
      setTimeout(() => {
        setErrorText("");
      }, 2000);
      return;
    }
    setIsLoading(true);
    const res = await Services.WorkSpacesServices.createWorkspace(
      selectedTeam?.teamId,
      selectedProjectId,
      newWorkSpaceName?.trim()
    );
    trackEvent("Workspace - New File", {
      fileName: newWorkSpaceName,
    });
    setIsCreateWorkspaceOpen(false);
    setIsLoading(false);
    setRefetch((oldValue) => !oldValue);
    if (res) {
      navigate(`/teams/${selectedTeam?.teamId}/r/${res}`);
    }
  };

  const renderCreateWorkspaceBtn = () => {
    if (!userRole) return <></>;
    if (userRole === "contributer" || userRole === "admin") {
      return (
        <CustomBtn
          type="outlined"
          onClick={() => setIsCreateWorkspaceOpen(true)}
        >
          Create a New File
        </CustomBtn>
      );
    }
  };

  const getWorkspaces = () => {
    if (workspaceLoading) {
      return (
        <Stack sx={styles.projectsStack}>
          <Grid spacing={3} container className="!mt-6">
            {new Array(9).fill(0).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div className="h-[140px] bg-newPrimaryFadedTwo border-grayBlueFaded rounded-[8px] animate-pulse"></div>
              </Grid>
            ))}
          </Grid>
        </Stack>
      );
    }
    if (projectsData?.length === 0) {
      return (
        <Stack
          sx={{
            ...styles.projectsStack,
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
          gap={4}
        >
          <RegText variant="body1" sx={{ color: Colors.black }}>
            {userRole !== "contributer" || userRole !== "admin"
              ? "This team has no projects."
              : "Create a new project to get started."}
          </RegText>
        </Stack>
      );
    }

    if (!workspacesData || workspacesData?.length === 0) {
      return (
        <Stack
          sx={{
            ...styles.projectsStack,
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
          gap={4}
        >
          <RegText
            variant="body1"
            sx={{ color: Colors.black, textAlign: "center" }}
          >
            This Project doesn't have any Files.
          </RegText>
          {renderCreateWorkspaceBtn()}
        </Stack>
      );
    }

    const projectTitle = currentProject?.name;
    if (workspaceSearchQuery && workSpaceSearchResults.length === 0)
      return (
        <Stack sx={styles.projectsStack}>
          <SemiBoldText
            variant="body1"
            sx={{ color: Colors.blackFaded60, mb: "10px" }}
            textAlign={"center"}
            mt="38px"
          >
            No File matching
            <SemiBoldText
              component="span"
              variant="body1"
              sx={{ color: Colors.black }}
              ml="5px"
            >
              {workspaceSearchQuery?.length > 25
                ? workspaceSearchQuery?.slice(0, 25) + "..."
                : workspaceSearchQuery}
            </SemiBoldText>
          </SemiBoldText>
        </Stack>
      );
    return (
      <Stack sx={styles.projectsStack}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb="24px"
        >
          <MedText sx={styles.projectTitle} variant="body1">
            {checkStringLength(projectTitle, 20)}
          </MedText>
          {userRole !== "viewer" && (
            <Tooltip title="Create Workspace" arrow placement="top">
              <IconButton
                onClick={() => setIsCreateWorkspaceOpen(true)}
                sx={{ display: { xs: "flex", md: "none" } }}
              >
                <AddChatSvg
                  style={styles.addChatSvg}
                  stroke={Colors.newPrimary}
                />
              </IconButton>
            </Tooltip>
          )}
          {userRole !== "viewer" && (
            <Stack
              sx={styles.newWorkSpace}
              component="button"
              onClick={() => setIsCreateWorkspaceOpen(true)}
            >
              <AddChatSvg
                style={styles.addChatSvg}
                stroke={Colors.newPrimary}
              />
              <MedText variant="body2" sx={{ color: Colors.newPrimary }}>
                New File
              </MedText>
            </Stack>
          )}
        </Stack>

        <Grid spacing={3} container>
          {workspacesData?.map((workspace, i) => (
            <ProjectCard
              index={i}
              key={workspace.key}
              name={workspace.name}
              sessionTime={workspace.lastSession}
              id={workspace.key}
              setRefetch={setRefetch}
            />
          ))}
        </Grid>
      </Stack>
    );
  };

  const renderModal = ({ heading, open, setOpen, children, onSubmit }) => {
    const closeAppDrawer = () => {
      setOpen(false);
      setNewWorkSpaceName("");
      setErrorText("");
    };

    if (isSmallScreen) {
      return (
        <>
          <AppDrawer
            type="swipeable"
            style={{ backgroundColor: "transparent" }}
            appDrawerProps={{
              open,
              anchor: "bottom",
              drawerWidth: "100%",
              closeModal: closeAppDrawer,
            }}
          >
            <Stack
              sx={{
                p: "24px 16px 32px 16px",
                backgroundColor: Colors.white,
                mt: 1,
                borderRadius: "16px 16px 0 0",
              }}
              gap="70px"
            >
              {children}

              <CustomBtn
                type="primary"
                onClick={onSubmit}
                disabled={isLoading || !newWorkSpaceName?.length}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: Colors.white }} size={30} />
                ) : (
                  "Save"
                )}
              </CustomBtn>
            </Stack>
          </AppDrawer>
        </>
      );
    }

    const btnText = heading === "Create a new file" ? "Create" : "Save";

    return (
      <CommonModal heading={heading} open={open}>
        {children}
        <Stack gap="16px" mt="40px">
          <CustomBtn type="primary" onClick={onSubmit} disabled={isLoading}>
            {isLoading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              btnText
            )}
          </CustomBtn>
          <CustomBtn type="secondary" onClick={closeAppDrawer}>
            Cancel
          </CustomBtn>
        </Stack>
      </CommonModal>
    );
  };

  const renderCreateWorkspaceModal = () => {
    let details = {
      backgroundColor: "",
      placeholder: "Enter file name",
    };

    if (isSmallScreen) {
      details = {
        backgroundColor: "transparent",
        placeholder: "File name",
      };
    }
    return renderModal({
      heading: "Create a new file",
      open: isCreateWorkspaceOpen,
      setOpen: setIsCreateWorkspaceOpen,
      children: (
        <Stack>
          <Input
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 25) {
                setNewWorkSpaceName(inputValue);
                setErrorText(
                  inputValue.length >= 25
                    ? "File name should be less than 25 characters."
                    : ""
                );
              }
            }}
            value={newWorkSpaceName}
            style={{
              backgroundColor: details.backgroundColor,
              width: "100%",
            }}
            placeholder={details.placeholder}
          />

          <RegText className="text-red !text-sm !mt-4 h-4">{errorText}</RegText>
        </Stack>
      ),
      onSubmit: createWorkSpace,
    });
  };

  return (
    <Stack direction="row" sx={{ position: "relative", flex: 1 }}>
      <Box sx={styles.projectsBox}>
        <Contributers />
      </Box>
      {getWorkspaces()}
      <Divider sx={styles.dividerScreen} orientation="vertical" />
      <Box sx={styles.projectsBox}>
        <ViewersAndContributers />
      </Box>
      {renderCreateWorkspaceModal()}
    </Stack>
  );
};

export default WorkSpaces;
