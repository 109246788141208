import React from "react";
import { Colors } from "../../../themes";
import { Button, Stack } from "@mui/material";
import { MedText } from "../../../styles";

const getDisabledBgColor = (type) => {
  if (type === "primary") return Colors.newPrimary;
};

const getDisabledTextColor = (type) => {
  if (type === "primary") return Colors.white;
  if (type === "error") return Colors.whiteFaded50;
};

const getDisabledOpacity = (type) => {
  if (type === "primary" || type === "error" || type === "outlined") return 0.5;

  if (type === "secondary") return 0.5;

  return 1;
};

const getBtnStyles = (customStyles, type, disabled) => {
  const commonStyles = {
    backgroundColor: disabled
      ? getDisabledBgColor(type)
      : Colors.primaryBtnColor,
    color: Colors.white,
    textTransform: "inherit",
    opacity: disabled ? getDisabledOpacity(type) : 1,
    height: "48px",
    p: "16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: disabled
        ? getDisabledTextColor(type)
        : Colors.primaryBtnColor,
    },
    ...customStyles,
  };

  const typeSpecificStyles = {
    primary: {
      "&:hover": {
        backgroundColor: Colors.primaryBtnColor,
        color: Colors.white,
      },
    },
    secondary: {
      backgroundColor: `transparent`,
      color: Colors.black,
      "&:hover": {
        backgroundColor: `transparent`,
        color: Colors.black,
      },
    },
    error: {
      backgroundColor: Colors.errorColor,
      color: Colors.white,
      "&:hover": {
        backgroundColor: Colors.errorColor,
        color: Colors.white,
      },
    },
    outlined: {
      backgroundColor: "transparent",
      color: Colors.black,
      border: `1px solid ${Colors.black}`,
      "&:hover": {
        backgroundColor: "transparent",
        color: Colors.black,
      },
    },
    delete: {
      backgroundColor: "transparent",
      color: Colors.errorColorText,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  };

  return { ...commonStyles, ...typeSpecificStyles[type] };
};

const CustomBtn = ({ children, type, styles, onClick, disabled }) => {
  const btnStyles = getBtnStyles(styles, type, disabled);

  return (
    <Stack
      component={Button}
      sx={btnStyles}
      onClick={onClick}
      disabled={disabled}
    >
      <MedText
        sx={{
          lineHeight: "16.8px",
          color: disabled && getDisabledTextColor(type),
        }}
      >
        {children}
      </MedText>
    </Stack>
  );
};

export default CustomBtn;
