import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { getProducts } from "@stripe/firestore-stripe-payments";

import { firestoreDb } from "../libraries/firebase";
import { payments } from "../libraries/stripe";
import { Services } from "../services";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (userId) => {
    try {
      const userData = await getDoc(doc(firestoreDb, "Users", userId));
      return { ...userData.data(), uid: userId, exist: userData.exists() };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSubscriptionFeatures = createAsyncThunk(
  "app/fetchSubscriptionFeatures",
  async () => {
    try {
      const docRef = doc(firestoreDb, "Settings", "features");
      const result = await getDoc(docRef);
      return result.data();
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPricing = createAsyncThunk("app/fetchPricing", async () => {
  try {
    const docRef = doc(firestoreDb, "Settings", "pricing");
    const result = await getDoc(docRef);
    return result.data()?.pricing;
  } catch (error) {
    throw error;
  }
});

export const fetchProducts = createAsyncThunk("app/fetchProducts", async () => {
  try {
    let productList = [];
    const products = await getProducts(payments, {
      includePrices: true,
      activeOnly: true,
    });
    for (const product of products) {
      productList.push(product);
    }

    return productList;
  } catch (error) {
    throw error;
  }
});
export const fetchWorkspaces = createAsyncThunk(
  "app/fetchWorkspaces",
  async ({ selectedTeamId, selectedProjectId }, { rejectWithValue }) => {
    if (!selectedTeamId || !selectedProjectId) return;
    let workspacesData = [];
    try {
      await Services.WorkSpacesServices.getWorkspaces(
        selectedTeamId,
        selectedProjectId,
        (workspacesItem) => {
          workspacesData = workspacesItem;
        }
      );
      return workspacesData;
    } catch (error) {
      console.log(error);
    }
  }
);
