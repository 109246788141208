import { query } from "firebase/database";
import {
  Timestamp,
  collection,
  deleteTeamMember,
  doc,
  firestoreDb,
  getDoc,
  getDocs,
  updateDoc,
  where,
  deleteDoc,
} from "../libraries/firebase";
import { changeTeamMembersRole } from "../libraries/firebase";
import moment from "moment";
import { trackEvent } from "../utils/functions";

export async function teamModerationMember(teamId) {
  try {
    const teamModerationDoc = await getDoc(
      doc(firestoreDb, `TeamsModerations/${teamId}`)
    );
    return teamModerationDoc.data();
  } catch (err) {
    console.error(err);
  }
}

export async function pendingMember(teamId) {
  try {
    const pendingInvitationsQuery = query(
      collection(firestoreDb, "Invitations"),
      where("teamId", "==", teamId),
      where("status", "==", "Sent"),
      where(
        "createdAt",
        ">=",
        moment(moment.now()).subtract(24, "hours").toDate()
      )
    );
    return await getDocs(pendingInvitationsQuery);
  } catch (err) {
    console.log(err);
  }
}

export async function deleteSelectedMembers(teamId, selectedMembers) {
  try {
    const teamModerationDoc = await getDoc(
      doc(firestoreDb, `TeamsModerations/${teamId}`)
    );
    const teamModeration = doc(firestoreDb, `TeamsModerations/${teamId}`);

    const teamModerationData = teamModerationDoc.data();

    const newUserIds = teamModerationData.userIds.filter(
      (user) => !selectedMembers.includes(user)
    );

    const newAdmins = teamModerationData.admin.filter(
      (admin) => !selectedMembers.includes(admin.userId)
    );
    const newContributers = teamModerationData.contributers.filter(
      (contributer) => !selectedMembers.includes(contributer.userId)
    );
    const newViewers = teamModerationData.viewers.filter(
      (viewer) => !selectedMembers.includes(viewer.userId)
    );

    await updateDoc(teamModeration, {
      admin: newAdmins,
      contributers: newContributers,
      viewers: newViewers,
      userIds: newUserIds,
    });
  } catch (err) {
    console.error(err);
  }
}

export const updatePendingUserRole = async (pendingUserId, role) => {
  try {
    const invitationDocRef = doc(firestoreDb, `Invitations`, pendingUserId);
    await updateDoc(invitationDocRef, {
      role,
    });
  } catch (err) {
    console.error(err);
  }
};

export async function updateRole(teamMemberIds = [], role, teamId) {
  try {
    const res = await changeTeamMembersRole({
      teamMemberIds: teamMemberIds,
      role,
      teamId,
    });
    return res?.data?.response;
  } catch (err) {
    console.error(err);
  }
}

export const removePendingUser = async (invitationDocId) => {
  try {
    const invitationDocRef = doc(firestoreDb, `Invitations`, invitationDocId);
    await updateDoc(invitationDocRef, {
      status: "cancelled",
    });
    trackEvent("Team Members (Pending Invite) - Cancel Invite");
  } catch (err) {
    console.error(err);
  }
};

export async function deleteMember(memberId, teamId, permission) {
  try {
    await deleteTeamMember({
      memberId,
      teamId,
      permission,
    });
  } catch (err) {
    console.error(err);
  }
}
