import { alpha, styled } from "@mui/material/styles";
import { Menu, TextField, Typography } from "@mui/material";
import { Colors } from "../themes";

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#727272",
  },
  "& .MuiOutlinedInput-root": {
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& fieldset": {
      borderColor: "#727272",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "#727272",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#727272",
    },
  },
});
export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme, width, marginTop }) => ({
  "& .MuiPaper-root": {
    borderRadius: "8px",
    width: width || "172px",
    backgroundColor: Colors.white,
    color: Colors.black,
    boxShadow: `0px 4px 56px ${Colors.blackFaded35}`,
    marginTop: marginTop || "0px",
  },
}));

export const LightText = styled(Typography)({
  fontFamily: `'Poppins', sans-serif`,
  fontWeight: 300,
});
export const RegText = styled(Typography)({
  fontFamily: `'Poppins', sans-serif`,
  fontWeight: 400,
});
export const MedText = styled(Typography)({
  fontFamily: `'Poppins', sans-serif`,
  fontWeight: 500,
});
export const SemiBoldText = styled(Typography)({
  fontFamily: `'Poppins', sans-serif`,
  fontWeight: 600,
});
export const BoldText = styled(Typography)({
  fontFamily: `'Poppins', sans-serif`,
  fontWeight: 700,
});
