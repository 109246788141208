import Input from "../../common/Input";
import CustomBtn from "../../common/CustomBtn";
import ProfilePic from "../../common/ProfilePic";
import Banner from "../../../assets/images/banner.png";
import Logo from "../../../assets/icons/logo.png";
import {
  ContactUsSvg,
  EditThreeSvg,
  Images,
  LoginCircleSvg,
  UnboardLogoSvg,
} from "../../../assets";
import { styles } from "./styles";
import { useState, useRef, useReducer, useEffect } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import { BoldText, MedText, RegText, SemiBoldText } from "../../../styles";
import { doc, setDoc } from "firebase/firestore";
import { firestoreDb, storage, storageRef } from "../../../libraries/firebase";
import { getDownloadURL, uploadString } from "firebase/storage";
import { auth } from "../../../libraries/firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../../themes";
import { setUserProfile } from "../../../redux/userSlice";
import { trackEvent } from "../../../utils/functions";
const formFields = {
  firstName: "",
  lastName: "",
  displayName: "",
  email: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_FIRST_NAME":
      return { ...state, firstName: action.payload };
    case "SET_LAST_NAME":
      return { ...state, lastName: action.payload };
    case "SET_DISPLAY_NAME":
      return { ...state, displayName: action.payload };
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
const SignupScreen = () => {
  const [image, setImage] = useState("");
  const [imageError, setImageError] = useState("");
  const [state, dispatch] = useReducer(reducer, formFields);
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  const { data: userData } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();

  useEffect(() => {
    if (user && userData.exist && !user?.isAnonymous) {
      navigate("/");
    }
  }, [user, userData]);
  const imageUpload = async () => {
    try {
      const imageRef = storageRef(storage, `Users/${user?.uid}/profile`);
      const uploadedImage = await uploadString(imageRef, image, "data_url");
      const downloadUrl = await getDownloadURL(uploadedImage.ref);
      return downloadUrl;
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmitData = async () => {
    setLoading(true);
    trackEvent("Sign-up - Create Account", {
      email: user?.email,
    });
    const profileImage = await imageUpload();
    const userData = {
      firstName: state.firstName,
      lastName: state.lastName,
      displayName: state.displayName,
      email: user?.email,
      profileImage: profileImage || "",
    };
    setDoc(doc(firestoreDb, "Users", user?.uid), userData)
      .then(() => dispatchRedux(setUserProfile({ ...userData, exist: true })))
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        if (localStorage.getItem("openViwerInvitationAfterSignIn")) {
          navigate(
            `/viewer-invitation/${JSON.parse(
              localStorage.getItem("openViwerInvitationAfterSignIn")
            )}`
          );
          return;
        }
        navigate("/");
      });
  };
  function handleFormInput(event) {
    const { name, value } = event.target;
    switch (name) {
      case "firstName":
        dispatch({ type: "SET_FIRST_NAME", payload: value });
        break;
      case "lastName":
        dispatch({ type: "SET_LAST_NAME", payload: value });
        break;
      case "displayName":
        dispatch({ type: "SET_DISPLAY_NAME", payload: value });
        break;
      case "email":
        dispatch({ type: "SET_EMAIL", payload: value });
        break;
      default:
        break;
    }
  }

  const buttonIsDisabled =
    !state.displayName.trim() ||
    !state.lastName.trim() ||
    !state.firstName.trim();

  const handleContactUs = () => {
    window.location.href = "https://www.ellenox.com/contact";
  };

  return (
    <div className="relative h-[100dvh] flex-col overflow-hidden   md:bg-newPrimary flex flex-1">
      <div className="bg-white hidden md:block absolute h-[150vh] w-[100%] rounded-full right-[-50%] top-[50%] -translate-y-[50%] z-10"></div>
      <img
        src={Banner}
        alt="banner"
        className="absolute bottom-0 w-[66.5%] hidden md:block z-20"
      />
      <img
        src={Images.bannerSmaller}
        alt="banner"
        className="absolute bottom-0 right-0 left-0 block md:hidden"
      />

      <Stack
        sx={styles.navbar}
        className="h-[72px] md:h-[60px] md:mt-[14px] mb-6 md:mb-0 shadow-md md:shadow-none max-w-[83rem] mx-auto w-full z-50 relative"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap="9px"
          className="text-newPrimary md:text-white "
        >
          <UnboardLogoSvg
            fill="currentColor"
            className="h-[19px] md:h-[26px] "
          />
        </Stack>
        <div className="flex" onClick={handleContactUs}>
          <SemiBoldText
            component={"a"}
            href={`${process.env.REACT_APP_ELLENOX_WEBSITE}`}
            variant="body2"
            target="_blank"
            display={{ xs: "none", md: "block" }}
            className="text-newPrimary"
          >
            Contact us
          </SemiBoldText>
          <div className="block md:hidden text-newPrimary mr-4">
            <ContactUsSvg fill="currentColor" />
          </div>
        </div>
      </Stack>
      <div className="text-white relative lg:max-w-[75rem] mx-auto w-full justify-end z-50 flex flex-1">
        <Stack
          direction="row"
          justifyContent={{ xs: "center", lg: "space-between" }}
          marginX={{ xs: "15px", lg: "0px" }}
          className="mt-4 md:mt-[70px] "
        >
          <div className="md:w-[440px] xs:mx-auto  ml-auto flex flex-col flex-1">
            <SemiBoldText sx={styles.createAccountText}>
              Create account
            </SemiBoldText>
            <div className="flex justify-center flex-col items-center gap-5">
              <ProfilePic
                image={image}
                setImage={setImage}
                setImageError={setImageError}
              />
              {imageError && (
                <RegText
                  sx={{
                    color: Colors.errorColor,
                    fontSize: "14px",
                  }}
                >
                  {imageError}
                </RegText>
              )}
            </div>
            <Stack component="form" gap={"16px"} mt={"40px"} className="h-full">
              <div className="flex w-full gap-2.5 justify-between">
                <Input
                  placeholder="First name"
                  name="firstName"
                  type="text"
                  value={state.firstName}
                  onChange={handleFormInput}
                  style={{ width: "100%" }}
                />
                <Input
                  placeholder="Last name"
                  name="lastName"
                  type="text"
                  value={state.lastName}
                  onChange={handleFormInput}
                  style={{ width: "100%" }}
                />
              </div>
              <Input
                placeholder="Display name"
                name="displayName"
                type="text"
                value={state.displayName}
                onChange={handleFormInput}
              />
              <Input
                placeholder="Email"
                name="email"
                type="email"
                value={user?.email}
                disabled={true}
              />

              <div className="flex flex-col mt-auto mb-[10%]">
                <CustomBtn
                  type={"primary"}
                  onClick={handleSubmitData}
                  disabled={loading || buttonIsDisabled}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: Colors.white }} size={30} />
                  ) : (
                    "Create Account"
                  )}
                </CustomBtn>
              </div>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default SignupScreen;
