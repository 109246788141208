import React from "react";
import { getNewThreadStyle } from "../SideBar";

const SideBarItem = ({
  containerStyle,
  onPress,
  icon,
  iconStyle,
  heading,
  activeStyle,
  subthreads,
  currentThreadId,
  onChangeThread,
  setCreatingThread,
  customStyle,
}) => {
  return (
    <>
      <button
        onClick={onPress}
        className={`flex items-center h-10 border rounded-md border-newPrimary  ${containerStyle}`}
        style={customStyle}
      >
        {icon && <img src={icon} alt="user" className={iconStyle} />}
        <p
          className={`text-newPrimary text-sm font-medium ml-4 overflow-hidden whitespace-nowrap ${activeStyle}`}
        >
          {heading} 
        </p>
      </button>
      {subthreads?.length > 0 &&
        subthreads?.map((subItem) => (
          <SideBarItem
            key={subItem?.id}
            containerStyle={`${
              getNewThreadStyle(currentThreadId, subItem?.id).containerStyle
            } `}
            icon={getNewThreadStyle(currentThreadId, subItem?.id).icon}
            iconStyle={"w-3 h-3"}
            activeStyle={
              getNewThreadStyle(currentThreadId, subItem?.id).activeStyle
            }
            heading={subItem?.name}
            currentThreadId={currentThreadId}
            checkId={subItem.id}
            onPress={() => {
              onChangeThread(subItem?.id);
              setCreatingThread(false);
            }}
            subthreads={subItem?.subthreads}
            onChangeThread={onChangeThread}
            setCreatingThread={setCreatingThread}
            customStyle={{
              marginLeft: subItem?.level * 50,
            }}
          />
        ))}
    </>
  );
};

export default SideBarItem;
