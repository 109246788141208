import React from "react";
import { Images, UnboardLogoSvg } from "../../../assets";
import { SemiBoldText } from "../../../styles";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const Loading = () => {
  const { loadInitialLoader } = useSelector((state) => state.app);

  const renderLoaderPrimary = () => (
    <div className="h-screen w-full flex items-center justify-center bg-[#fcfcfc] absolute top-0 bottom-0 left-0 right-0 z-[999999]">
      <img src={Images.loader} alt="loader" className="w-[100px] h-[100px]" />
    </div>
  );
  if (!loadInitialLoader) {
    return <>{renderLoaderPrimary()}</>;
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.6, ease: "circIn" },
      }}
      className="overflow-hidden absolute h-screen w-full !bg-newPrimary z-[999999] "
    >
      <div className="loading-screen-linear-gradient absolute top-0 right-0 left-0 bottom-0 z-50"></div>
      <img
        src={Images.bannerLoader}
        className="absolute bottom-0 h-full w-full right-0 left-0 top-0 object-cover hidden md:block"
        alt="loader"
      />
      <img
        src={Images.bannerLoaderSmaller}
        className="absolute bottom-0 h-full w-full right-0 left-0 top-0 object-cover block md:hidden"
        alt="loader"
      />
      <div className="relative z-[100] flex flex-col items-center md:justify-center h-full w-full">
        <UnboardLogoSvg
          fill="white"
          className="mt-[86px] md:mt-0 h-[27px] md:h-[99px] w-[102px] md:w-[212px]"
        />
        <SemiBoldText className="!text-[18px] md:!text-[36px] !mt-5 md:!mt-10 text-white">
          Experience the power of{" "}
        </SemiBoldText>
        <SemiBoldText className="!text-[28px] md:!text-[56px] text-white">
          collaboration with AI{" "}
        </SemiBoldText>
      </div>
    </motion.div>
  );
};

export default Loading;
