import { Box, Modal, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MedText, RegText } from "../../../styles";
import { Colors } from "../../../themes";
import { useMediaQuery } from "@mui/material";
const CommonModal = ({ children, heading, subHeading, open, setOpen }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [modalPosition, setModalPosition] = useState({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  });
  const currentPath = window.location.href;
  const conditionalZ = currentPath.includes("/members") ? 50 : 9999;
  useEffect(() => {
    if (!isSmallScreen) return;
    const handleKeyboardOpen = () => {
      setModalPosition({
        top: "18%",
        left: "50%",
        transform: "translate(-50%, -20%)",
      });
    };

    const handleKeyboardClose = () => {
      setModalPosition({
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      });
    };

    window.addEventListener("resize", handleKeyboardClose);
    window.addEventListener("keydown", handleKeyboardOpen);

    return () => {
      window.addEventListener("keydown", handleKeyboardOpen);
      window.removeEventListener("resize", handleKeyboardClose);
    };
  }, []);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ zIndex: conditionalZ }}
    >
      <Box sx={[style.modalBox, modalPosition]}>
        <MedText variant="h6" sx={style.heading}>
          {heading}
        </MedText>
        <hr style={style.separator} />
        <Stack sx={style.stack}>
          {subHeading && (
            <RegText sx={style.subHeading} variant="body2">
              {subHeading}
            </RegText>
          )}
          {children}
        </Stack>
      </Box>
    </Modal>
  );
};

export default CommonModal;
const style = {
  modalBox: {
    position: "absolute",
    width: { xs: "95%", md: "583px" },
    bgcolor: Colors.white,
    borderRadius: "8px",
    outline: "none",
  },
  heading: {
    color: Colors.black,
    py: "21px",
    px: "24px",
  },
  separator: {
    borderBottom: "none",
    borderRight: "none",
    borderLeft: "none",
    border: `0.5px solid ${Colors.grayishBlue}`,
  },
  subHeading: {
    color: Colors.blackFaded80,
    mb: "40px",
  },
  stack: {
    m: "20px 24px",
  },
};
