import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  useMediaQuery,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { nanoid } from "@reduxjs/toolkit";

import { MedText, RegText } from "../../../styles";
import { Colors } from "../../../themes";
import { Services } from "../../../services";
import CustomBtn from "../CustomBtn";
import { CloseSvg } from "../../../assets";
import { styles } from "./styles";
import Select from "../Select";
import { auth } from "../../../libraries/firebase";
import { validateEmail } from "../../../helper/validation";
import { useDispatch, useSelector } from "react-redux";
import { pendingMember, teamModerationMember } from "../../../services/member";
import { setTotalEmails, toggleNotification } from "../../../redux/appSlice";
import {
  isArray1DisjointFromArray2,
  trackEvent,
} from "../../../utils/functions";

const InviteCollaborators = ({
  setOpenInviteModal,
  emails,
  setEmails,
  type,
  team,
  isButtonDisabled = false,
  setMembers,
  fetchMembers,
  members,
  currentMembers,
}) => {
  const [user] = useAuthState(auth);
  const [emailInputs, setEmailInputs] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [send, setSend] = useState(false);
  const { selectedTeam } = useSelector((state) => state.user);
  const { totalEmails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const currentTeam = team ?? selectedTeam;

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  let inputWidth = "85%";

  if (isSmallScreen) {
    inputWidth = "75%";
  }

  const handleEmails = async (e) => {
    if (e.key.toLowerCase() !== "enter" && e.key !== ",") return;
    setError("");

    const newEmailInputs = emailInputs.trim();
    if (newEmailInputs === "") return;

    const emailList = newEmailInputs
      .split(",")
      .map((email) => email.trim())
      .filter((email) => email !== "");

    const uniqueEmailList = [...new Set(emailList)];

    const invalidEmails = uniqueEmailList.filter(
      (email) => !validateEmail(email, setError)
    );

    if (invalidEmails.length > 0) {
      setError("Invalid email : " + invalidEmails.join(", "));
      return;
    }

    const newEmails = uniqueEmailList.map((email) => ({
      email,
      role: "contributer",
      id: nanoid(),
    }));

    const existingEmails = emails.map((item) => item.email.toLowerCase());

    const teamModerationData = await teamModerationMember(currentTeam?.teamId);

    const pendingInvitations = await pendingMember(currentTeam?.teamId);

    const allEmails = new Set([]);

    function addMembersByRole(mems = []) {
      mems.forEach(async function (m) {
        allEmails?.add(m.email);
      });
    }

    addMembersByRole(teamModerationData?.admin);
    if (teamModerationData?.viewers?.length) {
      addMembersByRole(teamModerationData?.viewers);
    }
    if (teamModerationData?.contributers?.length) {
      addMembersByRole(teamModerationData?.contributers);
    }

    pendingInvitations?.forEach(function (inviteRef) {
      const invite = inviteRef.data();
      allEmails?.add(invite.recipientEmail);
    });

    const newUniqueEmails = newEmails.filter(
      (item) => !allEmails.has(item.email.toLowerCase())
    );

    const uniqueEmails = newEmails.filter(
      (item) => !existingEmails.includes(item.email.toLowerCase())
    );

    if (
      newUniqueEmails.length !== newEmails.length ||
      uniqueEmails?.length !== newEmails.length
    ) {
      setError("Email already exists");
      return;
    }

    setEmails((prev) => prev.concat(newUniqueEmails));
    localStorage.setItem(
      "storedEmails",
      JSON.stringify(emails.concat(newUniqueEmails))
    );
    setEmailInputs("");
  };

  const handleInputChange = (e) => {
    if (e.target.value === ",") return;
    setEmailInputs(e.target.value);
  };

  const handleDelete = (email) => {
    setEmails((prev) => prev.filter((item) => item.email !== email));
    localStorage.setItem(
      "storedEmails",
      JSON.stringify(emails.filter((item) => item?.email !== email))
    );
  };

  const handleCancel = () => {
    trackEvent("Team Members - Invite Editors - Cancel");
    setOpenInviteModal(false);
    setEmails([]);
    localStorage.setItem("storedEmails", JSON.stringify([]));
  };

  const hasDuplicateEmails = (usersArray) => {
    const uniqueEmails = new Set();
    for (let user of usersArray) {
      if (uniqueEmails.has(user.email)) {
        return true;
      }
      uniqueEmails.add(user.email);
    }
    return false;
  };

  const handleInvite = async () => {
    setError("");
    if (!emails.length) return;

    const validateEmailArr = emails.map((item) =>
      validateEmail(item?.email, setError)
    );

    const isValid = validateEmailArr.some((valid) => valid === false);

    if (isValid) {
      setError("Invalid email");
      return;
    }

    if (hasDuplicateEmails(emails)) {
      setError("Duplicate email found");
      return;
    }

    const senderInfo = currentTeam?.admin?.filter(
      (adminItem) => adminItem.userId === user?.uid
    )[0];

    const filteredEmail = emails.filter(
      (item) => item?.email !== auth?.currentUser?.email
    );
    const updatedData = currentMembers?.filter(
      (i) => i?.permission !== "viewer"
    );
    dispatch(setTotalEmails([...updatedData, ...emails]));
    setLoading(true);
    const result = await Services.InviteServices.sendInviteEmails(
      currentTeam?.teamId,
      currentTeam?.teamName,
      senderInfo?.email,
      senderInfo?.name,
      filteredEmail
    );

    if (result === "success") {
      trackEvent("Team Members - Invite Editors - Send Invites", {
        emails: filteredEmail,
        numInvites: filteredEmail?.length,
      });
      setLoading(false);
      setSend(true);
      // setEmails([]);
      localStorage.setItem("storedEmails", JSON.stringify([]));
      if (fetchMembers) {
        setTimeout(() => {
          fetchMembers();
        }, 2000);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (send) {
      if (setOpenInviteModal) {
        setOpenInviteModal(false);
      }
      if (
        currentMembers &&
        totalEmails?.length > 0 &&
        isArray1DisjointFromArray2(emails, totalEmails)
      ) {
        dispatch(
          toggleNotification({
            open: true,
            content: "Editors seat limit reached!",
            action: () => {},
            type: "warning",
          })
        );
        if (type !== "show-btn") {
          dispatch(setTotalEmails([]));
          setEmails([]);
        }
        return;
      }
      dispatch(
        toggleNotification({
          open: true,
          content: "Invite links sent successfully.",
          action: () => {},
          type: "warning",
        })
      );
      if (type !== "show-btn") {
        dispatch(setTotalEmails([]));
        setEmails([]);
      }
    }
  }, [send]);

  return (
    <>
      <MedText variant="body2" sx={styles.label} className="!text-black/50">
        Please, enter email ID
      </MedText>
      <input
        onChange={handleInputChange}
        value={emailInputs}
        onKeyDown={handleEmails}
        placeholder="Email, comma seperated"
        className="bg-white/10 rounded-lg focus:rounded-b-none w-full focus:outline-none p-3 text-black text-sm focus:border-b-[2px] focus:border-b-newPrimary"
        type="text"
        style={{
          fontFamily: "Poppins",
          background: Colors.whiteFaded,
        }}
      />
      {error && <div className="text-sm text-red mt-2">{error}</div>}
      <Grid
        container
        mt="8px"
        spacing={1}
        className="max-h-[120px] overflow-y-scroll"
        sx={styles.emailContainer}
      >
        {[...new Set(emails.map((item) => item.email))].map((email, i) => (
          <Grid item key={i}>
            <Stack direction="row" sx={styles.chip} gap="10px">
              <RegText sx={styles.chipText}>{email}</RegText>
              <Box onClick={() => handleDelete(email)}>
                <CloseSvg height={10} width={10} stroke={Colors.black} />
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
      {type !== "show-btn" && (
        <Stack sx={styles.btnContainer}>
          <CustomBtn
            type={"primary"}
            onClick={handleInvite}
            disabled={!emails?.length || isButtonDisabled}
          >
            {loading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              "Send Invite"
            )}
          </CustomBtn>
          <CustomBtn
            type="secondary"
            onClick={handleCancel}
            styles={{ display: { xs: "none", md: "flex" } }}
          >
            Cancel
          </CustomBtn>
        </Stack>
      )}

      <Snackbar
        open={send}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setSend(false)}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{
            backgroundColor: "white",
            color: "black",
            position: "relative",
            bottom: "100px",
          }}
        >
          Emails are sent.
        </Alert>
      </Snackbar>
    </>
  );
};

export default InviteCollaborators;
