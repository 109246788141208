import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { RegText, SemiBoldText } from "../../../styles";
import CustomBtn from "../../common/CustomBtn";
import { styles } from "./styles";
import {
  checkInviteStatus,
  getInviteData,
  handleInvitationLink,
} from "../../../services/invite";
import { auth } from "../../../libraries/firebase";
import { Services } from "../../../services";
import { UserInvitationSvg } from "../../../assets";
import Loading from "../../common/Loading";
import { Colors } from "../../../themes";
import { getEditorSeats } from "../../../helper/validation";

const InvitationScreen = () => {
  const { invitedTeamId } = useParams();
  const navigate = useNavigate();
  const [user, isLoading] = useAuthState(auth);

  const [inviteData, setInviteData] = useState(null);
  const [teamInfo, setTeamInfo] = useState(null);
  const [teamModData, setTeamModData] = useState(null);
  const [isAcceptLoading, setAcceptLoading] = useState(false);
  const [isRejectLoading, setRejectLoading] = useState(false);

  const processInvite = useCallback(async () => {
    localStorage.removeItem("openInvitationAfterSignIn");
    if (!user) {
      let navigationPath = `/signin?invitationId=${invitedTeamId}`;
      navigate(navigationPath);
      return;
    }

    const preInviteData = await getInviteData(invitedTeamId, user?.email);
    const duration = moment.duration(
      moment().diff(moment(preInviteData?.createdAt?.toDate()))
    );

    if (preInviteData) {
      const teamData = await Services.TeamServices.getTeamData(invitedTeamId);
      const teamModerationData = await Services.TeamServices.getTeamModData(
        invitedTeamId
      );
      const totalEditors = [
        ...teamModerationData.admin,
        ...teamModerationData.contributers,
      ];

      if (totalEditors?.length >= getEditorSeats(teamModerationData?.plan)) {
        setTeamModData(teamModerationData);
        setInviteData(preInviteData);
        navigate(`/`);
        return;
      }

      if (preInviteData?.status === "notvalid") {
        setTeamModData(teamModerationData);
        setInviteData(preInviteData);
        navigate(`/`);
        return;
      }

      if (preInviteData?.status === "Sent") {
        if (duration.asHours() < 24) {
          if (user?.email !== preInviteData?.recipientEmail) {
            navigate(`/`);
            return;
          }

          setTeamInfo(teamData);
          setInviteData(preInviteData);

          return;
        } else {
          navigate(
            `/teams/${preInviteData?.teamId}/invitation/expire?team_name=${teamData?.name}&team_admin_name=${preInviteData?.senderInfo?.name}`
          );
          return;
        }
      }

      if (preInviteData?.status === "cancelled") {
        navigate(
          `/teams/${preInviteData?.teamId}/invitation/cancel?team_name=${teamData?.name}&team_admin_name=${preInviteData?.senderInfo?.name}`
        );

        return;
      }
    } else {
    }
  }, [user, invitedTeamId, navigate]);

  const acceptInvite = async () => {
    if (!inviteData) {
      return;
    }

    const res = await checkInviteStatus({ invitationId: inviteData?.id });
    if (res === "cancelled") {
      navigate(
        `/teams/${inviteData?.teamId}/invitation/cancel?team_name=${teamInfo?.name}&team_admin_name=${inviteData?.senderInfo?.name}`
      );
      return;
    }

    setAcceptLoading(true);
    const response = await handleInvitationLink(inviteData?.id, "Accepted");
    setAcceptLoading(false);

    if (response === "success") {
      navigate(`/teams/${inviteData?.teamId}`);
    }
  };

  const rejectInvite = async () => {
    if (!inviteData) {
      return;
    }

    setRejectLoading(true);
    const response = await handleInvitationLink(inviteData?.id, "Rejected");
    setRejectLoading(false);

    if (response === "rejected") {
      navigate("/");
    }
  };

  useEffect(
    function () {
      if (!invitedTeamId) {
        return;
      }

      if (isLoading) {
        return;
      }

      processInvite();
    },
    [invitedTeamId, navigate, processInvite, isLoading]
  );

  if (!teamInfo) {
    return <Loading />;
  }

  if (
    teamModData &&
    [...teamModData.admin, ...teamModData.contributers]?.length >=
      getEditorSeats(teamModData?.plan)
  ) {
    navigate("/");
    return;
  }

  return (
    <Box height="100%">
      <Stack {...styles.container}>
        {teamInfo?.profileImage ? (
          <Box
            component="img"
            src={teamInfo?.profileImage}
            alt="team-logo"
            mt={{ xs: "64px", md: "40px" }}
            height={{ xs: "79px", md: "126px" }}
            width={{ xs: "79px", md: "126px" }}
            sx={{
              borderRadius: "8px",
            }}
          />
        ) : (
          <Box {...styles.logoBox}>
            <UserInvitationSvg style={{ height: "100%", width: "100%" }} />
          </Box>
        )}
        <SemiBoldText {...styles.welcomeText}>
          {inviteData?.senderInfo?.name} invited You to {teamInfo?.name} team
        </SemiBoldText>
        <RegText {...styles.descriptionText1}>{teamInfo?.about}</RegText>
        <RegText {...styles.descriptionText2}>{teamInfo?.about}</RegText>
        <CustomBtn
          onClick={acceptInvite}
          {...styles.customBtn}
          disabled={isAcceptLoading || isRejectLoading}
        >
          {isAcceptLoading ? (
            <CircularProgress sx={{ color: Colors.white }} size={30} />
          ) : (
            "Accept Invitation"
          )}
        </CustomBtn>
        <CustomBtn
          onClick={rejectInvite}
          disabled={isRejectLoading || isAcceptLoading}
          {...styles.customBtnReject}
        >
          {isRejectLoading ? (
            <CircularProgress sx={{ color: Colors.white }} size={30} />
          ) : (
            "Reject"
          )}
        </CustomBtn>
      </Stack>
    </Box>
  );
};

export default InvitationScreen;
