import React from "react";
import { CheveronRightSvg, UnboardLogoSvg } from "../../../assets";
import { RegText, SemiBoldText } from "../../../styles";
import { Colors } from "../../../themes";

const PrivacyAndTerms = ({ title, content }) => {
  return (
    <div className="max-w-[85rem] mx-auto min-h-screen">
      <div className="h-[72px] md:h-[60px]  flex items-center justify-start md:mt-[29px] mb-6 md:mb-0 custom-shadow md:shadow-none">
        <UnboardLogoSvg
          fill={Colors.newPrimary}
          className="h-[26px] hidden md:block"
        />
        <a href="/signin" className="flex items-center ml-6 gap-4 md:hidden">
          <CheveronRightSvg className="scale-[-1] h-[14px]" stroke="black"/>
          <SemiBoldText className="!text-black !text-sm">{title}</SemiBoldText>
        </a>
      </div>
      <SemiBoldText className="!text-black !text-[28px] text-center mt-[33px] hidden md:block">
        {title}
      </SemiBoldText>
      <RegText className="max-w-[62rem] !mx-6 md:!mx-auto !mt-6 md:!mt-[40px] !text-sm" dangerouslySetInnerHTML={{ __html: content }}/>
    </div>
  );
};

export default PrivacyAndTerms;
