import { Colors } from "../../../themes";

export const styles = {
  createAccountText: {
    fontSize: { xs: "16px", md: "20px" },
    textAlign: "center",
    mb: { xs: "16px", md: "20px" },
    color: Colors.gray,
  },
  navbar: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    py: { xs: "18px", lg: "33px" },
    bgcolor: { xs: Colors.whiteFaded05, lg: "transparent" },
  },
  heading: {
    fontSize: "47px",
    lineHeight: "71px",
    mb: "15px",
  },
  profilePicWrapper: {
    position: "relative",
    width: { xs: "70.8px", md: "126px" },
    height: { xs: "70.8px", md: "126px" },
    mb: "20px",
  },
  profilePic: {
    borderRadius: "8px",
    width: { xs: "70.8px", md: "126px" },
    height: { xs: "70.8px", md: "126px" },
    objectFit: "cover",
  },
  editIcon: {
    position: "absolute",
    bottom: "-20px",
    right: "-20px",
  },
};
