import { Colors } from "../../../themes";

export const styles = {
  arrowBack: {
    flexDirection: "row",
    height: "72px",
    width: "100%",
    bgcolor: Colors.whiteFaded05,
    pl: "16px",
  },
  heading: { px: "20px", mb: "48px", mt: "16px" },
  subHeading: { textAlign: "center" },

  userAvatarWrapper: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
    height: "32px",
    width: "32px",
    backgroundColor: Colors.white05,
  },
  pendingTextWrapper: {
    px: "8px",
    py: "4px",
    borderRadius: "8px",
    backgroundColor: Colors.whiteFaded05,
  },
  pendingText: {
    fontSize: "10px",
  },
};
