import React, { useEffect, useState } from "react";
import { UserInvitationSvg } from "../../../assets";
import { Box, CircularProgress, Stack } from "@mui/material";
import { RegText, SemiBoldText } from "../../../styles";
import CustomBtn from "../../common/CustomBtn";
import { styles } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { firestoreDb } from "../../../libraries/firebase";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/Loading";
import { toggleNotification } from "../../../redux/appSlice";
import { Colors } from "../../../themes";

const ViewerInvitation = () => {
  const [teamInfo, setTeamInfo] = useState(null);
  const [componentLoading, setComponentLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const { data } = useSelector((state) => state.user);
  const { teamId } = useParams();
  const docQuery = doc(firestoreDb, "TeamsModerations", teamId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const getTeamData = async () => {
      if (!teamId) return;
      try {
        const docData = await getDoc(docQuery);
        setTeamInfo(docData.data());
        if (docData.data().userIds.includes(data?.uid)) {
          dispatch(
            toggleNotification({
              open: true,
              content: "Already joined the team",
              type: "warning",
            })
          );
          navigate(`/teams/${teamId}`);
        }
      } catch (e) {
        console.log("e.message", e.message);
      } finally {
        setComponentLoading(false);
      }
    };
    getTeamData();
  }, [teamId]);

  const viewerInvitationHandler = async () => {
    setBtnLoading(true);
    updateDoc(docQuery, {
      viewers: arrayUnion({
        email: data?.email,
        name: data?.displayName,
        profileImage: data?.profileImage,
        userId: data?.uid,
      }),
      userIds: arrayUnion(data?.uid),
    })
      .then((res) => {
        console.log("res", res);
      })
      .catch((e) => {
        console.log("e", e);
      })
      .finally(() => {
        localStorage.removeItem("openViwerInvitationAfterSignIn");
        navigate(`/teams/${teamId}`);
        setBtnLoading(false);
      });
  };
  if (componentLoading) {
    return <Loading />;
  }
  return (
    <Box height="100%">
      <Stack {...styles.container}>
        {teamInfo?.profileImage ? (
          <Box
            component="img"
            src={teamInfo?.profileImage}
            alt="team-logo"
            mt={{ xs: "64px", md: "40px" }}
            height={{ xs: "79px", md: "126px" }}
            width={{ xs: "79px", md: "126px" }}
            sx={{
              borderRadius: "8px",
            }}
          />
        ) : (
          <Box {...styles.logoBox}>
            <UserInvitationSvg style={{ height: "100%", width: "100%" }} />
          </Box>
        )}
        <SemiBoldText {...styles.welcomeText}>
          You can join the team {teamInfo?.teamName} as a Viewer
        </SemiBoldText>
        <RegText {...styles.descriptionText1}>{teamInfo?.teamAbout}</RegText>
        <RegText {...styles.descriptionText2}>{}</RegText>
        <CustomBtn onClick={viewerInvitationHandler} {...styles.customBtn}>
          {btnLoading ? (
            <CircularProgress size={30} sx={{ color: Colors.white }} />
          ) : (
            "Continue as a Viewer"
          )}
        </CustomBtn>
        <CustomBtn
          {...styles.customBtnReject}
          onClick={() => {
            navigate(`/`);
            localStorage.removeItem("openViwerInvitationAfterSignIn");
          }}
        >
          {"Cancel"}
        </CustomBtn>
      </Stack>
    </Box>
  );
};

export default ViewerInvitation;
