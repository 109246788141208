export const validateEmail = (email, callback) => {
  if (!email) {
    callback("Please enter a valid email");
    return false;
  }
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  if (!email.match(emailPattern)) {
    callback("Please enter a valid email");
    return false;
  }
  return true;
};

export const validateInput = (value, setError, fieldName) => {
  if (fieldName === "Team name" && !value.trim()) {
    setError(`${fieldName} cannot be empty.`);
    return false;
  }
  if (fieldName === "Team name" && value.trim().length < 2) {
    setError(`${fieldName} should be at least 2 characters.`);
    return false;
  }
  if (value.trim().length >= 300 && fieldName === "About team") {
    setError(`${fieldName} should be less than 300 characters.`);
    return false;
  }
  if (value.trim().length >= 26 && fieldName === "Team name") {
    setError(`${fieldName} should be less than 25 characters.`);
    return false;
  }
  setError("");
  return true;
};

export const validateConfirmInput = (value, setError, fieldName, testValue) => {
  if (!value.trim()) {
    setError(`${fieldName} cannot be empty.`);
    return false;
  }
  if (value !== testValue) {
    setError(`${fieldName} doesn't match`);
    return false;
  }
  setError("");
  return true;
};

export const getEditorSeats = (plan) => {
  if (plan === "free") return 3;

  if (plan === "premium") return 5;

  return 12;
};

export const getEmailLimit = (plan) => {
  if (plan === "free") return 4;

  if (plan === "premium") return 6;

  return 13;
};
