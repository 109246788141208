import moment from "moment";

import {
  onValue,
  push,
  realDb,
  realTimeRef,
  update,
} from "../libraries/firebase";
import { trackEvent } from "../utils/functions";

export const fetchChatMessages = async (roomId, setMessages) => {
  try {
    const docRef = realTimeRef(realDb, `Chats/${roomId}`);

    onValue(docRef, (snapshot) => {
      if (snapshot.exists() && snapshot.val() !== "null") {
        const data = snapshot.val();
        let messages = [];

        Object.keys(data).forEach((key) => {
          messages.push({
            id: data[key]?.sId,
            text: data[key]?.text,
            messageId: key,
            timestamp: moment
              .unix(data[key]?.ts)
              .format("h:mm a")
              .toUpperCase(),
          });
        });
        setMessages(messages);
      }
    });
  } catch (error) {
    console.log(error);
    return {
      status: "failed",
      data: [],
    };
  }
};

export const sendMessages = async (roomId, sId, text, teamId, projectId) => {
  try {
    const docRef = realTimeRef(realDb, `Chats/${roomId}`);
    await push(docRef, {
      sId,
      text,
      ts: moment().unix(),
    });
    trackEvent("Chat - Send Collab Message", {
      teamID: teamId,
      projectID: projectId,
      fileID: roomId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchCollaborators = async (
  roomId,
  setCollaborators,
  collectionName
) => {
  try {
    const roomName = collectionName ?? "room";
    const docRef = realTimeRef(realDb, `${roomName}/${roomId}/collaborators`);

    onValue(docRef, (snapshot) => {
      if (snapshot.exists() && snapshot.val() !== "null") {
        setCollaborators(snapshot?.val());
      }
    });
  } catch (error) {
    console.log(error);
    return {
      status: "failed",
      data: {},
    };
  }
};

export const editUserName = async (roomId, uid, name) => {
  try {
    const pathRef = realTimeRef(realDb, `room/${roomId}/collaborators`);
    await update(pathRef, {
      [uid]: name,
    });
  } catch (error) {
    console.log(error);
  }
};
