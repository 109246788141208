import {
  AppBar,
  Button,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Drawer, Icons, ProfileSvg } from "../../../assets";
import IosShareIcon from "@mui/icons-material/IosShare";
import AppDrawer from "../AppDrawer";
import LinkCard from "../LinkCard";
import { Colors } from "../../../themes";
import { checkStringLength } from "../../../utils/functions";
const PhoneNav = ({
  appDrawerProps,
  setAppDrawerProps,
  type,
  workspaceName = "Unboard",
}) => {
  const handleMenuToggle = () => {
    setAppDrawerProps({
      open: true,
      anchor: "left",
      drawerWidth: "70%",
    });
  };
  const handleChatToggle = () => {
    setAppDrawerProps({
      open: true,
      anchor: "right",
      drawerWidth: "100%",
    });
  };
  const handleBottomSheet = () => {
    setAppDrawerProps({
      open: true,
      anchor: "bottom",
      drawerWidth: "100%",
    });
  };
  const showCollabChat = (type) => {
    if (type !== "cloned") {
      return (
        <Stack direction="row" gap={1} alignItems="center">
          {/* <button
            onClick={handleBottomSheet}
            className="flex bg-newPrimary px-3 py-1 rounded-full items-center"
          >
            <IosShareIcon sx={{ fontSize: 18 }} />{" "}
            <p className="text-sm whitespace-nowrap">Share Workspace</p>
          </button> */}
          <Divider flexItem orientation="vertical" className="bg-grayBlueFaded" />
          <ProfileSvg
            width={18}
            height={18}
            onClick={() => handleChatToggle()}
            stroke={Colors.newPrimary}
          />
        </Stack>
      );
    }
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: { xs: "block", sm: "none" },
          boxShadow: "none",
          border: `1px solid ${Colors.grayishBlue}`,
          zIndex: 10,
        }}
      >
        <div className="flex bg-white h-14 px-4 items-center justify-between">
          <div className="flex items-center">
            <button
              className="flex flex-row h-7 items-center justify-center mr-2"
              onClick={() => handleMenuToggle()}
            >
              <Drawer />
            </button>
            <p className="text-black text-sm">{checkStringLength(workspaceName,20)}</p>
          </div>

          {showCollabChat(type)}
        </div>
      </AppBar>
    </>
  );
};

export default PhoneNav;
