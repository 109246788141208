import {
  collectionGroup,
  doc,
  firestoreDb,
  getDocs,
  orderBy,
  payment,
  query,
  updateDoc,
  where,
} from "../libraries/firebase";

export const sendPayment = async (teamId) => {
  try {
    const response = await payment({
      teamId,
    });
    // TODO - handle payment and redirection
    if (response?.data?.status !== "success") {
      console.log("Error!");
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchInvoices = async (teamId) => {
  try {
    const subscriptionIds = [];
    const docRef = collectionGroup(firestoreDb, "subscriptions");
    const subscriptionQuery = query(
      docRef,
      where("metadata.teamId", "==", teamId),
      orderBy("created", "desc")
    );

    const querySnapShot = await getDocs(subscriptionQuery);
    querySnapShot.docs.forEach((doc) => {
      subscriptionIds.push(doc.id);
    });

    const invoicePromises = subscriptionIds.map(async (id) => {
      const invoiceRef = collectionGroup(firestoreDb, "invoices");
      const invoiceQuery = query(
        invoiceRef,
        where("subscription", "==", id),
        orderBy("created", "desc")
      );
      const snapShot = await getDocs(invoiceQuery);
      return snapShot.docs[0].data();
    });

    const invoices = await Promise.all(invoicePromises);
    return invoices;
  } catch (error) {
    console.log(error);
  }
};

export const fetchCurrentTeamSubscription = async (teamId) => {
  if (!teamId) return;
  try {
    const subscriptionRef = collectionGroup(firestoreDb, "subscriptions");
    const subscriptionQuery = query(
      subscriptionRef,
      where("metadata.teamId", "==", teamId),
      where("status", "==", "active"),
      orderBy("created", "desc")
    );
    const querySnapShot = await getDocs(subscriptionQuery);

    if (querySnapShot.empty) return null;

    return [querySnapShot.docs[0].data()];
  } catch (error) {
    console.log(error);
  }
};
