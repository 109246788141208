import { Colors } from "../../../themes";

export const styles = {
  inputContainer: {
    backgroundColor: Colors.whiteFaded10,
    height: "48px",
    borderRadius: "8px",
    alignItems: "center",
    position: "relative",
    padding: "13px 14px",
  },
  input: {
    backgroundColor: "transparent",
    height: "48px",
    borderRadius: "8px",
    caretColor: Colors.primaryBtnColor,
    color: Colors.white,
    outline: "none",
    fontFamily: `"Poppins", sans-serif`,
    fontSize: "14px",
    fontWeight: 500,
  },
  select: {
    fontFamily: `"Poppins", sans-serif`,
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: "transparent",
    color: Colors.white,
    // padding: "13px 14px",
    outline: "none",
    zIndex: 10,
    cursor: "pointer",
  },
  chip: {
    border: `1px solid ${Colors.blackFaded54}`,
    p: "5.14px 10px",
    borderRadius: "100vmax",
    alignItems: "center",
  },
  chipText: { color: Colors.black, fontSize: "12px" },
  label: { lineHeight: "16px", mb: "16px" },
  btnContainer: {
    mt: { xs: "auto", md: "40px" },
    gap: "24px",
    mb: { xs: "8px", md: 0 },
    pt: { xs: "8px", md: 0 },
  },
  emailContainer: {
    "::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "transparent !important",
      borderRadius: "100px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: `${Colors.newPrimaryFaded25} !important`,
      borderRadius: "100px !important",
      border:`1px solid ${Colors.newPrimary}`
    },

    "&::-webkit-scrollbar": {
      display: "block",
    },
  },
};
