import moment from "moment";
import {
  realDb,
  realTimeRef,
  push,
  onValue,
  update,
} from "../libraries/firebase";
import { trackEvent } from "../utils/functions";

export const updateLastSession = async (roomId) => {
  const workspaceRef = realTimeRef(realDb, `Workspaces/${roomId}`);

  await update(workspaceRef, {
    timestamp: moment().unix(),
  });
};
export const createThread = async ({
  roomId,
  threadId,
  question,
  requesterId,
  conversationId,
  parentMessageId,
  isSubThread,
  isFirstMessageOfNewSubThread,
  conversationLength,
  teamId,
  projectId,
}) => {
  try {
    const pathRef = realTimeRef(realDb, `Threads/${roomId}`);
    let threadAutoId = threadId;

    if (!conversationLength && !isSubThread) {
      threadAutoId = push(pathRef, {
        parentHistory: "",
        parentThreadId: "",
        conversationId: "",
        request: question,
      }).key;
    }

    if (conversationLength === 0 && isSubThread) {
      const ref1 = realTimeRef(realDb, `Threads/${roomId}/${threadAutoId}`);
      update(ref1, {
        request: question,
      });
    }

    const threadRef = realTimeRef(realDb, `AIChats/${roomId}/${threadAutoId}`);
    await updateLastSession(roomId);
    await push(threadRef, {
      messageId: "",
      parentMessageId,
      request: question,
      requesterId,
      response: "...",
      isFirstMessageOfNewSubThread,
      timestamp: moment().unix(),
    });
    trackEvent("Chat - Send AI Message", {
      teamID: teamId,
      projectID: projectId,
      fileID: roomId,
    });

    return { status: "success", data: threadAutoId };
  } catch (error) {
    console.log(error);
    return "failed";
  }
};

export const fetchThreads = async (roomId, setThreads, collectionName) => {
  try {
    const roomName = collectionName ?? "Threads";

    const docRef = realTimeRef(realDb, `${roomName}/${roomId}`);

    onValue(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let threads = [];
        if (data === "null") return;

        Object.keys(data).forEach((key) => {
          let firstConvo = data[key]?.request ?? data[key]?.parentHistory;

          if (
            !data[key]?.request &&
            Object.keys(data[key]?.parentHistory).length > 0
          ) {
            firstConvo = data[key]?.parentHistory;
            const firstKey = Object.keys(firstConvo)[0];
            firstConvo = data[key]?.parentHistory?.[firstKey]?.request;
          }

          threads.push({
            id: key,
            name: firstConvo ?? "New thread",
            parentThread: data[key]?.parentThreadId,
            parentHistory: data[key]?.parentHistory,
            conversationId: data[key]?.conversationId,
          });
        });

        setThreads(threads.reverse());
      }
    });
  } catch (error) {
    console.log(error);
    return {
      status: "failed",
      data: [],
    };
  }
};

export const fetchConversation = async (
  roomId,
  threadId,
  setConversation,
  collectionName
) => {
  try {
    const roomName = collectionName ?? "AIChats";

    const docRef = realTimeRef(realDb, `${roomName}/${roomId}/${threadId}`);

    onValue(docRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        let conversationList = [];

        Object.keys(data).forEach((key) => {
          conversationList.push({
            id: key,
            question: data[key]?.["request"],
            answer: data[key]?.["response"],
            requesterId: data[key]?.["requesterId"],
            parentMessageId: data[key]?.["parentMessageId"],
          });
        });
        setConversation(conversationList);
      }
    });
  } catch (error) {
    console.log(error);
    return {
      status: "failed",
      data: [],
    };
  }
};
