import React, { useState } from "react";
import { Icons } from "../../../assets";
import { AvatarSvg } from "../../../assets";
import { stringToColour } from "../../../helper/formatting";
import ReactMarkdown from "react-markdown";
import "../../../index.css";
import { MedText } from "../../../styles";
const renderIcon = (type, svgText) => {
  if (type === "answer") {
    return (
      <img
        src={Icons.chatGpt}
        alt="user"
        className="w-[30px] h-[30px] rounded-sm"
      />
    );
  }
  return (
    <div className="w-8 h-8">
      <AvatarSvg fill={stringToColour(svgText)} />
    </div>
  );
};

const Bubble = ({
  type,
  name,
  message,
  customStyle,
  svgText,
  isRequester = false,
  onPress,
  isViewer,
}) => {
  const [showSubThread, setShowSubThread] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className={`flex flex-1 flex-row justify-between items-start relative ${customStyle}`}
      onMouseEnter={() => setShowSubThread(true)}
      onMouseLeave={() => setShowSubThread(false)}
    >
      <div className="flex flex-row">
        {renderIcon(type, svgText)}
        <div className="flex flex-col ml-4 break-keep">
          {name && <p className="text-grey text-xs">{name}</p>}
          <ReactMarkdown
            children={message.toString()}
            className="prose prose-invert prose-emerald text-black text-break"
          />
        </div>
      </div>
      {isRequester && showSubThread && !isViewer && (
        <button
          className="flex flex-row px-3 py-2 h-7 items-center justify-center bg-newPrimary/20  rounded-lg absolute right-0"
          onClick={() => {
            if (!isLoading) {
              onPress(setIsLoading);
            }
          }}
        >
          <img src={Icons.programmingArrow} alt="copy" className="w-4 h-4" />
          <MedText variant="caption" className="text-newPrimary mx-1   !ml-2 fs:hidden fmd:block !text-xs">
            {isLoading ? "creating..." : "New Question"}
          </MedText>
        </button>
      )}
    </div>
  );
};

export default Bubble;
