import mixpanel from "mixpanel-browser";

export const formatThreads = (threads) => {
  const formattedThreads = [];
  const threadMap = new Map();

  for (const thread of threads) {
    threadMap.set(thread.id, {
      id: thread.id,
      name: thread.name,
      parentHistory: thread.parentHistory,
      subthreads: [],
    });
  }

  for (const thread of threads) {
    const parentThread = threadMap.get(thread.parentThread);
    if (!parentThread) {
      formattedThreads.push(threadMap.get(thread.id));
      continue;
    }
    parentThread.subthreads.push(threadMap.get(thread.id));
  }

  return formattedThreads;
};

export const formatThreadsWithLevels = (threads) => {
  const rootThreads = [];
  const hash = {};

  for (const thread of threads) {
    hash[thread.id] = {
      id: thread.id,
      name: thread.name,
      parentHistory: thread.parentHistory,
      subthreads: [],
    };
  }

  for (const thread of threads) {
    if (thread.parentThread) {
      hash[thread.parentThread].subthreads.push(hash[thread.id]);
    } else {
      rootThreads.push(hash[thread.id]);
    }
  }

  function addLevels(threads, level) {
    for (const thread of threads) {
      thread.level = level;
      addLevels(thread.subthreads, level + 1);
    }
  }

  addLevels(rootThreads, 0);

  return rootThreads;
};

export const getDeviceHeight = () => {
  const isMobileiOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return isMobileiOS ? window.visualViewport.height : "100vh";
};
export const trackEvent = (eventName, data) => {
  try {
    if (!eventName) return;
    mixpanel.track(eventName, data);
  } catch (e) {
    console.log(e);
  }
};

export const getUserRolesInTeams = (teams, userId) => {
  const roles = {};
  teams.forEach((team) => {
    if (team?.admin?.some((t) => t.userId === userId)) {
      roles[team?.teamId] = "admin";
      return;
    }
    if (team?.contributers?.some((t) => t.userId === userId)) {
      roles[team?.teamId] = "contributer";
      return;
    }
    roles[team?.teamId] = "viewer";
  });

  return roles;
};

export const isUserAdmin = (currentUserId, selectedTeam) => {
  const isAdmin = selectedTeam?.admin?.some(
    (item) => item?.userId === currentUserId
  );
  if (isAdmin) {
    return true;
  }
  return false;
};

// TODO - directly get the role as per selected team.
export const getUserRole = (currentUserId, selectedTeam) => {
  const isAdmin = selectedTeam?.admin?.some(
    (item) => item?.userId === currentUserId
  );
  if (isAdmin) {
    return "admin";
  }
  const isContributor = selectedTeam?.contributers?.some(
    (item) => item?.userId === currentUserId
  );
  if (isContributor) {
    return "contributer";
  }
  const isViewer = selectedTeam?.viewers?.some(
    (item) => item?.userId === currentUserId
  );
  if (isViewer) {
    return "viewer";
  }
  return "";
};

export const checkStringLength = (name, length) =>
  name?.length > length ? `${name?.slice(0, length)}...` : name;

export function removeCommonElements(firstArray, secondArray) {
  // Extract unique identifiers from the second array
  const secondArrayIds = new Set(secondArray.map((item) => item.id));

  // Filter the first array to remove elements whose identifiers are in the second array
  const filteredArray = firstArray.filter(
    (item) => !secondArrayIds.has(item.id)
  );

  return filteredArray;
}

export function adjustPendingMembers(existingMembers, pendingMembers, plan) {
  // Calculate the total number of members (existing + pending)
  const totalMembers = existingMembers.length + pendingMembers.length;

  // Calculate the number of excess members over the plan limit
  const excessMembers = totalMembers - plan;
  // If there are excess members, remove them from the pending members array
  if (excessMembers > 0) {
    pendingMembers.splice(-excessMembers);
  }
  // Return the adjusted pending members array
  return pendingMembers;
}

export function isArray1DisjointFromArray2(array1, array2) {
  console.log(array1, array2);
  // Extract unique identifiers from array2
  const array2Ids = new Set(array2.map((item) => item.email));

  // Check if any element in array1 is present in array2
  for (let i = 0; i < array1.length; i++) {
    if (array2Ids.has(array1[i].email)) {
      return false; // array1 contains an element from array2
    }
  }

  return true; // array1 does not contain any element from array2
}
