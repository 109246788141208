import { InputAdornment, TextField } from "@mui/material";
import { Colors } from "../../../themes";
import { MedText } from "../../../styles";

const Input = ({
  label,
  type,
  style,
  autoFocus,
  onBlur,
  onChange,
  startAdornment,
  placeholder,
  name,
  value,
  disabled,
  maxLength,
  onPaste,
  onKeyDown,
  multiline,
  rows,
}) => {
  return (
    <div className="w-full rounded-lg overflow-hidden">
      <TextField
        disabled={disabled}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        type={type}
        className={`block  outline-none rounded-lg bg-white/10 py-[13px] px-[14px] mb-[24px]  w-full`}
        style={{ caretColor: Colors.newPrimary, ...style }}
        required
        autoFocus={autoFocus}
        onBlur={onBlur}
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => onKeyDown(e)}
        onPaste={onPaste}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ mr: "8px" }} position="start">
              {startAdornment ?? startAdornment}
            </InputAdornment>
          ),
          style: {
            color: Colors.dark,
            padding: "13px 16px",
            fontFamily: `"Poppins", sans-serif`,
            fontSize: "14px",
            background: Colors.whiteFaded,
            borderRadius: "8px",
            fontWeight: 500,
            outline: "none",
          },
        }}
        inputProps={{ maxLength: maxLength }}
        multiline={multiline}
        rows={rows}
        sx={{
          "& .MuiInput-underline:before": {
            borderBottomColor: Colors.whiteFaded,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: Colors.newPrimary,
          },
        }}
      />
    </div>
  );
};

export default Input;
