import { child, get, onValue, query } from "firebase/database";
import {
  firestoreDb,
  addDoc,
  collection,
  doc,
  updateDoc,
  setDoc,
  getDocs,
  onSnapshot,
  realTimeRef,
  realDb,
} from "../libraries/firebase";
import { serverTimestamp, where, orderBy } from "firebase/firestore";
import { setTotalMessagesForTeam, setWorkspaceIds } from "../redux/userSlice";
import moment from "moment/moment";

export const getWorkspaces = async (teamId, projectId, callback) => {
  try {
    if (!teamId || !projectId) return;

    const workspaceRef = collection(
      firestoreDb,
      `Teams`,
      `${teamId}`,
      `Workspaces`
    );
    const workspaceQuery = query(
      workspaceRef,
      where("projectId", "==", projectId),
      where("isDeleted", "==", false),
      orderBy("createdAt", "desc")
    );
    const snapshot = await getDocs(workspaceQuery);
    const workspaces = snapshot.docs.map(async (item) => {
      const workspaceRef = realTimeRef(realDb, `Workspaces/${item?.id}`);
      const workspaceData = await get(workspaceRef);
      let lastSession = moment(item?.data()?.createdAt?.toDate()).unix();
      if (workspaceData.exists && workspaceData?.val()?.timestamp !== "null") {
        lastSession = workspaceData?.val()?.timestamp;
      }

      return {
        key: item?.id,
        ...item?.data(),
        updatedAt: item?.data()?.updatedAt?.seconds,
        createdAt: item?.data()?.createdAt?.seconds,
        lastSession,
      };
    });

    const updatedWorkspaces = await Promise.all(workspaces);

    callback(updatedWorkspaces);
  } catch (error) {
    console.error(error);
  }
};

export const createWorkspace = async (teamId, projectId, name) => {
  try {
    const workspaceRef = collection(firestoreDb, "Teams", teamId, "Workspaces");
    const newDoc = await addDoc(workspaceRef, {
      name,
      projectId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      isDeleted: false,
    });

    return newDoc?.id;
  } catch (error) {
    console.log(error);
  }
};

export const renameWorkSpace = async (teamId, workspaceId, name) => {
  try {
    const workspaceRef = doc(
      firestoreDb,
      "Teams",
      teamId,
      "Workspaces",
      workspaceId
    );

    await setDoc(
      workspaceRef,
      {
        name,
        updatedAt: serverTimestamp(),
      },
      {
        merge: true,
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteWorkSpace = async (teamId, workspaceId) => {
  try {
    const workspaceRef = doc(
      firestoreDb,
      "Teams",
      teamId,
      "Workspaces",
      workspaceId
    );
    await updateDoc(workspaceRef, {
      isDeleted: true,
      deletedAt: serverTimestamp(),
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllWorkspacesForTeam = async (teamId, dispatch) => {
  try {
    const workspaceRef = collection(firestoreDb, "Teams", teamId, "Workspaces");
    const unsubscribe = onSnapshot(workspaceRef, async (querySnapShot) => {
      if (!querySnapShot.docs.length) return;
      const workspaceIds = querySnapShot.docs.map((doc) => {
        return doc?.id;
      });
      dispatch(setWorkspaceIds(workspaceIds));
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchIndividualTeamWorkspacesMessages = async (
  teamId,
  dispatch,
  workspaceIdsForTeam
) => {
  try {
    let workspaceMessages = 0;
    if (!workspaceIdsForTeam?.length) return workspaceMessages;
    const updatedWorkspaceMessages = workspaceIdsForTeam?.map(async (id) => {
      const workspaceId = id;

      const pathRef = realTimeRef(realDb);

      const snapShot = await get(child(pathRef, `AIChats/${workspaceId}`));

      if (snapShot?.exists() && snapShot?.val() !== "null") {
        const totalMessages = countTotalMessages(snapShot?.val());
        workspaceMessages += totalMessages;
        return;
      }
      workspaceMessages += 0;
    });

    await Promise.all(updatedWorkspaceMessages);
    dispatch(setTotalMessagesForTeam(workspaceMessages));
  } catch (error) {
    console.log(error);
  }
};

function countTotalMessages(data) {
  let totalCount = 0;

  for (const room in data) {
    if (data?.hasOwnProperty(room)) {
      totalCount += Object.keys(data[room]).length;
    }
  }

  return totalCount;
}

export const checkMessageLimitAcrossProjects = async (
  teamId,
  workspaceIdsForTeam,
  dispatch
) => {
  try {
    const updatedWorkspaceMessages = workspaceIdsForTeam?.map(async (id) => {
      const workspaceId = id;

      const pathRef = realTimeRef(realDb);

      onValue(child(pathRef, `AIChats/${workspaceId}`), (snapShot) => {
        fetchIndividualTeamWorkspacesMessages(
          teamId,
          dispatch,
          workspaceIdsForTeam
        );
      });
    });
  } catch (error) {
    console.log(error);
  }
};
