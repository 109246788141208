import { sendSignInLinkToEmail } from "firebase/auth";
import React, { useState } from "react";
import { trackEvent } from "../../../utils/functions";
import { validateEmail } from "../../../helper/validation";
import { auth } from "../../../libraries/firebase";
import { Alert, CircularProgress, Snackbar } from "@mui/material";

const LimitModal = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [sentToEmail, setSentToEmail] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    trackEvent("Sign in - Send email link", {
      email,
    });

    setLoading(true);

    const isEmailValid = validateEmail(email, setError);
    if (!isEmailValid) {
      setLoading(false);

      return;
    }

    try {
      await sendSignInLinkToEmail(auth, email, {
        url: process.env.REACT_APP_WEBSITE_URL + "signin/",
        handleCodeInApp: true,
      });

      localStorage.setItem("email", email);
      setLoading(false);
      setIsEmailSent(true);
      setEmail("");
      setSentToEmail(email);
      setError("");
    } catch (e) {
      console.error(e);
      setError("Something went wrong, please try again");
      setLoading(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="absolute z-[200] backdrop-blur-sm bg-black/60 inset-0 flex items-center justify-center"
    >
      <div className="text-center w-[90%] md:w-[466px]  rounded-3xl px-6 py-[50px] lg:p-[50px] bg-white">
        <h1 className="font-bold text-lg lg:text-2xl font-mulish lg:whitespace-nowrap">
          All good things dont need to end
        </h1>
        <p className="mt-[18px] text-sm lg:text-base font-mulish">
          Sign-up to Unboard and continue using
          <br className="hidden lg:block" /> multiple AI models with your team.
        </p>
        <div className=" w-full mx-auto md:w-[307px]">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            className=" px-6 placeholder:font-mulish w-full font-mulish placeholder:text-[#686868] py-[11px] mt-[36px] rounded-full border border-[#E3E3E3]"
          />{" "}
          {error && (
            <div className="text-sm text-left text-red mt-2">{error}</div>
          )}
          <button
            className="relative font-mulish bg-[#3C60F0]  w-full mt-3 text-white py-[11px] rounded-full font-bold disabled:opacity-95"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              "Sign-up"
            )}
          </button>
        </div>
      </div>
      <Snackbar
        open={isEmailSent}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setIsEmailSent(false)}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{ backgroundColor: "white", color: "black" }}
        >
          {`Email sent to: ${sentToEmail}`}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default LimitModal;
