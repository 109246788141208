export const black = "#000000";
export const grayOne = "#030303";
export const blackFaded10 = "#1c1c1c";
export const blackFaded35 = "rgba(0, 0, 0, 0.35)";
export const blackFaded54 = "rgba(0, 0, 0, 0.54)";
export const blackFaded80 = "rgba(0, 0, 0, 0.8)";
export const eclipse = "#363636";
export const white = "#ffffff";
export const primaryColor = "#343541";
export const primaryColorLight = "#3E3F4B";
export const primaryColorLighter = "#3e3f4a";
export const whiteFaded15 = " rgba(255, 255, 255, 0.15)";
export const whiteFaded1 = "  rgba(255, 255, 255, 0.1)";
export const whiteFaded2 = "  rgba(255, 255, 255, 0.2)";
export const whiteFaded05 = "rgba(255, 255, 255, 0.05)";
export const whiteFaded50 = "rgba(255, 255, 255, 0.5)";
export const whiteFaded25 = "rgba(255, 255, 255, 0.25)";
export const whiteFaded02 = "rgba(255, 255, 255, 0.02)";
export const whiteFaded10 = "rgba(255, 255, 255, 0.1)";
export const whiteFaded20 = "rgba(255, 255, 255, 0.2)";
export const whiteFaded40 = "rgba(255, 255, 255, 0.4)";
export const whiteFaded70 = "rgba(255, 255, 255, 0.7)";
export const whiteFaded80 = "rgba(255, 255, 255, 0.8)";
export const whiteFadedO90 = "rgba(255, 255, 255, 0.9)";
export const whiteFaded90 = "rgba(52, 53, 65, 0.2)";
export const white05 = "#3e3f4a";
export const whiteFaded60 = "rgba(255, 255, 255, 0.6)";
export const whiteFaded = "rgba(244, 244, 244, 1)";
export const grayishBlue = "rgba(209, 215, 230, 1)";
export const primaryBtnColor = "#4875F6";
export const disabledBtnColor = "#2649AC";
export const errorColor = "#DA3841";
export const errorColorText = "#F97E85";
export const purple = "#352546";
export const purpleLight = "#42364E";
export const yellow = "#F3B516";
export const darkBlue = "#041823";
export const greenShade = "rgba(53, 99, 85, 0.8)";
export const nameColor = "#11B5E4";
export const newPrimary = "#4875F6";
export const newPrimaryFaded25 = "#4875F640";
export const dark = "#585858";
export const gray = "#757575";
export const blackFaded70 = "rgba(0, 0, 0, 0.7)";
export const blackFaded60 = "rgba(0, 0, 0, 0.6)";
export const blackFaded90 = "rgba(0, 0, 0, 0.9)";
