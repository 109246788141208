import { useSearchParams } from "react-router-dom";
import { ExpireSvg } from "../../../assets";

const ExpiredScreen = () => {
  const [searchParams] = useSearchParams()
  const team_name = searchParams.get("team_name")
  const team_admin_name = searchParams.get("team_admin_name")

  return ( 
    <div className="h-[80vh] w-full flex flex-col justify-center items-center text-white text-center p-4">
      <div className="w-20 h-20 flex justify-center items-center bg-white/10 rounded-lg mb-10 md:h-32 md:w-32">
        <ExpireSvg className="h-10 w-10 md:h-max md:w-max" />
      </div>
      <h4 className="mb-6 text-xl font-semibold md:text-2xl">Your invite link sent by {team_admin_name} has expired.</h4>
      <p className="text-sm font-normal max-w-lg w-full md:text-base">You get 24 hours to accept your invite before your link expires. Please contact your admin to get access to {team_name}.</p>
    </div>
   );
}
 
export default ExpiredScreen;