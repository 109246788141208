import { Colors } from "../../../themes";

export const styles = {
  profilePicWrapper: {
    position: "relative",
    width: { xs: "70.8px", md: "126px" },
    height: { xs: "70.8px", md: "126px" },
    mb: "20px",
  },
  profilePic: {
    borderRadius: "8px",
    width: { xs: "70.8px", md: "126px" },
    height: { xs: "70.8px", md: "126px" },
    objectFit: "cover",
  },
  editIcon: {
    position: "absolute",
    bottom: "-20px",
    right: "-20px",
  },
  errorMessage: {
    color: Colors.errorColor,
    fontSize: "14px",
    marginTop: "16px",
    marginBottom: "4px",
  },
};
