import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import { auth } from "../../../libraries/firebase";
import { Colors } from "../../../themes";
import { UserSvg } from "../../../assets";
import { MedText, RegText } from "../../../styles";
import { styles } from "./styles";
import { checkStringLength } from "../../../utils/functions";

const Collaborator = ({ image, name, role }) => {
  return (
    <div className="flex gap-2 items-center mb-4">
      {image?.length ? (
        <img
          src={image}
          alt="profile"
          className="w-[24px] h-[24px] rounded-full object-cover"
        />
      ) : (
        <UserSvg width={24} height={24} radius={100} />
      )}
      <RegText
        variant="body2"
        className="text-ellipsis overflow-hidden whitespace-nowrap text-black"
      >
        {checkStringLength(name, 15)}
      </RegText>
      {role && (
        <RegText variant="body2" className="whitespace-nowrap !text-black">
          {role}
        </RegText>
      )}
    </div>
  );
};

const ViewersAndContributers = ({ children }) => {
  const { notification } = useSelector((state) => state.app);
  const { selectedTeam } = useSelector((state) => state.user);
  const admins = selectedTeam?.admin;
  const contributors = selectedTeam?.contributers;
  const viewers = selectedTeam?.viewers;
  const [user] = useAuthState(auth);

  const displayUserRole = (currentUserId) => {
    const isAdmin = selectedTeam?.admin?.some(
      (item) => item?.userId === currentUserId
    );

    const isYou = currentUserId === user?.uid;

    if (isYou) return `( You )`;

    if (isAdmin) return `( Admin )`;

    return "";
  };

  let paddingParent = 5;
  let paddingChild = 0;
  if (children) {
    paddingParent = 0;
    paddingChild = 4;
  }

  let topPosition = "72px";
  if (notification) {
    topPosition = "132px";
  }

  const renderCollaborators = () => {
    if (!contributors?.length && !admins?.length)
      return (
        <RegText variant="body2" sx={styles.contributor}>
          No collaborators
        </RegText>
      );

    return (
      <>
        {contributors.map((item, i) => {
          return (
            <Collaborator
              key={i}
              image={item?.profileImage}
              name={item?.name}
              role={displayUserRole(item?.userId)}
            />
          );
        })}
      </>
    );
  };

  const renderViewers = () => {
    if (!viewers?.length)
      return (
        <RegText variant="body2" className="!text-black">
          No viewers
        </RegText>
      );

    return (
      <>
        {viewers.map((item, i) => {
          return (
            <Collaborator
              key={i}
              image={item?.profileImage}
              name={item?.name}
              role={displayUserRole(item?.userId)}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <div
        className={`text-white/80 bg-[${Colors.primaryColor}] p-${paddingParent} md:p-5 w-[100vw] md:w-[21%] h-[100vh] md:fixed md:top-[${topPosition}]`}
      >
        {children ?? children}
        <div
          className={` border-b-black/20 border-b-[1px] p-${paddingChild} -mt-0.5 pb-4  pl-[32px] md:pl-0 mr-2`}
        >
          <MedText variant="body2" sx={styles.heading}>
            About
          </MedText>
          <RegText variant="body2" className="text-black/60 break-words">
            {selectedTeam?.teamAbout?.length
              ? selectedTeam?.teamAbout
              : "Add Description"}
          </RegText>
        </div>
        <div className={`"h-[70vh] overflow-auto pt-4 pl-[32px] pr-2 md:pl-0`}>
          <div className="py-2">
            <MedText variant="body2" sx={[styles.heading, { mb: "18px" }]}>
              Contributors
            </MedText>
            {admins?.map((item, i) => {
              return (
                <Collaborator
                  key={i}
                  image={item?.profileImage}
                  name={item?.name}
                  role={displayUserRole(item?.userId)}
                />
              );
            })}
            {renderCollaborators()}
          </div>
          <div className="py-2">
            <MedText variant="body2" sx={[styles.heading, { mb: "18px" }]}>
              Viewers
            </MedText>
            {renderViewers()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewersAndContributers;
