import axios from "axios";
import { getChatGptResponse } from "../helper/chatgpt";
const URL = "http://localhost:4001/api/chatgpt/";

export const postQuestion = async (message) => {
  const payloads = {
    message,
  };
  try {
    const invalidResponse = "Oops Something went wrong, please try again?";
    const response = await getChatGptResponse(payloads.message);
    if (!response) return invalidResponse;
    return response;
  } catch (error) {
    console.log(error);
  }
};
