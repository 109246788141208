import { Stack } from "@mui/material";
import { BoldText, MedText } from "../../../styles";
import { Colors } from "../../../themes";

const SubscriptionCard = ({
  heading,
  price,
  subHeading,
  children,
  isSelectedPlan,
  setSelectedPlan,
  planType,
  setInitialPlan,
  currentPlan,
  type,
}) => {
  const getAppStyles = () => {
    let styles = {
      bgColor: Colors.white,
      headingColor: Colors.black,
      subHeadingColor: Colors.blackFaded60,
    };
    if (isSelectedPlan) {
      styles = {
        bgColor: Colors.darkBlue,
        headingColor: Colors.white,
        subHeadingColor: Colors.whiteFadedO90,
      };
      return styles;
    }
    return styles;
  };
  const { bgColor, headingColor, subHeadingColor } = getAppStyles();
  return (
    <div
      key={planType}
      onClick={() => {
        if (currentPlan === planType && type !== "create-team") return;
        setInitialPlan(null);
        setSelectedPlan(planType);
      }}
      className="relative"
    >
      <Stack
        bgcolor={bgColor}
        sx={styles.container}
        className="w-full md:max-w-[356px] max-w-[300px] border-borderBlue25 border-[0.5px] shadow-lg shadow-blueShadow"
      >
        <BoldText
          variant="body1"
          sx={{ color: headingColor, ...styles.heading }}
        >
          {heading}
        </BoldText>
        {planType === "premium" && (
          <div className="bg-red rounded-l-[10px] py-[5px] px-[10px] absolute right-0 top-[10px]">
            <BoldText variant="caption">60% of users pick this</BoldText>
          </div>
        )}
        <div className="md:hidden"></div>
        <BoldText sx={{ color: headingColor, ...styles.price }}>
          ${price}/monthly
        </BoldText>
        <MedText sx={{ color: subHeadingColor, ...styles.subHeading }}>
          {subHeading}
        </MedText>
        {children}
      </Stack>
    </div>
  );
};

export default SubscriptionCard;
const styles = {
  container: {
    borderRadius: "10px",
    p: "16px",
  },
  heading: {
    mb: "5px",
    fontSize: "16px",
  },
  price: {
    fontSize: "20px",
  },
  subHeading: {
    fontSize: "12px",
  },
};
