const TextArea = ({ label, placeholder, onChange, value, marginBottom }) => {
  return (
    <div className="">
      <label className="block text-white text-[14px] font-medium mb-2">
        {label}
      </label>
      <textarea
        onChange={(e) => onChange(e)}
        className={`block text-black outline-none rounded-lg bg-black/5 py-[13px] px-[14px] mb-[${
          marginBottom ?? 24
        }px] w-full focus:border-b-[2px]
        focus:border-b-newPrimary`}
        maxLength={300}
        placeholder={placeholder}
        value={value}
        style={{
          fontFamily: "Poppins",
          fontSize: "14px",
          padding: "24px",
          maxHeight: "180px",
        }}
      />
    </div>
  );
};

export default TextArea;
