import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MedText } from "../../../styles";
import { Colors } from "../../../themes";
import { CloseSvg } from "../../../assets";
import { styles } from "./styles";
import { fetchCurrentTeamSubscription } from "../../../services/payment";
import { useDispatch, useSelector } from "react-redux";
import { createPortalLink } from "../../../libraries/firebase";
import Loading from "../Loading";
import { toggleNotification } from "../../../redux/appSlice";

const getStyles = (type) => {
  const isYellow = type === "warning";
  return {
    bgcolor: isYellow ? Colors.yellow : Colors.errorColor,
    textColor: isYellow ? Colors.black : Colors.white,
    buttonBgColor: isYellow ? Colors.black : Colors.white,
    buttonTextColor: isYellow ? Colors.yellow : Colors.errorColor,
    closeIconColor: isYellow ? Colors.black : Colors.white,
    closeIconBtnColor: isYellow ? Colors.yellow : Colors.errorColor,
  };
};

const Notification = ({ tempLoader }) => {
  const { notification } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notification.open && !notification?.persist) {
      const timer = setTimeout(() => {
        dispatch(
          toggleNotification({
            open: false,
          })
        );
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [notification]);

  const renderLoader = () => {
    if (!tempLoader) return;
    return <Loading />;
  };

  const {
    bgcolor,
    textColor,
    buttonBgColor,
    buttonTextColor,
    closeIconColor,
    closeIconBtnColor,
  } = getStyles(notification.type);
  if (!notification.open) return;

  const closeNotification = () => {
    dispatch(
      toggleNotification({
        open: false,
        content: "",
        action: () => {},
      })
    );
  };
  return (
    <Stack {...styles.container} sx={{ bgcolor }}>
      <Box sx={styles.showDesktop} />
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        sx={{
          width: { xs: "200%", md: "auto" },
          justifyContent: { xs: "space-between", md: "center" },
        }}
      >
        <MedText
          sx={{ fontSize: { xs: "12px", md: "14px" }, color: textColor }}
        >
          {notification.content}
        </MedText>
        {notification.btnText && (
          <Button
            sx={{
              bgcolor: buttonBgColor,
              ...styles.button,
            }}
            onClick={notification.action}
          >
            <MedText variant="body2" sx={{ color: buttonTextColor }}>
              {notification.btnText}
            </MedText>
          </Button>
        )}

        <Stack
          sx={{ ...styles.payNowContainer, bgcolor: buttonBgColor }}
          gap={1}
        >
          <MedText
            sx={{
              color: buttonTextColor,
              width: "max-content",
              fontSize: "12px",
            }}
            onClick={notification.action}
          >
            {notification.btnText}
          </MedText>
          <Divider orientation="vertical" sx={styles.divider} />
          {!notification?.persist && (
            <Tooltip title="close" onClick={closeNotification}>
              <CloseSvg stroke={closeIconBtnColor} height="11px" width="10px" />
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Tooltip title="close">
        <IconButton
          sx={styles.showDesktop}
          onClick={closeNotification}
          disabled={notification?.persist}
        >
          {!notification?.persist && <CloseSvg stroke={closeIconColor} />}
        </IconButton>
      </Tooltip>
      {renderLoader()}
    </Stack>
  );
};

export default Notification;
