import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Stack } from "@mui/material";
import ProfilePic from "../../common/ProfilePic";
import Input from "../../common/Input";
import CustomBtn from "../../common/CustomBtn";
import { styles } from "./styles";
import {
  doc,
  firestoreDb,
  storage,
  storageRef,
  updateDoc,
} from "../../../libraries/firebase";
import { getDownloadURL, uploadString } from "firebase/storage";
import { RegText } from "../../../styles";
import { fetchUserData } from "../../../redux/asyncThunk";
import { Colors } from "../../../themes";

function ProfilePicture() {
  const { data: userData } = useSelector((state) => state.user);
  const [image, setImage] = useState(userData?.profileImage);
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState("");
  const [errors, setErrors] = useState({
    "First Name": "",
    "Last Name": "",
    "Display Name": "",
  });
  const [isFormChange, setIsFormChange] = useState(false);
  const dispatch = useDispatch();
  const userDataLabels = [
    { label: "First Name", value: userData?.firstName },
    { label: "Last Name", value: userData?.lastName },
    { label: "Display Name", value: userData?.displayName },
    { label: "Email", value: userData?.email },
  ];
  useEffect(() => {
    setLabels(userDataLabels);
    setImage(userData?.profileImage);
  }, [userData]);

  const handleInputChange = (event, label) => {
    const value = event.target.value;
    const updatedLabels = labels.map((item) => {
      if (item.label === label) {
        return { ...item, value };
      }
      return item;
    });
    setLabels(updatedLabels);
  };

  useEffect(() => {
    if (image !== userData?.profileImage) {
      setIsFormChange(true);
      return;
    }
    const formHasChanged = userDataLabels.some(
      (item) =>
        item.value !== labels.find((label) => label.label === item.label)?.value
    );
    setIsFormChange(formHasChanged);
  }, [image, userData?.profileImage, labels]);

  useEffect(() => {
    setErrors({
      "First Name": labels[0]?.value.trim() ? "" : "First Name is required",
      "Last Name": labels[1]?.value.trim() ? "" : "Last Name is required",
      "Display Name": labels[2]?.value.trim() ? "" : "Display Name is required",
    });
  }, [labels]);
  const imageUpload = async () => {
    try {
      const imageRef = storageRef(storage, `Users/${userData?.uid}/profile`);
      const uploadedImage = await uploadString(imageRef, image, "data_url");
      const downloadUrl = await getDownloadURL(uploadedImage.ref);
      return downloadUrl;
    } catch (e) {
      console.log(e);
    }
  };
  const updateUserProfile = async () => {
    setLoading(true);
    try {
      const { uid } = userData;
      const email = userData?.email;
      let profileImage = userData?.profileImage;
      if (image !== userData?.profileImage) {
        profileImage = await imageUpload();
      }
      const displayName = labels
        .find((item) => item.label === "Display Name")
        .value.trim();
      const firstName = labels
        .find((item) => item.label === "First Name")
        .value.trim();
      const lastName = labels
        .find((item) => item.label === "Last Name")
        .value.trim();
      const newUserData = {
        profileImage,
        displayName,
        firstName,
        lastName,
        email,
      };
      await updateDoc(doc(firestoreDb, "Users", uid), newUserData);
      dispatch(fetchUserData());
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setIsFormChange(false);
    }
  };

  const renderFields = (item) => {
    return (
      <Box sx={styles.inputContainer} key={item.label}>
        <Input
          label={item.label}
          style={{ width: "100%" }}
          value={item.value}
          disabled={item.label === "Email"}
          onChange={(event) => handleInputChange(event, item.label)}
          maxLength={255}
        />
        {errors[item.label] && (
          <RegText sx={styles.errorMessage}>{errors[item.label]}</RegText>
        )}
      </Box>
    );
  };

  return (
    <Stack
      alignItems="center"
      pt="80px"
      overflow="auto"
      height={{ xs: "85vh", md: "100%" }}
    >
      <ProfilePic
        image={image}
        setImage={setImage}
        setImageError={setImageError}
      />
      {imageError && <RegText sx={styles.errorMessage}>{imageError}</RegText>}
      <Stack sx={styles.inputWrapper} component="form" px={'20px'}>
        <div className="flex gap-2.5">
          {labels.slice(0, 2).map((item) => renderFields(item))}
        </div>
        {labels.slice(2).map((item) => renderFields(item))}
        <CustomBtn
          styles={styles.btnSaveChanges}
          type="primary"
          onClick={updateUserProfile}
          disabled={
            loading ||
            imageError ||
            Object.values(errors).some((error) => error) ||
            !isFormChange ||
            labels.slice(0, 3).some((label) => !label.value.trim())
          }
        >
          {loading ? (
            <CircularProgress sx={{ color: Colors.white }} size={30} />
          ) : (
            "Save Changes"
          )}
        </CustomBtn>
      </Stack>
    </Stack>
  );
}

export default ProfilePicture;
