import {
  firestoreDb,
  doc,
  getDoc,
  collection,
  addDoc,
  Timestamp,
  updateDoc,
  sendEmail,
  query,
  where,
  orderBy,
  getDocs,
  handleInvitation,
  setDoc,
} from "../libraries/firebase";

export const getInviteData = async (teamId, email) => {
  try {
    const invitationDocQuery = query(
      collection(firestoreDb, "Invitations"),
      where("recipientEmail", "==", email),
      where("teamId", "==", teamId),
      orderBy("createdAt", "desc")
    );
    const snapshot = await getDocs(invitationDocQuery);
    if (snapshot.empty) {
      return {};
    }
    const snapshotData = snapshot.docs[0].data();

    return { ...snapshotData, id: snapshot.docs[0].id };
  } catch (error) {
    console.log(error);
  }
};

export const handleInvitationLink = async (invitationId, status) => {
  try {
    const response = await handleInvitation({
      invitationId,
      status,
    });

    if (response?.data?.status !== "success") {
      console.log("Error! Cannot handle invitation.");
    }

    return response?.data?.status;
  } catch (error) {
    console.log(error);
  }
};

export const sendInviteEmails = async (
  teamId,
  teamName,
  senderEmail,
  senderName,
  emails = []
) => {
  try {
    const response = await sendEmail({
      teamId,
      teamName,
      senderEmail,
      senderName,
      emailList: emails,
    });

    if (response?.data?.status !== "success") {
      console.log("Error! Cannot send invite emails.");
    }

    return response?.data?.status;
  } catch (error) {
    console.log(error);
  }
};

export const checkInviteStatus = async ({ invitationId }) => {
  try {
    const docRef = doc(firestoreDb, "Invitations", invitationId);
    const response = await getDoc(docRef);
    return response.data().status;
  } catch (error) {
    console.log(error);
  }
};
