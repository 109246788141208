import React, { useEffect } from "react";
import { Images } from "../../../assets";
import Hero from "./hero";
import Chat from "./chat";
import { auth } from "../../../libraries/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInAnonymously } from "firebase/auth";
import { Navigate } from "react-router-dom";
import Loading from "../../common/Loading";
const LandingPage = () => {
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    if (!user) {
      signInAnonymously(auth)
        .then((res) => {
          // Signed in..
          console.log("res", res);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    }
  }, [user, loading]);
  if (loading) {
    return <Loading />;
  }
  if (user && !user?.isAnonymous) {
    return <Navigate to={"/"} />;
  }
  return (
    <div className="h-[100svh] flex w-full">
      <img
        src={Images.LandingPageBackground}
        className="w-1/2 h-full object-cover absolute top-0 right-0 left-0 bottom-0 hidden lg:block"
        alt="background"
      />
      <img
        src={Images.LandingPageBackgroundSmaller}
        className="w-full lg:hidden h-full object-cover absolute top-0 right-0 left-0 bottom-0"
        alt="background"
      />
      <Hero />
      <div className="hidden relative lg:block w-1/2 overflow-y-auto  bg-[#E3E3E3]">
        <Chat />
      </div>
    </div>
  );
};

export default LandingPage;
