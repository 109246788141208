import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData, fetchWorkspaces } from "./asyncThunk";

const initialState = {
  data: null,
  loading: false,
  error: null,
  status: "pending",
  teams: [],
  selectedTeam: {},
  selectedProjectId: "",
  projectsData: [],
  workspacesData: [],
  userRoles: {},
  workSpaceSearchResults: [],
  workspaceIdsForTeam: [],
  totalMessagesForTeam: 0,
  workspaceSearchQuery: "",
  workspaceLoading: true,
  projectLoading: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserTeams: (state, action) => {
      state.teams = action.payload;
    },
    setSelectedTeam: (state, action) => {
      state.selectedTeam = state.teams?.find((team) =>
        window.location.href.includes(team.teamId)
      );
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
    setUserProfile: (state, action) => {
      state.data = action.payload;
    },
    setProjectsData: (state, action) => {
      state.projectsData = action.payload;
      state.projectLoading = false;
    },
    setWorkspaceData: (state, action) => {
      state.workspacesData = action.payload;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    findWorkspaces: (state, action) => {
      if (action.payload === "") {
        state.workSpaceSearchResults = state.workspacesData;
        return;
      }
      state.workspaceSearchQuery = action.payload;
      const data = state.workspacesData
        .filter(
          (workspace) =>
            workspace.name
              .toLowerCase()
              .includes(action?.payload?.toLowerCase()) &&
            workspace.projectId === state.selectedProjectId
        )
        .map((workspace) => ({
          name: workspace.name,
          key: workspace.key,
          projectId: workspace.projectId,
          createdAt: workspace.createdAt,
          updatedAt: workspace.updatedAt,
          lastSession: workspace.lastSession,
        }));

      state.workSpaceSearchResults = data;
    },
    clearUserData: (state) => {
      Object.assign(state, initialState);
    },
    setTotalMessagesForTeam: (state, action) => {
      state.totalMessagesForTeam = action.payload;
    },
    setWorkspaceIds: (state, action) => {
      state.workspaceIdsForTeam = action.payload;
    },
    setWorkspaceLoading: (state, action) => {
      state.workspaceLoading = action.payload;
    },
    clearSearchQuery: (state) => {
      state.workspaceSearchQuery = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.status = "complete";
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = "rejected";
      });
    builder
      .addCase(fetchWorkspaces.pending, (state) => {
        state.workspacesData = [];
        state.workspaceLoading = true;
      })
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        state.workspacesData = action.payload;
        state.workspaceLoading = false;
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.workspacesData = [];
        state.workspaceLoading = false;
      });
  },
});
export const {
  setUserTeams,
  setSelectedTeam,
  setUserProfile,
  setProjectsData,
  setUserRoles,
  setSelectedProjectId,
  setWorkspaceData,
  findWorkspaces,
  clearUserData,
  setTotalMessagesForTeam,
  setWorkspaceIds,
  clearSearchQuery,
  setWorkspaceLoading,
} = userSlice.actions;

export default userSlice.reducer;
