import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import testSlice from "./testSlice";
import userSlice from "./userSlice";
import { chatApiSlice } from "./chatApiSlice";

const enableDevTools = process.env.REACT_APP_DEVTOOLS === "true";

export const store = configureStore({
  reducer: {
    app: appSlice,
    test: testSlice,
    user: userSlice,
    [chatApiSlice.reducerPath]: chatApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(chatApiSlice.middleware),
  devTools: enableDevTools,
});
