import { Colors } from "../../../themes";

export const styles = {
  profilePicWrapper: {
    position: "relative",
    width: { xs: "70.8px", md: "126px" },
    height: { xs: "70.8px", md: "126px" },
  },
  profilePic: {
    borderRadius: "8px",
    width: { xs: "70.8px", md: "126px" },
    height: { xs: "70.8px", md: "126px" },
    objectFit: "cover",
  },
  editIcon: {
    position: "absolute",
    bottom: "-20px",
    right: "-20px",
  },
  inputWrapper: {
    width: { xs: "100vw", md: "440px" },
    flex: 1,
    mt: "31px",
  },
  inputContainer: {
    width: "100%",
    mt: { xs: "17px", md: "25px" },
  },
  btnSaveChanges: {
    mt: { xs: "auto", md: "57px" },
  },
  errorMessage: {
    color: Colors.errorColor,
    fontSize: "14px",
    marginTop: "16px",
    marginBottom: "4px",
  },
};
