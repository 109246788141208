import { Colors } from "../../../themes";

export const styles = {
  teamProfileLinks: {
    backgroundColor: Colors.blackFaded10,
    width: "100%",
    mt: "8px",
    borderRadius: "8px",
  },
  teamProfileLinkTitle: {
    color: Colors.white,
  },
  teamProfileLinkDivider: {
    bgcolor: Colors.whiteFaded25,
  },
  teamProfileImage: {
    height: "64px",
    width: "64px",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "red",
  },
  teamProfileName: {
    color: Colors.white,
  },
  teamProfileContainer: {
    backgroundColor: Colors.white,
    mt: 1,
    borderRadius: "16px 16px 0 0",
  },
};
