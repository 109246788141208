import React from "react";
import { Chip } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { CloseSvg, Icons, UserSvg } from "../../../assets";
import { auth } from "../../../libraries/firebase";
import { MedText, RegText, SemiBoldText } from "../../../styles";
import { useSelector } from "react-redux";
import { checkStringLength } from "../../../utils/functions";
import { styles } from "./styles";
import { Colors } from "../../../themes";

const Collaborator = ({ image, name, role }) => {
  return (
    <div className="flex items-center mb-4 justify-between">
      <div className="flex items-center gap-2">
        {image?.length ? (
          <img
            src={image}
            alt="profile"
            className="w-[24px] h-[24px] rounded-full object-cover"
          />
        ) : (
          <UserSvg width={24} height={24} radius={100} />
        )}
        <RegText
          variant="body2"
          className="text-ellipsis overflow-hidden whitespace-nowrap text-black"
        >
          {checkStringLength(name, 15)}
        </RegText>
      </div>
      {role && (
        <RegText variant="body2" className="whitespace-nowrap !text-black">
          {role}
        </RegText>
      )}
    </div>
  );
};

const ActiveMemberCard = ({ data, onClose }) => {
  const currentUser = auth?.currentUser;
  const { selectedTeam } = useSelector((state) => state.user);
  const { admin, contributers, viewers } = selectedTeam;
  const [user] = useAuthState(auth);
  const displayUserRole = (currentUserId) => {
    const isAdmin = selectedTeam?.admin?.some(
      (item) => item?.userId === currentUserId
    );

    const isYou = currentUserId === currentUser?.uid;

    if (isYou) return `( You )`;

    if (isAdmin) return `( Admin )`;

    return "";
  };
  const renderCollaborators = () => {
    if (!contributers?.length && !admin?.length)
      return (
        <RegText variant="body2" sx={styles.contributor}>
          No collaborators
        </RegText>
      );

    return (
      <>
        {contributers.map((item, i) => {
          return (
            <Collaborator
              key={i}
              image={item?.profileImage}
              name={item?.name}
              role={displayUserRole(item?.userId)}
            />
          );
        })}
      </>
    );
  };

  const renderViewers = () => {
    if (!viewers?.length)
      return (
        <RegText variant="body2" className="!text-black">
          No viewers
        </RegText>
      );

    return (
      <>
        {viewers.map((item, i) => {
          return (
            <Collaborator
              key={i}
              image={item?.profileImage}
              name={item?.name}
              role={displayUserRole(item?.userId)}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="rounded-xl bg-white custom-shadow p-4 w-[100%]">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <SemiBoldText className="text-black !text-sm">
            Active Members
          </SemiBoldText>
        </div>
        <button
          className="flex rounded-full h-[18px] w-[18px] p-[5px] justify-center items-center bg-newPrimary"
          onClick={onClose}
        >
          <CloseSvg stroke={Colors.white} className="" />
        </button>
      </div>
      <div className={`"h-[70vh] overflow-auto pt-4  pr-2 md:pl-0`}>
        <div className="py-2">
          <MedText variant="body2" sx={[styles.heading, { mb: "18px" }]}>
            Contributors
          </MedText>
          {admin?.map((item, i) => {
            return (
              <Collaborator
                key={i}
                image={item?.profileImage}
                name={item?.name}
                role={displayUserRole(item?.userId)}
              />
            );
          })}
          {renderCollaborators()}
        </div>
        <div className="py-2">
          <MedText variant="body2" sx={[styles.heading, { mb: "18px" }]}>
            Viewers
          </MedText>
          {renderViewers()}
        </div>
      </div>
    </div>
  );
};

export default ActiveMemberCard;
