import React from "react";
import { Icons } from "../../../assets";
import {
  formatThreadsWithLevels,
  getDeviceHeight,
} from "../../../utils/functions";
import SideBarItem from "../SideBarItem";
import "./styles.css";

export const getNewThreadStyle = (currentThreadId, previousThread) => {
  if (currentThreadId == previousThread) {
    return {
      containerStyle: "w-[90%] bg-dark6 mt-1 mb-2 px-[14px] py-4",
      icon: Icons.chat,
      activeStyle: "text-white",
    };
  }
  return {
    containerStyle: "w-[90%] bg-grey2 mt-1 mb-2 px-[14px] py-4",
    icon: Icons.chatFill,
    activeStyle: "text-dark7",
  };
};

const SharedSidebar = ({
  data,
  onPressCreate,
  onPressWorkspace,
  onCloneWorkspace,
  onChangeThread,
  currentThreadId,
  creatingThread,
  setCreatingThread,
}) => {
  const formattedThreads = formatThreadsWithLevels(data);

  return (
    <div
      className="relative bg-primary flex flex-col items-center pr-2"
      style={{
        height: getDeviceHeight(),
      }}
    >
      <div className="flex flex-1 flex-col overflow-x-scroll pb-[8%] w-full h-full items-center pt-[8%]">
        {formattedThreads?.map((item) => (
          <SideBarItem
            key={item?.id}
            containerStyle={
              getNewThreadStyle(currentThreadId, item?.id).containerStyle
            }
            icon={getNewThreadStyle(currentThreadId, item?.id).icon}
            iconStyle={"w-3 h-3"}
            activeStyle={
              getNewThreadStyle(currentThreadId, item?.id).activeStyle
            }
            heading={item?.name}
            currentThreadId={currentThreadId}
            checkId={item.id}
            onPress={() => {
              onChangeThread(item?.id);
              setCreatingThread(false);
            }}
            subthreads={item?.subthreads}
            onChangeThread={onChangeThread}
            setCreatingThread={setCreatingThread}
          />
        ))}
      </div>
    </div>
  );
};

export default SharedSidebar;
