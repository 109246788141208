import {
  firestoreDb,
  addDoc,
  collection,
  setDoc,
  doc,
} from "../libraries/firebase";
import {
  deleteDoc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

export const createProject = async (teamId, projectName) => {
  try {
    const projectRef = collection(firestoreDb, `Teams`, teamId, `Projects`);
    const res = await addDoc(projectRef, {
      createdAt: serverTimestamp(),
      name: projectName,
      updatedAt: serverTimestamp(),
      isDeleted: false,
    });
    return { status: "success", id: res?.id };
  } catch (error) {
    console.log(error);
  }
};

export const fetchProjectsData = async (teamId, callback) => {
  const unsubscribe = onSnapshot(
    query(
      collection(firestoreDb, "Teams", teamId, "Projects"),
      orderBy("createdAt", "asc"),
      where("isDeleted", "==", false)
    ),
    (snap) => {
      const projects = snap.docs.map((doc) => ({
        ...doc?.data(),
        id: doc?.id,
        updatedAt: doc?.data()?.updatedAt?.seconds,
        createdAt: doc?.data()?.createdAt?.seconds,
      }));
      callback(projects);
    }
  );

  return () => {
    unsubscribe();
  };
};

export const updateProjectName = async (teamId, projectName, projectId) => {
  try {
    const projectDocRef = doc(
      firestoreDb,
      `Teams`,
      teamId,
      `Projects`,
      projectId
    );

    await setDoc(
      projectDocRef,
      {
        name: projectName,
        updatedAt: serverTimestamp(),
      },
      { merge: true }
    );

    return "success";
  } catch (error) {
    console.log(error);
  }
};

export const deleteProject = async (teamId, projectId) => {
  try {
    const projectDocRef = doc(
      firestoreDb,
      `Teams`,
      teamId,
      `Projects`,
      projectId
    );

    await updateDoc(projectDocRef, {
      isDeleted: true,
      deletedAt: serverTimestamp(),
    });

    return "success";
  } catch (error) {
    console.log(error);
  }
};
