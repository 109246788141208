import React, { useCallback, useEffect, useState } from "react";
import {
  AddChatSvg,
  ArrowDownSvg,
  ArrowLeftSvg,
  ArrowRightSvg,
  LeaveSvg,
  LogoSvg,
  SettingSvg,
  UserLogo,
  UserSvg,
} from "../../../../assets";
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Tooltip,
} from "@mui/material";
import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { toggleLeftMenu, toggleRightMenu } from "../../../../redux/appSlice";
import { Colors } from "../../../../themes";
import ViewersAndContributers from "../../ViewersAndContributers";
import Contributers from "../../Contributers";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, leaveTeam } from "../../../../libraries/firebase";
import TopNavbar from "../TopNavbar";
import { RegText, SemiBoldText } from "../../../../styles";
import AppDrawer from "../../AppDrawer";
import SubMenu from "../../SubMenu";
import CommonModal from "../../CommonModal";
import CustomBtn from "../../CustomBtn";
import {
  clearSearchQuery,
  setSelectedTeam,
  setWorkspaceLoading,
} from "../../../../redux/userSlice";
import PhoneTeamSetting from "../../PhoneTeamSetting";
import {
  checkStringLength,
  getUserRole,
  trackEvent,
} from "../../../../utils/functions";
import { capitalize } from "../../../../helper/formatting";

const ButtonContainer = ({ children, tooltip, onClick }) => (
  <Tooltip title={tooltip} arrow placement="right">
    <Stack {...styles.buttonContainer} onClick={onClick}>
      {children}
    </Stack>
  </Tooltip>
);

const SelectedItem = () => (
  <Box sx={styles.logo.arrow}>
    <ArrowRightSvg height={35} width={15} />
  </Box>
);

const renderSelectedItem = (teamId) => {
  if (teamId === "Home") {
    return <SelectedItem />;
  }
  if (window.location.href.includes(teamId)) {
    return <SelectedItem />;
  }
  return null;
};

const Logo = ({ closeSideBar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { teams, selectedTeam } = useSelector((state) => state.user);
  return (
    <ButtonContainer
      tooltip="Home"
      onClick={() => {
        if (teams.length > 0) {
          if (selectedTeam?.teamId) {
            navigate(`/teams/${selectedTeam?.teamId}/`);
          }
          return;
        }
        navigate("/");
        closeSideBar();
      }}
    >
      {location.pathname === "/" && renderSelectedItem("Home")}
      <LogoSvg height={48} width={48} />
    </ButtonContainer>
  );
};

const CreateTeams = () => {
  const [hoverActive, setHoverActive] = useState(false);
  const navigate = useNavigate();
  return (
    <ButtonContainer
      tooltip="New Team"
      onClick={() => {
        trackEvent("Create Team - New Team", {
          Source: "Workspace",
        });
        localStorage.removeItem("storedTeam");
        localStorage.removeItem("storedEmails");
        navigate("/create-team");
      }}
    >
      <Stack
        {...styles.createWorkspace.stack}
        sx={{
          bgcolor: hoverActive ? Colors.primaryBtnColor : Colors.whiteFaded15,
        }}
        onMouseOver={() => setHoverActive(true)}
        onMouseLeave={() => setHoverActive(false)}
      >
        <AddChatSvg
          height={16}
          width={16}
          stroke={hoverActive ? Colors.white : Colors.primaryBtnColor}
        />
      </Stack>
    </ButtonContainer>
  );
};

const ProfileButton = ({ navigate, userData, closeSideBar }) => {
  const renderProfileImage = () => {
    if (userData?.profileImage) {
      return (
        <Box
          {...styles.profileImageStyle}
          className="object-cover"
          src={userData?.profileImage}
        />
      );
    }
    return (
      <Box sx={styles.noProfileImage}>
        <UserLogo height="100%" width="100%" />
      </Box>
    );
  };

  const handleProfileButtonClick = () => {
    trackEvent("Workspace - Profile Page");
    navigate("/user-profile");
    closeSideBar();
  };

  return (
    <ButtonContainer tooltip="Profile" onClick={handleProfileButtonClick}>
      {renderSelectedItem("profile")}
      {renderProfileImage()}
    </ButtonContainer>
  );
};

const TeamButton = ({ navigate, teamData, closeSideBar }) => {
  const dispatch = useDispatch();
  return (
    <ButtonContainer
      tooltip={teamData?.teamName}
      onClick={() => {
        trackEvent("Workspace - Select Team", {
          teamID: teamData?.teamId,
        });
        dispatch(setWorkspaceLoading(true));
        dispatch(clearSearchQuery());
        navigate(`/teams/${teamData?.teamId}`);
        closeSideBar();
      }}
    >
      {renderSelectedItem(teamData?.teamId)}
      <div className="">
        {teamData.profileImage === "" ? (
          <UserSvg height={30} width={30} />
        ) : (
          <img
            src={teamData?.profileImage}
            alt={teamData?.teamName}
            style={styles.profileImageStyle}
          />
        )}
      </div>
    </ButtonContainer>
  );
};
const Navbar = ({ selectedItem, setSelectedItem, navigate, closeSideBar }) => {
  const { teams, data: userData } = useSelector((state) => state.user);
  return (
    <Stack
      sx={{
        ...styles.container,
      }}
      alignItems="center"
    >
      <Logo
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        closeSideBar={closeSideBar}
      />
      <hr style={styles.divider} />
      <Stack
        sx={{ maxHeight: "80%", overflow: "auto" }}
        className="nav-container"
      >
        {teams?.map((team) => {
          return (
            <TeamButton
              key={team.teamId}
              navigate={navigate}
              teamData={team}
              closeSideBar={closeSideBar}
            />
          );
        })}
      </Stack>
      <CreateTeams />
      <hr style={{ ...styles.divider, marginTop: "auto" }} />
      <ProfileButton
        navigate={navigate}
        userData={userData}
        closeSideBar={closeSideBar}
      />
    </Stack>
  );
};
const SideNavbar = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState("Home");
  const { menuLeftState, menuRightState } = useSelector((state) => state.app);
  const { teams, selectedTeam } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPhoneTeamSetting, setOpenPhoneTeamSetting] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const [user] = useAuthState(auth);
  const role = getUserRole(user?.uid, selectedTeam);
  const isAdmin = role === "admin";

  const handleOpenSubMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeSideBar = () => {
    dispatch(toggleLeftMenu(false));
    dispatch(toggleRightMenu(false));
  };
  const handleClose = (index) => {
    setAnchorEl(null);
    if (index === 1) {
      setOpenModal(true);
    }
  };
  useEffect(() => {
    dispatch(setSelectedTeam());
  }, [navigate, teams]);

  const handleLeave = async () => {
    setLoading(true);
    const response = await leaveTeam({ role, teamId: selectedTeam?.teamId });
    if (
      response?.data?.status === "failed" &&
      response?.data?.error === "only one admin in team"
    ) {
      setLeaveModal(true);
    } else if (response?.data?.status === "success") {
      trackEvent("Workspace - Dropdown - Leave");
      navigate("/");
    }
    handleClose();
    setOpenModal(false);
    setLoading(false);
  };

  const menuItems = [
    {
      icon: <LeaveSvg />,
      text: "Leave Team",
      color: Colors.errorColorText,
      onClick: () => {
        if (role === "admin" && selectedTeam.admin.length === 1) {
          setLeaveModal(true);
          return;
        }
        setOpenModal(true);
      },
    },
  ];

  if (isAdmin) {
    menuItems.unshift({
      icon: <SettingSvg />,
      text: "Team Settings",
      onClick: () => {
        trackEvent("Workspace - Dropdown - Settings");
        setOpenPhoneTeamSetting(true);
        handleClose();
      },
    });
  }

  const renderProjects = () => {
    const avoidPaths = ["/", "/user-profile"];
    if (
      menuLeftState &&
      !avoidPaths.includes(location.pathname) &&
      !location.pathname.includes("/viewer-invitation")
    ) {
      return (
        <Contributers>
          <SubMenu
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            menuItems={menuItems}
            width="60%"
            marginTop="25px"
          />
          <Box width="100%" px={"5px"} mt="20px">
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SemiBoldText variant="body1" className="!text-black">
                {checkStringLength(selectedTeam?.teamName, 18)}
              </SemiBoldText>
              <IconButton onClick={handleOpenSubMenu}>
                <ArrowDownSvg />
              </IconButton>
            </Stack>
            <Stack sx={styles.premiumContainer}>
              <RegText sx={styles.teamName}>
                {capitalize(selectedTeam?.plan)}
              </RegText>
            </Stack>
          </Box>
          <hr style={styles.hr} />
        </Contributers>
      );
    }
  };

  const renderContributors = () => {
    if (menuRightState && location.pathname !== "/") {
      return (
        <ViewersAndContributers>
          <Stack sx={styles.navbar} className="custom-shadow">
            <ArrowLeftSvg
              height={24}
              widht={24}
              onClick={closeSideBar}
              fill="black"
            />
          </Stack>
        </ViewersAndContributers>
      );
    }
  };
  const renderPhoneTeamSetting = useCallback(() => {
    if (openPhoneTeamSetting) {
      return (
        <PhoneTeamSetting
          open={openPhoneTeamSetting}
          setOpen={setOpenPhoneTeamSetting}
          closeSideBar={closeSideBar}
        />
      );
    }
  }, [openPhoneTeamSetting]);

  const renderModal = ({ heading, subHeading, open, setOpen, children }) => {
    return (
      <CommonModal
        heading={heading}
        subHeading={subHeading}
        open={open}
        setOpen={setOpen}
      >
        {children}
      </CommonModal>
    );
  };

  const renderLeaveTeam = () => {
    return renderModal({
      heading: "Leave team?",
      subHeading: isAdmin
        ? "Your active files will not be deleted"
        : "Your active files will remain active",
      open: openModal,
      setOpen: setOpenModal,
      children: (
        <Stack gap={2}>
          <CustomBtn type="error" onClick={handleLeave}>
            {loading ? (
              <CircularProgress sx={{ color: Colors.white }} size={30} />
            ) : (
              "Leave"
            )}
          </CustomBtn>
          <CustomBtn type="secondary" onClick={() => setOpenModal(false)}>
            Cancel
          </CustomBtn>
        </Stack>
      ),
    });
  };

  const renderLeave = () => {
    return renderModal({
      heading: "You can't leave this team",
      subHeading:
        "To leave the team, please assign another member as an admin. ",
      open: leaveModal,
      setOpen: setLeaveModal,
      children: (
        <Stack gap={2}>
          <CustomBtn
            type="primary"
            onClick={() => {
              setLeaveModal(false);
              navigate(`/teams/${selectedTeam.teamId}/members`);
              dispatch(closeSideBar);
            }}
          >
            Update Roles
          </CustomBtn>
          <CustomBtn type="secondary" onClick={() => setLeaveModal(false)}>
            Close
          </CustomBtn>
        </Stack>
      ),
    });
  };

  return (
    <>
      {renderPhoneTeamSetting()}
      {renderLeave()}
      {renderLeaveTeam()}
      <Box>
        <Box sx={{ display: { xs: "none", md: "block" }, ...styles.container }}>
          <Navbar
            navigate={navigate}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            closeSideBar={closeSideBar}
          />
        </Box>
        <AppDrawer
          appDrawerProps={{
            open: menuLeftState,
            anchor: "left",
            drawerWidth: "100vw",
          }}
          style={{ bgcolor: Colors.primaryColorLighter }}
        >
          <Stack direction="row" sx={styles.mobileContainer}>
            <Navbar
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              navigate={navigate}
              closeSideBar={closeSideBar}
            />
            {renderProjects()}
            <Stack onClick={closeSideBar} sx={styles.dummyChildren}>
              <TopNavbar />
              <Stack sx={{ height: "100vh" }}>{children}</Stack>
            </Stack>
          </Stack>
        </AppDrawer>
        <AppDrawer
          appDrawerProps={{
            open: menuRightState,
            anchor: "right",
            drawerWidth: "100vw",
          }}
          style={{ bgcolor: Colors.white }}
        >
          <Stack direction="row" sx={styles.mobileContainerRight}>
            <Stack onClick={closeSideBar} sx={styles.dummyChildren}>
              <TopNavbar />
              <Stack>{children}</Stack>
            </Stack>
            {renderContributors()}
          </Stack>
        </AppDrawer>
      </Box>
    </>
  );
};

export default SideNavbar;
