import React from "react";
import { motion } from "framer-motion";

const LoadingDots = () => {
  const dotVariants = {
    start: {
      y: "0%",
    },
    end: {
      y: "100%",
    },
  };

  const dotTransition = {
    duration: 0.5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  return (
    <div className="flex justify-center items-center h-5">
      {[0, 1, 2].map((index) => (
        <motion.div
          key={index}
          className="w-1 h-1 mx-1 bg-black/50 rounded-full"
          variants={dotVariants}
          initial="start"
          animate="end"
          transition={{
            ...dotTransition,
            delay: index * 0.1,
          }}
        />
      ))}
    </div>
  );
};

export default LoadingDots;
