import { Colors } from "../themes";

export const formatMember = (data) => {
  if (Object.keys(data).length === 0) return [];

  const members = Object.keys(data).map((key) => {
    return {
      id: key,
      name: data[key],
    };
  });

  return members;
};

export const stringToColour = function (str) {
  if (!str) return Colors.nameColor;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const capitalize = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const formattingSessionTime = (timeString) => {
  timeString = timeString
    .replace(/a few seconds ago/, "just now")
    .replace(/a minute ago/, "1 min ago")
    .replace(/minutes ago/, "min ago")
    .replace(/an hour ago/, "1 hr ago")
    .replace(/hours ago/, "hr ago")
    .replace(/a day ago/, "1 day ago")
    .replace(/days ago/, "days ago")
    .replace(/a year ago/, "1 year ago")
    .replace(/years ago/, "years ago");

  return timeString;
};
